import React, { useContext, useEffect, useState } from 'react';
import { Platform, View, SafeAreaView, Text, ActivityIndicator, ScrollView, TextInput, Image, TouchableOpacity, Dimensions } from 'react-native';
import { useToast } from "react-native-toast-notifications";
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import * as ImagePicker from 'expo-image-picker';
import * as DocumentPicker from 'expo-document-picker';
import { styles } from '../styles/global';
import { API } from '../lib/api';
import IconButton from '../components/iconButton';
import ItemImage from '../components/itemImage';
import { useNavigation } from '@react-navigation/native';
import { AuthContext, TicketContext } from '../context';
import { Actions } from './serviceTicketProvider';
import DocumentLink from '../components/documentLink';
import Button from "../components/Common/Button";
import { colors } from "../config/Colors";

import Header from "../components/Common/Header";

let { width } = Dimensions.get('window');
width = width > 500 ? 500 : width;

export function ItemForm(props) {
  const authContext = useContext(AuthContext);
  const toast = useToast();
  const navigation = useNavigation();
  const [item, setItem] = useState(null);
  const [localItem, setLocalItem] = useState(null);
  const [saving, setSaving] = useState(false);

  function setField(obj, setter, field, value) {
    setter({
      ...obj,
      [field]: value
    });
  }
  const setItemField = (field, value) => setField(item, setItem, field, value);
  const setLocalItemField = (field, value) => setField(localItem, setLocalItem, field, value);

  async function pickImage() {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (!result.canceled) {
      setLocalItemField('images', [...(localItem?.images || []), {'upload': result.uri}]);
    }
  };

  async function pickDocument() {
    let result = await DocumentPicker.getDocumentAsync({
      type: 'application/pdf',
    });

    if (result.type == 'success') {
      setLocalItemField('documents', [...(localItem?.documents || []), {'upload': result}]);
    }
  };

  async function uploadLocalImages(itemId) {
    let imageResponses = [];
    if ((localItem?.images || []).length > 0) {
      let imageUploads = localItem.images.filter(image => 'upload' in image).map(image => API.uploadItemImage(image.upload));
      // Upload the images
      try {
        imageResponses = await Promise.all(imageUploads);
      } catch (error) {
        console.log(error);
        toast.show('Failed to upload images');
        imageResponses = [];
      }

      // Once the images are uploaded, attach them to the item.
      try {
        await Promise.all(imageResponses.map(image => API.setItemImageItemId(image.id, itemId)));
      } catch (error) {
        console.log(error);
        toast.show('Failed to associate images');
      }
    }
    return imageResponses;
  }

  async function uploadLocalDocuments(itemId) {
    let responses = [];
    if ((localItem?.documents || []).length > 0) {
      // TODO: upload is not working on Android
      let uploads = localItem.documents.filter(doc => 'upload' in doc).map(doc => API.uploadDocument(Platform.OS == 'web' ? doc.upload.file : doc.upload, {'item_id': itemId}));

      try {
        responses = await Promise.all(uploads);
      } catch (error) {
        console.log(error);
        toast.show('Failed to upload documents');
        responses = [];
      }
    }
    return responses;
  }

  async function save() {
    let itemResponse = null;
    setSaving(true);
    if (props.itemId != null) {
      let imageResponses = await uploadLocalImages(props.itemId);

      let imagesToDelete = item.images.filter(image => (('id' in image) && (localItem.images.find(li => li.id == image.id) == null)));
      let imageDeletes = imagesToDelete.map(image => API.deleteItemImage(image.id))
      if (imageDeletes.length > 0) {
        try {
          await Promise.all(imageDeletes);
        } catch (error) {
          console.log(error);
          toast.show('Failed to delete images');
          imagesToDelete = [];
        }
      }

      let documentResponses = await uploadLocalDocuments(props.itemId);

      let docsToDelete = item.documents.filter(doc => (('id' in doc) && (localItem.documents.find(ld => ld.id == doc.id) == null)));
      let docDeletes = docsToDelete.map(doc => API.deleteDocument(doc.id))
      if (docDeletes.length > 0) {
        try {
          await Promise.all(docDeletes);
        } catch (error) {
          console.log(error);
          toast.show('Failed to delete documents');
          docsToDelete = [];
        }
      }

      const diff = Object.keys(localItem)
      .filter(key => key != 'images' && key != 'documents')
      .reduce((diff, key) => {
        if (localItem[key] != item[key]) {
          diff[key] = localItem[key];
        }
        return diff;
      }, {});

      if (Object.keys(diff).length !== 0) {
        try {
          itemResponse = await API.updateItem(props.itemId, diff);
        } catch (error) {
          console.log(error);
          toast.show('Failed update item');
          itemResponse = null;
        }
      }

      if (itemResponse) {
        setItem(itemResponse);
        setLocalItem(itemResponse);
      } else {
        let newImages = [...(item.images.filter(i => (imagesToDelete.find(di => (di.id == i.id)) == null))), ...imageResponses];
        setLocalItemField('images', newImages);
        setItemField('images', newImages);

        let newDocuments = [...item.documents.filter(d => (docsToDelete.find(dd => (dd.id == d.id)) == null)), ...documentResponses];
        setLocalItemField('documents', newDocuments);
        setItemField('documents', newDocuments);
      }
    } else {
      const newItem = Object.keys(localItem).filter(key => key != 'images' && key != 'documents').reduce((obj, key) => {
        obj[key] = localItem[key];
        return obj;
      }, {});
      // Create a new item
      try {
        itemResponse = await API.createItem(newItem);
      } catch(error) {
        toast.show('Failed to create item')
        console.log(error);
      }

      let images = [];
      let documents = [];
      if (itemResponse) {
        images = await uploadLocalImages(itemResponse.id);
        documents = await uploadLocalDocuments(itemResponse.id);
        itemResponse = {...itemResponse, images: images, documents: documents};
      }
    }
    setSaving(false);
    console.log(itemResponse);
    if (itemResponse) {
      props.onSave(itemResponse);
    } else {
      props.onSave(item);
    }
  }

  async function deleteItem() {
    try {
      setSaving(true);
      await API.deleteItem(props.itemId);
      setSaving(false);

      if (authContext.user.role == 'JEWELER') {
        navigation.replace('JewelerUserCollectionScreen', {id: item.owner_id});
      } else {
        navigation.replace('CollectionScreen');
      }
    } catch(error) {
      toast.show('Failed to delete item');
      console.log(error);
    }
  }

  function deleteImage(image) {
    let key = 'upload' in image ? 'upload' : 'id';
    setLocalItemField('images', localItem.images.filter(i => i[key] != image[key]));
  }

  function deleteDocument(document) {
    let key = 'upload' in document ? 'upload' : 'id';
    setLocalItemField('documents', localItem.documents.filter(d => d[key] != document[key]));
  }

  function isModified() {
    if (localItem === null) return false;
    return Object.keys(localItem)
    .reduce((changed, key) => {
      if (localItem[key] != item[key]) {
        return true;
      }
      return changed;
    }, false);
  }

  function isValid() {
    return localItem.description != null;
  }

  useEffect(() => {
    if (props.itemId === null) {
      let newItem = {};
      if (authContext.user.role == 'JEWELER') {
        newItem = {'owner_id': props.userId};
      }

      // This is a creation form.
      setItem(newItem);
      setLocalItem(newItem);
      return;
    }

    API.item(props.itemId).then(response => {
      setItem(response);
      setLocalItem(response);
    }).catch(error => {
      console.log(error);
    });
  }, [props.itemId])

  let warrantyDocs = localItem?.documents?.length > 0 ? localItem.documents.filter(doc => doc.purpose == 'WARRANTY' || doc.upload) : [];
  return (
    <View style={styles.containerContainer}>
      <Header
        title={props.itemId == null ? 'Create Item' : 'Edit ' + (item == null ? '' : '' + item.description)}
      />
    <ScrollView>
      <View style={styles.container}>
        {
          localItem === null || saving ?
          <ActivityIndicator size="large"/>
          :
          <View>
            <View style={styles.itemListwithGapTopBorder}>
              <View style={styles.itemListTitle}>
              <Text style={styles.subTitleText}>Details</Text>
              </View>
              <View style={styles.blockNoBorder}>
              <View style={styles.inputContainer}>
                <Text style={styles.inputLabel}>Title</Text>
                <TextInput style={styles.input} value={localItem.description || ''} onChangeText={(value) => setLocalItemField('description', value)}></TextInput>
              </View>
              <View style={styles.inputContainer}>
                <Text style={styles.inputLabel}>Brand</Text>
                <TextInput style={styles.input} value={localItem.brand || ''} onChangeText={(value) => setLocalItemField('brand', value)}></TextInput>
              </View>
              <View style={styles.inputContainer}>
                <Text style={styles.inputLabel}>Reference</Text>
                <TextInput style={styles.input} value={localItem.reference || ''} onChangeText={(value) => setLocalItemField('reference', value)}></TextInput>
              </View>
              <View style={styles.inputContainer}>
                <Text style={styles.inputLabel}>Serial Number</Text>
                <TextInput style={styles.input} value={localItem.serial_number || ''} onChangeText={(value) => setLocalItemField('serial_number', value)}></TextInput>
              </View>
            </View>
              </View>

            <View style={styles.itemListwithGap}>
              <View style={styles.itemListTitle}>
              <Text style={styles.subTitleText}>Images</Text>
              </View>
              <View style={styles.blockNoBorder}>
              <View style={{display: 'flex', flexDirection: 'row'}}>
              {
                localItem.images?.length > 0 ?
                localItem.images.map(image =>
                <View style={styles.image} key={image.upload || image.id}>{'upload' in image ?
                    <Image style={styles.thumbnailImg} source={{uri: image.upload}}/> :
                    <ItemImage style={styles.thumbnailImg} image={image}/>}
                    <TouchableOpacity onPress={() => deleteImage(image)}>
                      <FontAwesome5 style={styles.imgAction} name={'trash'} color={'black'} size={15}/>
                    </TouchableOpacity>
                </View>
                )
                :
              <Text style={styles.timelineItemText}>No images found.</Text>
              }
              </View>

            <Button title={"Add Image"} color={colors.primary} onPress={pickImage}/>
            </View>
            </View>

              <View style={styles.itemListwithGap}>
                <View style={styles.itemListTitle}>
                <Text style={styles.subTitleText}>Warranty Documents</Text>
                </View>
                <View style={styles.blockNoBorder}>
            <View style={styles.documents}>
            {
              warrantyDocs.length > 0 ?
              warrantyDocs.map((document) => {
                return <View key={document.id || document.upload} style={styles.documentItem}>
                  <DocumentLink document={document}/>
                    <TouchableOpacity onPress={() => deleteDocument(document)}>
                      <FontAwesome5 style={styles.smallListItemAction} name={'trash'} color={'black'} size={15}/>
                    </TouchableOpacity>
                </View>
              })
              :
              <Text style={styles.timelineItemText}>No documents found.</Text>
            }
              </View>

              <Button title={"Add Document"} color={colors.primary} onPress={pickDocument}/>

              </View>
              </View>
          </View>
        }

        <View style={styles.footerButtonContainerTopBorder}>
        <IconButton title='Save'onPress={save} disabled={!isModified() || !isValid()}/>
        {
          props.itemId === null ?
          <></> :
          <Button title={"Delete Item"} color={colors.redButtom} onPress={deleteItem}/>
        }
            </View>
      </View>
    </ScrollView>
    </View>
  )
}
export function ItemEditor({ route, navigation }) {
  return <ItemForm itemId={route.params?.id} onSave={(item) => {navigation.navigate('ItemDetails', {'id': item.id})}}/>
}

export function ItemCreator({ route, navigation }) {
  return <ItemForm itemId={null} onSave={(item) => {navigation.replace('ItemDetails', {'id': item.id})}}/>
}

export function JewelerUserItemCreator({ route, navigation }) {
  const userId = route.params.id;
  return <ItemForm itemId={null} userId={userId} onSave={(item) => {navigation.replace('ItemDetails', {'id': item.id})}}/>
}

export function ServiceTicketItemCreator({ route, navigation }) {
  const ticketContext = useContext(TicketContext);

  useEffect(() => {
    // If no user is selected, redirect back to the ticket overview.
    if (ticketContext.ticket?.user?.id == null) {
      navigation.replace('ServiceTicketEditorScreen');
    }
  }, [ticketContext.ticket?.user?.id]);

  return ticketContext.ticket?.user?.id ?
    <ItemForm itemId={null} userId={ticketContext.ticket.user.id} onSave={(item) => {
      ticketContext.dispatch({type: Actions.CreateItem, item: item, itemId: item.id});
      navigation.pop();
      navigation.pop();
    }}/>
    : <></>;
}
