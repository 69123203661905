import React, { useEffect, useState } from 'react';
import { View, SafeAreaView, Text, ActivityIndicator, ScrollView, TextInput, Image, Alert } from 'react-native';
import { useToast } from "react-native-toast-notifications";
import * as ImagePicker from 'expo-image-picker';
import { styles } from '../styles/global';
import { API } from '../lib/api';
import IconButton from '../components/iconButton';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';

export default function JewelerLocationForm({ route, navigation }) {
  const locationId = route.params?.id;
  const toast = useToast();
  const [location, setLocation] = useState(null);
  const [localLocation, setLocalLocation] = useState(null);
  const [saving, setSaving] = useState(false);
  
  function setField(obj, setter, field, value) {
    setter({
      ...obj,
      [field]: value
    });
  }
  const setLocationField = (field, value) => setField(location, setLocation, field, value);
  const setLocalField = (field, value) => setField(localLocation, setLocalLocation, field, value);

  async function pickImage() {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (!result.canceled) {
      setLocalField('image_url', result.assets[0].uri);
    }
  };

  async function save() {
    setSaving(true);
    if (locationId != 'new') {
      let promises = [];
      const locationDiff = Object.keys(localLocation)
      .filter(key => key != 'image_url')
      .reduce((diff, key) => {
        if (localLocation[key] != location[key]) {
          diff[key] = localLocation[key];
        }
        return diff;
      }, {});

      if (Object.keys(locationDiff).length !== 0) {
        promises.push(API.updateLocation(locationId, locationDiff).then(response => {
          setLocation(response);
        }).catch(error => {
          toast.show('Failed to save changes.')
          console.log(error);
        }));       
      }

      let imagePromise = null;
      if (location.image_url && !localLocation.image_url) {
        imagePromise = API.deleteLocationImage(location.image_url);
      } else if (localLocation.image_url && localLocation.image_url != location.image_url) {
        imagePromise = API.uploadLocationImage(locationId, localLocation.image_url);
      }

      if (imagePromise) {
        promises.push(imagePromise);
        imagePromise.then(response => {
          setLocalField('image_url', response['image_url']);
          setLocationField('image_url', response['image_url']);
        }).catch((error) => {
          toast.show('Failed to ' + (localLocation['image_url'] ? 'upload' : 'delete') + ' image');
          console.log(error);
        });
      }

      Promise.all(promises).then(() => {
        setSaving(false);
      }).catch(() => {
        setSaving(false);
      });
    } else {
      const newLocation = Object.keys(localLocation).filter(key => key != 'image_url').reduce((obj, key) => {
        obj[key] = localLocation[key];
        return obj;
      }, {});

      // Create a new location
      API.createLocation(newLocation).then(response => {
        if (localLocation.image_url) {
          return API.uploadLocationImage(response.id, localLocation.image_url);
        }
      }).then(() => {
        navigation.navigate('JewelerLocationsScreen');
      }).catch(error => {
        toast.show('Failed to create location.')
        console.log(error);
      });
    }
  }

  function deleteLocation() {
    API.deleteLocation(locationId).then(() => {
      toast.show('Deleted ' + location.name);
      navigation.navigate('JewelerLocationsScreen');
    }).catch(error => {
      toast.show('Failed to delete location');
      console.log(error);
    })
  }

  function isModified() {
    if (localLocation === null) return false;
    return Object.keys(localLocation)
    .reduce((changed, key) => {
      if (localLocation[key] != location[key]) {
        return true;
      }
      return changed;
    }, false);
  }

  useEffect(() => {
    if (locationId === 'new') {
      // This is a creation form.
      setLocation({});
      setLocalLocation({});
      return;
    }

    API.location(locationId).then(response => {
      setLocation(response);
      setLocalLocation(response);
    }).catch(error => {
      toast.show('Failed to fetch location');
      console.log(error);
    });
  }, [locationId])

  return (
  <SafeAreaView style={styles.safeArea}>
    <ScrollView>
      <View style={styles.container}>
        <Text style={styles.titleText}>Location Editor</Text>
        {
          localLocation === null || saving ?
          <ActivityIndicator size="large"/>
          :
          <View>
            {
              localLocation.image_url ?
              <Image style={styles.thumbnailImg} source={{uri: (localLocation.image_url.startsWith('/') ? API.host : '') + localLocation.image_url}}/> :
              <FontAwesome5 size={78} color="#aaa" name="image" />
            }
            <TextInput style={styles.input} placeholder="Name" value={localLocation.name} onChangeText={(value) => setLocalField('name', value)}></TextInput>
            <TextInput style={styles.input} placeholder="Street address" value={localLocation.address1} onChangeText={(value) => setLocalField('address1', value)}></TextInput>
            <TextInput style={styles.input} placeholder="Apt, suite, unit, etc." value={localLocation.address2} onChangeText={(value) => setLocalField('address2', value)}></TextInput>
            <TextInput style={styles.input} placeholder="City" value={localLocation.city} onChangeText={(value) => setLocalField('city', value)}></TextInput>
            <TextInput style={styles.input} placeholder="State" value={localLocation.state} onChangeText={(value) => setLocalField('state', value)}></TextInput>
            <TextInput style={styles.input} placeholder="ZIP Code" value={localLocation.postal_code} onChangeText={(value) => setLocalField('postal_code', value)}></TextInput>
          </View>
        }

        <IconButton title='Select Image' icon='image' onPress={pickImage}/>
        <IconButton title='Delete Image' icon='trash' onPress={() => setLocalField('image_url', null)} disabled={localLocation?.image_url == null}/>
        <IconButton title='Save' icon='save' onPress={save} disabled={!isModified()}/>
        {
          locationId == 'new' ?
          <></> :
          <IconButton title='Delete Location' icon='trash' onPress={deleteLocation}/>
        }
      </View>
    </ScrollView>
  </SafeAreaView>
  )
}
