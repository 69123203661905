import React, { useContext, useEffect, useState } from 'react';
import { ActivityIndicator, SafeAreaView, Text, ScrollView, View } from 'react-native';
import { LineChart } from "react-native-chart-kit";
import { useToast } from 'react-native-toast-notifications';
import { DateTime } from 'luxon';
import { styles } from '../styles/global';
import { API } from '../lib/api';
import ItemImage from '../components/itemImage';
import { AuthContext } from '../context';
import QuickActionButton from '../components/quickActionButton';
import DocumentLink from '../components/documentLink';

const chartConfig = {
  backgroundColor: '#ffffff',
  backgroundGradientFrom: '#ffffff',
  backgroundGradientTo: '#ffffff',
  decimalPlaces: 0,
  color: (opacity = 0) => `rgba(0, 0, 0, ${opacity})`,
  strokeWidth: 2,
  useShadowColorFromDataset: false
};

function parseDateTime(s)
{
  return DateTime.fromISO(s, { zone: "UTC" }).setZone('system');
}

export default function ItemAppraisalScreen({ route, navigation }) {
  const itemId = route.params?.id;
  const authContext = useContext(AuthContext);
  const [ item, setItem ] = useState(null);
  const toast = useToast();

  async function queryAPI() {
    try {
      let item = await API.item(itemId)
      setItem(item);
    } catch (error) {
      toast.show('Failed to load item details');
      console.log(error);
    }
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // Refresh when the screen enters focus.
      queryAPI();
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    setItem(null);
    queryAPI();
  }, [itemId]);

  let lastAppraisal = item?.appraisals.length > 0 ? item.appraisals[item.appraisals.length-1] : null;
  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView>
        <View style={styles.container}>
          { item == null ?
            <ActivityIndicator size="large"/> :
            <View>
              {
                authContext.user.role == 'JEWELER' ?
                <View style={styles.itemActionGrid}>
                  <QuickActionButton icon='tools' title='New Appraisal' linkTo={{ screen: 'AppraisalUpload', params: {id: itemId}}}/>
                </View>
                :
                <></>
              }
              <View>
                <Text style={styles.smallTitleText}>{item.brand} {item.description}</Text>
                <ItemImage style={styles.thumbnailImg} image={item?.images[0]}/>
              </View>
              { item.appraisals.length > 0 ?
                <View>
                  <Text style={styles.helpText}>Appraisal Value: ${lastAppraisal.value.toLocaleString('en', {useGrouping: true})}</Text>
                  <Text style={styles.helpText}>Appraisal Date: {parseDateTime(lastAppraisal.datetime).toLocaleString(DateTime.DATE_MED)}</Text>
                  <Text style={styles.helpText}>Appraiser: {lastAppraisal.appraiser}</Text>
                  <DocumentLink document={lastAppraisal.document}/>
                  <Text style={styles.smallSubTitleText}>Historical Value</Text>
                  <LineChart
                      data={{
                        labels: item.appraisals.map(ap => parseDateTime(ap.datetime).toLocaleString({month: 'short', day: 'numeric'})),
                        datasets: [{ data: item.appraisals.map(ap => ap.value) }]
                      }}
                      width={600}
                      height={175}
                      chartConfig={chartConfig}
                      decimalPlaces={0}
                      bezier
                      fromZero
                      yAxisLabel="$"
                      style={styles.chart}
                    />
                </View>
                :
                <Text>No appraisals found.</Text>
              }
            </View>
          }
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
