import React, { useContext, useEffect, useState } from 'react';
import {  View, SafeAreaView, Text, ScrollView } from 'react-native';
import { styles } from '../styles/global';
import QuickActionButton from '../components/quickActionButton';
import TicketList from '../components/ticketList';

export default function JewelerJobs({ navigation }) {
  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView>
        <View style={styles.fullContainer}>
          <Text style={styles.titleText}>Jobs</Text>
          <TicketList/>
          <Text style={styles.subTitleTextOrphanGap}>Actions</Text>
          <View style={styles.itemActionGrid}>
            <QuickActionButton icon='tools' title='New Job' linkTo={{ screen: 'ServiceTicketCreatorScreen'}}/>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}
