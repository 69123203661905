import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  ImageBackground,
  Dimensions,
  FlatList,
  Switch,
  ScrollView,
  TextInput
} from "react-native";

import { colors } from "../../config/Colors";
import Header from "../../components/Common/Header";
import MemoryItem from "../../components/Capture/MemoryItem";
import ServiceItem from "../../components/Capture/ServiceItem";
import Button from "../../components/Common/Button";

const { width } = Dimensions.get("window");

export default function RequestServiceScreen() {

  const [isEnabled, setIsEnabled] = useState(false);
  const toggleSwitchOne = () => setIsEnabled(previousState => !previousState);
  const toggleSwitchTwo = () => setIsEnabled(previousState => !previousState);
  const toggleSwitchThree = () => setIsEnabled(previousState => !previousState);
  const toggleSwitchFour = () => setIsEnabled(previousState => !previousState);
  const [services, setServices] = useState([
    {
      id: "1",
      avatarUri: require("../../assets/icons/Common/rolexGmtAvatar.png"),
      title: "Rolex GMT-Master II",
      subtitle: "Full Service",
    },
    {
      id: "2",
      avatarUri: require("../../assets/icons/Common/frankthemen.png"),
      title: "Patek Philippe Nautilus 5811",
      subtitle: "Polish Bracelet",
    },
  ]);

  const renderItem = ({ item }) => (
    <ServiceItem
      avatarUri={item.avatarUri}
      title={item.title}
      subtitle={item.subtitle}
    />
  );

  return (
    <View style={styles.containerContainer}>
    <View style={styles.container}>
      <Header title="Request Service" />
      <ScrollView showsVerticalScrollIndicator={false}>
      <View
        style={{
          ...styles.iphoneGalleryContainer,
          borderWidth: 1,
          borderColor: colors.tabTopBorder,
          height: 72,
        }}
      >
        <Text style={styles.iphoneGalleryText}>Select an Item</Text>
      </View>
      <MemoryItem
        avatarUri={require("../../assets/icons/Common/rolexGmtAvatar.png")}
        title="GMT-Master II"
        subtitle="Rolex"
      />
      <View
        style={{
          ...styles.iphoneGalleryContainerNoTop,
          borderWidth: 1,
          borderColor: colors.tabTopBorder,
          height: 72,
        }}
      >
        <Text style={styles.iphoneGalleryText}>Select a Service Center</Text>
      </View>
      <MemoryItem
        avatarUri={require("../../assets/icons/Common/sawmill.png")}
        title="Diamond Cellar (Dublin, OH)"
        subtitle="6280 Sawmill Road"
      />

          <View
            style={{
              ...styles.iphoneGalleryContainerNoTop,
              borderWidth: 1,
              borderColor: "#ddd",
              height: 72,
            }}
          >
            <Text style={styles.iphoneGalleryText}>Add Details</Text>
          </View>
          <View style={styles.serviceDetailsBox}>
            <View style={styles.switchContainer}>
              <Switch style={styles.toggleSwitch}
                onValueChange={toggleSwitchOne}
                value={isEnabled}
              />
              <Text style={styles.switchText}>Full Service</Text>
          </View>
          <View style={styles.switchContainer}>
            <Switch style={styles.toggleSwitch}
              onValueChange={toggleSwitchTwo}
              value={isEnabled}
            />
          <Text style={styles.switchText}>Appraisal</Text>
        </View>
        <View style={styles.switchContainer}>
          <Switch style={styles.toggleSwitch}
            onValueChange={toggleSwitchThree}
            value={isEnabled}
          />
        <Text style={styles.switchText}>Polish</Text>
      </View>
      <View style={styles.switchContainer}>
        <Switch style={styles.toggleSwitch}
          onValueChange={toggleSwitchFour}
          value={isEnabled}
        />
      <Text style={styles.switchText}>Other Repair</Text>
    </View>
          <TextInput
            style={styles.descriptionInput}
            placeholder="Tell us about the item you wish to have serviced."
            multiline
          />
          </View>
            <View style={styles.buttonContainer}>
           <Button
           title={"Submit"}
           color={colors.primary}
           />
         </View>

      </ScrollView>

    </View>
      </View>
  );
}

const styles = StyleSheet.create({
  containerContainer: {
    backgroundColor: "white",
    flex: 1
  },
  container: {
    flex: 1,
    backgroundColor: colors.white,
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight:'auto'
  },
  serviceDetailsBox: {
    marginTop: 10
  },
  switchContainer: {
    marginLeft: 20,
    flexDirection:'row',
    flexWrap:'wrap',
    marginTop: 10
  },
  toggleSwitch: {
  },
  switchText: {
    marginLeft: 10,
    fontFamily: "Regular",
    marginTop: 7
  },
  tabContainer: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: colors.tabTopBorder,
  },
  tabButton: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 15,
  },
  tabText: {
    fontSize: 14,
    color: colors.primary,
    fontFamily: "Regular",
  },

  activeTabText: {
    fontSize: 14,
    color: colors.primary,
    fontFamily: "SemiBold",
  },

  activeTab: {
    borderBottomWidth: 2,
    borderBottomColor: colors.primary,
  },
  imageBackground: {
    width: width,
    height: 170,
    justifyContent: "center",
    alignItems: "center",
  },
  imageBackgroundText: {
    fontSize: 14,
    color: colors.black,
    fontFamily: "SemiBold",
  },
  descriptionInput: {
    borderColor: colors.primary,
    borderWidth: 1,
    borderRadius: 6,
    padding: 12,
    paddingTop: 15,
    margin: 20,
    height: 168,
    textAlignVertical: "top",
    color: colors.primary,
    fontFamily: "Regular",
  },
  header: {
    padding: 20,
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: colors.tabTopBorder,
  },
  headerText: {
    fontSize: 20,
    color: colors.black,
  },
  gallery: {},
  imageItem: {
    width: width / 3,
    height: width / 3,
    borderWidth: 1,
    borderColor: colors.white,
  },
  image: {
    width: "100%",
    height: "100%",
  },
  iphoneGalleryContainer: {
    width: width,
    height: 48,
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "left",
    paddingLeft: 20,
    borderTopWidth: 1,
    borderLeftWidth: 0,
    borderTopColor: colors.tabTopBorder,
  },
  iphoneGalleryContainerNoTop: {
    width: width,
    height: 48,
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "left",
    paddingLeft: 20,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderTopColor: colors.tabTopBorder,
  },
  iphoneGalleryText: {
    fontSize: 14,
    fontFamily: "SemiBold",
    color: colors.primary,
  },
  buttonContainer: {
    marginBottom: 50
  },
});
