import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { styles } from '../styles/global';
import { useLinkProps } from '@react-navigation/native';

export default function QuickActionButton(props) {
  const {title, icon, linkTo} = props;
  const linkProps = useLinkProps({to: linkTo || ''});
  return (
    <View style={styles.itemAction}>
      <View style={styles.boxContainer}>
        <TouchableOpacity style={styles.itemActionContent} {...(linkTo ? linkProps : {})}>
          <FontAwesome5 style={{ marginBottom: 10 }} name={icon} color={'black'} size={24} solid />
          <Text style={styles.itemActionTitleLarge}>{title}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
