import React, { useContext, useEffect, useState } from 'react';
import { SafeAreaView, Text, View, Dimensions, ScrollView } from 'react-native';
import { useToast } from "react-native-toast-notifications";
import { API } from '../lib/api';
import { styles } from '../styles/global';
import { TicketContext } from '../context';
import JewelerLocation from '../components/jewelerLocation';
import Header from "../components/Common/Header";

export default function ServiceTicketLocationScreen({ route, navigation }) {
  const ticketContext = useContext(TicketContext);
  const toast = useToast();

  const [locations, setLocations] = useState({
    items: null,
    refreshing: false,
  });

  function queryAPI() {
    setLocations({...locations, refreshing: true});
    API.locations().then(items => {
      setLocations({refreshing: false, items: items});
    }).catch(err => {
      // TODO: provide a better "failed to load items" screen.
      setLocations({...locations, items: [], refreshing: false});
      toast.show('Failed to load locations');
      console.log(err);
    });
  }

  useEffect(() => {
    if (locations.items !== null) return;
    queryAPI();
  }, [locations.items]);

  function selectLocation(locationId) {
    ticketContext.setTicketField('location', locations.items.filter(l => l.id == locationId)[0]);
    navigation.goBack();
  }

  return (

    <View style={styles.containerContainer}>
      <View style={styles.container}>
        <Header
          title={"Select a Location"}
        />

    <ScrollView style={styles.scrollArea}>
      <View style={styles.container}>

        {
          (locations.items === null ? [] : locations.items).map(location => {
            return <JewelerLocation key={location.id} location={location} onPress={selectLocation}/>
          })
        }
      </View>

</ScrollView>
  </View>
  </View>


  )
}
