import React from 'react';
import { Image, FlatList, StyleSheet, View, Dimensions, TouchableOpacity } from 'react-native';
import { API } from '../../lib/api';
import { useNavigation } from "@react-navigation/native";

const { width } = Dimensions.get('window');
const numColumns = 3;

let size = width / numColumns;
size = width > 500 ? 500 / numColumns : size;

const ImageGrid = ({data}) => {
  const navigation=useNavigation();
  return (
    <FlatList
      data={data}
      renderItem={({ item }) => (
        <TouchableOpacity style={styles.imageContainer} onPress={()=>navigation.navigate('MemoryDetailScreen', {id: item.id, data: item})}>
        <Image source={{uri: API.apiUrl(item.item_image.url)}} style={styles.image} />
        </TouchableOpacity>
      )}
      keyExtractor={(item) => item.id}
      numColumns={numColumns}
      // Optional: if you do not want spaces between the images
      columnWrapperStyle={styles.column}
    />
  );
};

const styles = StyleSheet.create({
  image: {
    width: size,
    height: size,
  },
  imageContainer: {
  },
  column: {
    justifyContent: 'flex-start'
  },
});

export default ImageGrid;
