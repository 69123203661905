const SampleJsonData = [
  {
    id: "1",
    image: require("../assets/icons/Profile/item1.png"),
    title: "Rolex GMT-Master II",
    subtitle: "Yesterday, 12:49 PM",
  },
  {
    id: "2",
    image: require("../assets/icons/Profile/item2.png"),
    title: "Rolex GMT-Master II",
    subtitle: "12 August 2023, 11:15 AM",
  },
  {
    id: "3",
    image: require("../assets/icons/Profile/item3.png"),
    title: "Patek Philippe Nautilus 5811",
    subtitle: "7 August 2023, 2:24 PM",
  },
  {
    id: "4",
    image: require("../assets/icons/Profile/item4.png"),
    title: "Tudor Pelagos LHD",
    subtitle: "2 August 2023, 11:45 AM",
  },
  {
    id: "5",
    image: require("../assets/icons/Profile/item5.png"),
    title: "Tudor Pelagos LHD",
    subtitle: "15 July 2023, 9:12 AM",
  },
  {
    id: "6",
    image: require("../assets/icons/Profile/item4.png"),
    title: "Tudor Pelagos LHD",
    subtitle: "3 July 2023, 4:53 PM",
  },
];

const ItemsJsonData = [
  {
    id: "1",
    image: require("../assets/icons/Profile/item1.png"),
    title: "Rolex GMT-Master II",
    subtitle: "Yesterday, 12:49 PM",
  },
  {
    id: "2",
    image: require("../assets/icons/Profile/item2.png"),
    title: "Rolex GMT-Master II",
    subtitle: "12 August 2023, 11:15 AM",
  },
  {
    id: "3",
    image: require("../assets/icons/Profile/item3.png"),
    title: "Patek Philippe Nautilus 5811",
    subtitle: "7 August 2023, 2:24 PM",
  },
];

const TransferHistory = [
  {
    id: "1",
    avatarUri: require("../assets/icons/ItemDetails/Appraisal.png"),
    title: "Appraisal",
    subtitle: "Yesterday, 12:49 PM",
  },
  {
    id: "2",
    avatarUri: require("../assets/icons/ItemDetails/fullService.png"),
    title: "Full Service",
    subtitle: "3 August 2023, 2:15 PM",
  },
  {
    id: "3",
    avatarUri: require("../assets/icons/ItemDetails/itemCreated.png"),
    title: "Item Created",
    subtitle: "29 March 2020, 12:26 PM",
  },
];

const ServiceHistory = [
  {
    id: "1",
    avatarUri: require("../assets/icons/ItemDetails/Appraisal.png"),
    title: "Item is being appraised",
    subtitle: "Yesterday, 12:49 PM",
  },
  {
    id: "2",
    avatarUri: require("../assets/icons/ItemDetails/fullService.png"),
    title: "Full Service preformed",
    subtitle: "3 Jan 2024, 2:15 PM",
  },
  {
    id: "3",
    avatarUri: require("../assets/icons/ItemDetails/itemCreated.png"),
    title: "Ticket Created",
    subtitle: "29 Dec 2023, 12:26 PM",
  },
];

export { SampleJsonData, ItemsJsonData, TransferHistory, ServiceHistory };
