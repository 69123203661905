import React, { useEffect, useState } from 'react';
import { styles } from '../styles/global';
import { API } from '../lib/api';
import { ActivityIndicator, Button, SafeAreaView, Text, View, ScrollView} from 'react-native';
import CollectionItem from '../components/collectionItem';
import Header from "../components/Common/Header";
import User from '../components/user';
import UserSearch from '../components/userSearch';

function ConfirmTransfer(props) {
  return (
    <View>
      <Text>Confirm and Send</Text>
      <CollectionItem item={props.item}/>
      <User user={props.recipient}/>
      <View style={{flexDirection: 'row'}}>
        <Button onPress={props.onConfirm} title="Confirm"/>
        <Button onPress={props.onCancel} title="Cancel"/>
      </View>
    </View>
  );
}

function SentItem(props) {
  return (
    <View>
      <Text>Sent!</Text>
      <CollectionItem item={props.item}/>
      <User user={props.recipient}/>
    </View>
  )
}

const StageFindUser = 1;
const StageConfirmTransfer = 2;
const StageSending = 3;
const StageSent = 4;

function SendItemFlow(props) {
  const [stage, setStage] = useState(StageFindUser);
  const [item, setItem] = useState(null);
  const [recipient, setRecipient] = useState(null);

  useEffect(() => {
    if (item !== null) return;
    API.item(props.itemId).then(responseItem => {
      setItem(responseItem);
    }).catch(() => {
      setItem({});
      console.log('Failed to get item');
    })
  });

  useEffect(() => {
    if (stage !== StageSending) return;
    API.createTransfer(props.itemId, recipient.id).then(res => {
      setStage(StageSent);
    }).catch(() => {
      console.log('Failed to create transfer');
    });
  });

  useEffect(() => {
    // Once the user is selected, advance to the next stage.
    if (stage == StageFindUser && recipient !== null) {
      setStage(StageConfirmTransfer);
    }
  });

  if (stage == StageFindUser) {
    return <UserSearch onSelectUser={setRecipient}/>;
  }
  else if (stage == StageConfirmTransfer) {
    return (
    <ConfirmTransfer
      item={item}
      recipient={recipient}
      onConfirm={() => setStage(StageSending)}
      onCancel={() => props.navigation.goBack()}
    />
    );
  }
  else if (stage == StageSending) {
    return <ActivityIndicator size="large"/>;
  }
  else if (stage == StageSent) {
    return <SentItem item={item} recipient={recipient}/>
  }
}

export default function SendItemScreen({ route, navigation }) {
  const itemId = route.params?.id;
  return (
    <View style={styles.containerContainer}>
    <View style={styles.container}>
      <Header title="Send Item" />
      <ScrollView showsVerticalScrollIndicator={false}>
        <SendItemFlow navigation={navigation} itemId={itemId}/>
            </ScrollView>
      </View>
        </View>
  );
}
