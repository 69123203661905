import React, { useEffect, useState } from 'react';
import { Image } from 'react-native';
import { API } from '../lib/api';

export default function ItemImage(props) {
  const {image, ...imageProps} = props;

  // The Image component supposedly supports a 'headers' field under the
  // 'source' prop, but it doesn't seem to work.
  // https://github.com/facebook/react-native/issues/30017
  // We use our alternate auth scheme with an auth URL param to work around this.
  let uri = props.image ? API.apiUrl('/item-images/' + props.image.id) : null;
  return <Image source={{uri: uri}} {...imageProps}/>
}
