export const colors = {
  primary: "#545F71",
  secondry: "#9BA5B7",
  black: "#000000",
  white: "#FFFFFF",
  halfblack: "rgba(0, 0, 0, 0.50)",
  tabTopBorder: "#ddd",
  border: "#DDDDDD",
  time: "#6E6E6E",
  redButtom: "#F9373F",
};
