import React, { useState } from 'react';
import { View, SafeAreaView, Text, ScrollView, TextInput } from 'react-native';
import * as DocumentPicker from 'expo-document-picker';
import { API } from '../lib/api';
import { styles } from '../styles/global';
import IconButton from '../components/iconButton';
import DocumentLink from '../components/documentLink';

export default function AppraisalUploadScreen({ route, navigation }) {
  const { id } = route.params;
  const [value, setValue] = useState('');
  const [appraiser, setAppraiser] = useState('');
  const [document, setDocument] = useState(null);

  const isValueValid = /^\$?\d+$/.test(value);
  const isValid = isValueValid && document !== null;
  const valueInputStyle = (isValueValid || value.length == 0) ? styles.input : {...styles.input, borderColor: 'red'};

  async function pickDocument() {
    let result = await DocumentPicker.getDocumentAsync({
      type: 'application/pdf',
    });

    if (!result.canceled) {
      setDocument({'upload': result.file});
    }
  };

  async function save() {
    await API.uploadAppraisal(id, document.upload, {value: value, appraiser: appraiser});
    navigation.navigate('ItemAppraisalScreen', {'id': id});
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView>
        <View style={styles.container}>
          <Text style={styles.titleText}>Appraisal Upload</Text>
          <Text>Item ID: {id}</Text>
          <TextInput style={valueInputStyle} placeholder="Appraisal Value" value={value} onChangeText={setValue} keyboardType='numeric'></TextInput>
          <TextInput style={styles.input} placeholder="Appraiser" value={appraiser} onChangeText={setAppraiser}></TextInput>
          {
            document !== null
            ?
            <DocumentLink document={document}/>
            :
            <></>
          }
          <IconButton title='Select Document' icon='file-pdf' onPress={pickDocument}/>
          <IconButton title='Save' icon='save' onPress={save} disabled={!isValid}/>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
