import { Dimensions, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { colors } from '../../config/Colors';

let { width } = Dimensions.get('window');
width = width > 500 ? 500 : width;

export default function Button({title,color,onPress}) {
  return (
    <TouchableOpacity
    onPress={onPress}
    activeOpacity={0.5}
    style={{...styles.btncontainer,backgroundColor:color}}>
      <Text style={styles.btnText}>{title}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
btncontainer:
{
    width:width-30,
    height:52,
    justifyContent:"center",
    alignItems:"center",
    borderRadius:6,
    alignSelf:"center",
    marginTop: 10
},
btnText:
{
   fontSize: 14,
   fontFamily:'SemiBold',
   color:colors.white,
}

})
