import axios from 'axios';
import Constants from 'expo-constants';
import Storage from './storage';

// Weird import to support both web/mobile
// A normal import works for mobile, but breaks web.
// https://stackoverflow.com/questions/41846669 
global.Buffer = global.Buffer || require('buffer').Buffer;

class APIIncorrectCredentials extends Error {
    constructor(...params) {
        super(...params)
    }
}

const MemoryVoteType = {
    THUMBS_UP: 'THUMBS_UP',
    THUMBS_DOWN: 'THUMBS_DOWN',
};

class APIClient {
    constructor(host) {
        this.host = host;
        this.token = null;
        this.remember = false;
    }

    /*
     * Authenticate with cached credentials, if they exist.
     */
    loginWithToken() {
        return Storage.getItem('token').then(token => {
            if (token === null) {
                throw new Error('No token in storage');
            }
            return token;
        }).then((token) => {
            // Verify the token with the backend.
            return axios.get(this.host + '/users/login', {headers: {'Authorization': 'Bearer ' + token}}).then((res) => {
                return {token: token, response: res};
            });
        }).then(state => {
            this.token = state.token;
            this.remember = true;  // logged in with a saved token - remember me was set previously.
            return state.response.data;
        });
    }

    _handleLoginResponse(data, remember) {
        if (data.token) {
            this.token = data.token;
            this.remember = remember
            let promise;
            if (remember) {
                // If the user requested rememberance, save the token.
                promise = Storage.setItem('token', data.token);
            } else {
                // Otherwise, keep the user token for the duration of their session (needed for web).
                promise = Storage.setItemSession('token', data.token);
            }

            return promise.then(() => data);
        } else {
            throw new Error('Unauthorized');
        }
    }

    apiUrl(endpoint) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return this.host + endpoint + '?auth=' + this.token;
    }

    /*
     * Authenticate using the given credentials.
     *
     * Returns a Promise.
     *
     * On success, the authentication token is cached by the API object to use
     * for future requests.
     */
    login(identity, password, remember) {
        return axios.post(this.host + '/users/login', {
            'identity': identity,
            'password': password
        })
        .then(res => this._handleLoginResponse(res.data, remember))
        .catch(error => {
            console.log(error);

            if (error.response && error.response.status == 401) {
                throw new APIIncorrectCredentials('Unauthorized');
            } else {
                Promise.reject(error);
            }
        });
    }

    register(username, name, email, password, remember) {
        return axios.post(this.host + '/users', {
            'username': username,
            'name': name,
            'email': email,
            'password': password
        }).then(res => this._handleLoginResponse(res.data, remember));
    }

    logout() {
        return Storage.deleteItem('token');
    }

    _fetchAuthEndpoint(path) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.get(this.host + path, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    /*
     * Get items from a user's collection.
     * 
     * Returns a Promise for the list of items.
     */ 
    collection(userId) {
        return this._fetchAuthEndpoint('/users/' + userId + '/collection');
    }

    /*
     * Get transfer history for a item in a user's collection.
     *
     * @param item_id The item ID
     *
     * Returns a Promise for the list of items.
     */
    transfers(item_id) {
        return this._fetchAuthEndpoint('/items/' + item_id + '/transfers');
    }

    createTransfer(item_id, user_id_to) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        let data = {'user_id_to': user_id_to};
        return axios.post(this.host + '/items/' + item_id + '/transfers', data, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    _updateTransferStatus(transfer_id, status) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.patch(this.host + '/transfers/' + transfer_id, {'status': status}, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    completeTransfer(transfer_id) {
        return this._updateTransferStatus(transfer_id, 'COMPLETED');
    }

    cancelTransfer(transfer_id) {
        return this._updateTransferStatus(transfer_id, 'CANCELED');
    }

    rejectTransfer(transfer_id) {
        return this._updateTransferStatus(transfer_id, 'REJECTED');
    }

    /*
     * Get the currently logged in user's info.
     *
     * Returns a Promise for the user info.
     */
    user(user) {
        return this._fetchAuthEndpoint('/users/' + user);
    }

    updateUser(user, data) {
        // This endpoint is usable both in authenticated and unauthenticated
        // states (although has different capabilities depending on whether
        // the user is authenticated).
        let headers = {};
        if (this.token) {
            headers = {'Authorization': 'Bearer ' + this.token};
        }

        return axios.patch(this.host + '/users/' + user, data, {headers: headers}).then(res => {
            return 'token' in res.data ? this._handleLoginResponse(res.data, this.remember) : res.data;
        });
    }

    resendEmailVerification() {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.post(this.host + '/users/email/resend', {}, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    forgotPassword(email) {
        return axios.post(this.host + '/users/password/forgot', {'email': email},).then(res => res.data);
    }

    /*
     * Search for a list of matching users.
     */
    users(query = null) {
        let url;
        if (query !== null)
        {
            url = '/users?q=' + encodeURIComponent(query);
        }
        else {
            url = '/users';
        }
        return this._fetchAuthEndpoint(url);
    }

    item(id) {
        return this._fetchAuthEndpoint('/items/' + id);
    }

    createItem(data) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.post(this.host + '/items', data, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    deleteItem(itemId) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.delete(this.host + '/items/' + itemId, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    updateItem(id, data) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.patch(this.host + '/items/' + id, data, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    uploadItemImage(data_uri) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }

        // We *must* use fetch to both get the image and post it again. It is difficult to get the actual raw bytes here.
        // See: https://github.com/facebook/react-native/issues/22681
        return fetch(data_uri).then(res => {
            return res.blob().then(blob => {
                return fetch(this.host + '/item-images', {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        'Content-Type': res.headers['content-type'],
                    },
                    body: blob
                }).then(res => res.json());
            });
        });
    }

    setItemImageItemId(imageId, itemId) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.patch(this.host + '/item-images/' + imageId, {'item_id': itemId}, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    deleteItemImage(imageId) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.delete(this.host + '/item-images/' + imageId, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    ticket(id) {
        return this._fetchAuthEndpoint('/tickets/' + id);
    }

    tickets() {
        return this._fetchAuthEndpoint('/tickets');
    }

    summary() {
        return this._fetchAuthEndpoint('/summary');
    }

    settings() {
        return this._fetchAuthEndpoint('/settings');
    }

    updateSettings(data) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.patch(this.host + '/settings', data, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    submitTicket(data) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.post(this.host + '/tickets', data, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    updateTicket(ticketId, data) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.put(this.host + '/tickets/' + ticketId, data, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    addTicketEvent(ticketId, data) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.post(this.host + '/tickets/' + ticketId + '/events', data, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    locations() {
        return axios.get(this.host + '/locations').then(res => res.data);
    }

    location(id) {
        return this._fetchAuthEndpoint('/locations/' + id);
    }

    createLocation(data) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.post(this.host + '/locations', data, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    deleteLocation(id) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.delete(this.host + '/locations/' + id, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    updateLocation(id, data) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.patch(this.host + '/locations/' + id, data, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    uploadLocationImage(id, data_uri) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }

        // We *must* use fetch to both get the image and post it again. It is difficult to get the actual raw bytes here.
        // See: https://github.com/facebook/react-native/issues/22681
        return fetch(data_uri).then(res => {
            return res.blob().then(blob => {
                return fetch(this.host + '/locations/' + id + '/images', {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        'Content-Type': res.headers['content-type'],
                    },
                    body: blob
                }).then(res => res.json());
            });
        });
    }

    deleteLocationImage(url) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.delete(this.host + url, {headers: {
            'Authorization': 'Bearer ' + this.token,
        }}).then(res => res.data);
    }

    savePushToken(pushToken) {
        return axios.put(this.host + '/push/' + pushToken, {}, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    uploadDocument(file, meta) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }

        const formData = new FormData();
        formData.append('meta', JSON.stringify(meta));
        formData.append('file', file);

        return fetch(this.host + '/documents', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.token,
            },
            body: formData
        }).then(res => res.json());
    }

    uploadAppraisal(itemId, file, meta) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }

        const formData = new FormData();
        formData.append('meta', JSON.stringify(meta));
        formData.append('file', file);

        return fetch(this.host + '/items/' + itemId + '/appraisals', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.token,
            },
            body: formData
        }).then(res => res.json());
    }

    deleteDocument(id) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }

        return axios.delete(this.host + '/documents/' + id, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    /*
     * Get a user's memory feed.
     *
     * Returns a Promise for the list of items.
     */
    memories(userId = null, itemId = null) {
        let url = '/memories';
        let sep = '?';

        if (userId !== null) {
            url += sep;
            url += `user=${userId}`;
            sep = '&';
        }
        if (itemId !== null) {
            url += sep;
            url += `item=${itemId}`;
            sep = '&';
        }
        return this._fetchAuthEndpoint(url);
    }

    submitMemory(data) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.post(this.host + '/memories', data, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    vote(memory_id, type) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }

        return axios.put(
            this.host + '/memories/' + memory_id + '/vote',
            {'vote_type': type},
            {headers: {'Authorization': 'Bearer ' + this.token}}
        ).then(res => res.data);
    }

    delete_vote(memory_id) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }

        return axios.delete(this.host + '/memories/' + memory_id + '/vote', {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    follow(userId) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.put(this.host + '/users/' + userId + '/follow', null, {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    unfollow(userId) {
        if (this.token === null) {
            throw new Error('Unauthorized');
        }
        return axios.delete(this.host + '/users/' + userId + '/follow', {headers: {'Authorization': 'Bearer ' + this.token}}).then(res => res.data);
    }

    followers(userId) {
        return this._fetchAuthEndpoint('/users/' + userId + '/followers');
    }

    following(userId) {
        return this._fetchAuthEndpoint('/users/' + userId + '/following');
    }
}

const API = new APIClient(Constants.expoConfig.extra.apiHost);

export { API, APIIncorrectCredentials, MemoryVoteType };
