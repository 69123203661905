import React, { useContext, useEffect, useState, Dimensions } from 'react';
import { View, SafeAreaView, Text, ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { styles } from '../styles/global';
import { API } from '../lib/api';
import { AuthContext } from '../context.js';
import IconButton from '../components/iconButton';
import SwitchOption from '../components/switchOption';
import Header from "../components/Common/Header";
import Button from "../components/Common/Button";
import { colors } from "../config/Colors";

function SettingsView(props) {
  const authContext = useContext(AuthContext);
  const navigation = useNavigation();
  const [userSettings, setUserSettings] = useState({notification_preferences: []});
  const [synced, setSynced] = useState(false);

  function setUserSettingsField(field, val) {
    setUserSettings(obj => {return {...obj, [field]: val}});
  }

  function getPushPreference(type, name) {
    const pref = userSettings.notification_preferences.find(pref => pref.category == name);
    if (type == 'email') {
      return pref ? pref.email_enabled : false;
    } else if (type == 'push') {
      return pref ? pref.push_enabled : true;
    }
  }

  function setNotificationPreference(type, name, value) {
    console.log('set value for ' + name + ' to ' + value);
    let oldSettings = userSettings.notification_preferences.find(pref => pref.category == name);
    if (oldSettings == null) {
      oldSettings = {
        category: name,
        push_enabled: true,
        email_enabled: false
      }
    }

    let newSettings;
    if (type == 'email') {
      newSettings = {
        ...oldSettings,
        email_enabled: value
      }
    } else if (type == 'push') {
      newSettings = {
        ...oldSettings,
        push_enabled: value
      }
    }

    setUserSettingsField('notification_preferences', [
      ...userSettings.notification_preferences.filter(pref => pref.category != name),
      newSettings
    ])
  }

  useEffect(() => {
    API.settings().then(settings => {
      setUserSettings(settings);
      setSynced(true);
    }).catch(err => {
      // TODO: provide a better "failed to load items" screen.
      console.log(err);
    });
  }, [])

  useEffect(() => {
    setSynced(false);
    API.updateSettings(userSettings).then(settings => {
      setSynced(true);
    }).catch(err => {
      // TODO: provide a better "failed to load items" screen.
      console.log(err);
    });

  }, [userSettings]);

  function NotificationOption(props) {
    return <SwitchOption value={getPushPreference(props.type, props.category)} onValueChange={(val) => setNotificationPreference(props.type, props.category, val)} disabled={!synced}>
      {props.displayName}
    </SwitchOption>;
  }

  return(
    <View style={styles.containerContainer}>
      <Header title={"Settings"} />
          <ScrollView showsVerticalScrollIndicator={false}>
                  <View style={styles.container}>
        <View style={styles.itemListwithGap}>
          <View style={styles.itemListTitle}>
            <Text style={styles.subTitleText}>Account</Text>
          </View>
          <View style={styles.blockNoBorder}>
            <Text>Email Address: {authContext.user.email}</Text>
          </View>
        </View>

        <View style={styles.itemListwithGap}>
          <View style={styles.itemListTitle}>
            <Text style={styles.subTitleText}>Privacy</Text>
          </View>
          <View style={styles.blockNoBorder}>
            <SwitchOption value={userSettings.email_lookup} onValueChange={(val) => setUserSettingsField('email_lookup', val)} disabled={!synced}>Allow others to find me by Email Address</SwitchOption>
          </View>
        </View>

        <View style={styles.itemListwithGap}>
          <View style={styles.itemListTitle}>
            <Text style={styles.subTitleText}>Push Notifications</Text>
          </View>
          <View style={styles.blockNoBorder}>
            <NotificationOption type='push' category='ITEM_TRANSFER' displayName='Item Transfers'/>
            <NotificationOption type='push' category='TICKET_UPDATED' displayName='Service Ticket Updates'/>
            <NotificationOption type='push' category='SERVICE_REMINDER' displayName='Service Reminders'/>
          </View>
        </View>

        <View style={styles.itemListwithGap}>
          <View style={styles.itemListTitle}>
            <Text style={styles.subTitleText}>Email Notifications</Text>
          </View>
          <View style={styles.blockNoBorder}>
            <NotificationOption type='email' category='ITEM_TRANSFER' displayName='Item Transfers'/>
            <NotificationOption type='email' category='TICKET_UPDATED' displayName='Service Ticket Updates'/>
            <NotificationOption type='email' category='SERVICE_REMINDER' displayName='Service Reminders'/>
          </View>
        </View>
        
          <View style={styles.footerButtonContainer}>
            <Button title={"Change Password"} color={colors.primary} onPress={() => navigation.navigate('ChangePassword')}/>
            <Button title={"Logout"} color={colors.primary}  onPress={authContext.logout}/>
          </View>

</View>
    </ScrollView>
      </View>
  );
}

export default function SettingsScreen({ navigation }) {
  return (
      <SettingsView></SettingsView>
  );
}
