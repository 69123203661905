import React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { styles } from '../styles/global';
import { useLinkProps } from '@react-navigation/native';

export default function IconButton(props) {
  const {title, icon, linkTo, ...buttonProps} = props;
  const linkProps = useLinkProps({to: linkTo || ''});
  return (
    <TouchableOpacity style={{...styles.button, ...(props.disabled ? styles.disabledButton : {})}} {...buttonProps} {...(linkTo ? linkProps : {})}>
      <FontAwesome5 name={icon} size={18} color='white' solid style={{marginRight: 8}}/>
      <Text style={styles.buttonText}>{props.title}</Text>
    </TouchableOpacity>
  );
}
