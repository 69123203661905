import React from 'react';
import { View, Text, Switch } from 'react-native';

export default function SwitchOption(props) {
  const {children, value, ...switchProps} = props;
  // N.B.: The switch component uses the "activeThumbColor" prop when enabled on
  // web, but the "thumbColor" prop regardless of state on Android/iOS.
  // See: https://github.com/facebook/react-native/issues/30429
  const enabledColor = '#ffffff';
  const disabledColor = '#ffffff';
  return (
    <View style={{flexDirection: 'row', marginBottom: 10, marginTop: 10, marginLeft: 15}}>
      <Switch
        trackColor={{false: '#dfdfe2', true: '#b2b2b2'}}
        thumbColor={value ? enabledColor : disabledColor}
        activeThumbColor={enabledColor}
        value={value}
        {...switchProps}
      />
      <View style={{flexDirection: 'column', justifyContent: 'center', display: 'flex'}}>
        <Text style={{paddingLeft: 10, fontSize: 15,}}>{props.children}</Text>
      </View>
    </View>
  );
}
