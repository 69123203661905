// FeedItem.js
import React, { useContext, useState } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import { colors } from "../../config/Colors";
import { API, MemoryVoteType } from "../../lib/api";
import { useNavigation } from '@react-navigation/native';
import { AuthContext } from "../../context";

const FeedItem = ({ memoryId, username, userId, time, likes, description, imageUri, avatar, initialLikeState }) => {
  const [likeState, setLikeState] = useState(initialLikeState);
  const [likeCount, setLikeCount] = useState(likes);
  const navigation = useNavigation();
  const authContext = useContext(AuthContext);

  let toggleLikeState = (buttonState) => {
    let action;
    let desiredState;
    let countDiff = buttonState == MemoryVoteType.THUMBS_UP ? 1 : -1;
    if (likeState == buttonState) {
      action = API.delete_vote(memoryId);
      desiredState = null;
      countDiff = -1 * countDiff;
    } else {
      action = API.vote(memoryId, buttonState)
      desiredState = buttonState;
      if (likeState != null) {
        countDiff = 2 * countDiff;
      }
    }
    action.then(() => {
      setLikeState(desiredState);
      setLikeCount(c => c + countDiff);
    });
  }

  function goToProfile() {
    if (userId == authContext.user.id) {
      navigation.navigate('Profile')
    } else {
      navigation.navigate('UserProfile', {id: userId})
    }
  }

  return (
    <View style={styles.feedItem}>
      <TouchableOpacity style={styles.itemAvatarHeader} onPress={goToProfile}>
        <Image style={styles.avatar} source={avatar ? {uri: avatar} : require("../../assets/icons/blankavatar.png")} />
        <Text style={styles.username}>{username}</Text>
      </TouchableOpacity>

      <Image source={{uri: imageUri}} style={styles.image} />
      <View style={styles.interactionBar}>
        <TouchableOpacity style={styles.icon} onPress={() => toggleLikeState(MemoryVoteType.THUMBS_UP)}>
          <Image
            style={styles.thumbIcon}
            source={likeState == MemoryVoteType.THUMBS_UP ? require("../../assets/icons/Home/like_clicked.png") : require("../../assets/icons/Home/like.png")}
          />
        </TouchableOpacity>
        <TouchableOpacity style={styles.icon} onPress={() => toggleLikeState(MemoryVoteType.THUMBS_DOWN)}>
          <Image
            style={styles.thumbIcon}
            source={likeState == MemoryVoteType.THUMBS_DOWN ? require("../../assets/icons/Home/dislike_clicked.png") : require("../../assets/icons/Home/dislike.png")}
          />
        </TouchableOpacity>
      </View>
      <Text style={styles.likes}>{likeCount} thumbs up</Text>
      <Text style={styles.description}>{description}</Text>
      <Text style={styles.time}>{time}</Text>
    </View>


  );
};

const styles = StyleSheet.create({
  feedItem: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: colors.border,
    paddingBottom: 20,
  },
  username: {
    fontWeight: "bold",
    fontSize: 11,
    fontFamily: "Bold",
    color: colors.black,
  },
  image: {
    width: "100%",
    height: "undefined",
    aspectRatio: 1/1,
    marginTop: 10,
  },
  interactionBar: {
    flexDirection: "row",
    marginTop: 17,
    marginHorizontal: 15,
  },
  icon: {
    marginRight: 10,
  },
  likes: {
    fontFamily: "Bold",
    marginTop: 5,
    fontSize: 11.5,
    marginHorizontal: 12,
    marginTop: 26,
    color: colors.black,
  },
  description: {
    marginTop: 6,
    fontFamily: "Regular",
    color: colors.black,
    marginHorizontal: 12,
  },
  time: {
    color: colors.time,
    marginTop: 6,
    marginHorizontal: 12,
  },
  itemAvatarHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 16,
    marginTop: 10,
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 11,
    borderRadius: 15,
  },
  thumbIcon: {
    width: 23,
    height: 23,
  },
});

export default FeedItem;
