import React, { useContext, useEffect, useState } from 'react';
import { ActivityIndicator, View, SafeAreaView, Text, Button, TouchableOpacity, Image, ScrollView, FlatList, Dimensions } from 'react-native';
import { styles } from '../styles/global';
import { API } from '../lib/api';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import CollectionItem from '../components/collectionItem'
import { AuthContext } from '../context';
import { useLinkProps, useNavigation } from '@react-navigation/native';
import IconButton from '../components/iconButton';
import FeedItem from "../components/Home/FeedItem";
import { renderDateTime } from '../lib/utils';

function AccountSummary(props) {
  const authContext = useContext(AuthContext);
  const [loaded, setLoaded] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const [pendingTransfers, setPendingTransfers] = useState([]);
  const collectionLinkProps = useLinkProps({to: {screen: 'Collection', params: {screen: 'CollectionScreen'}}});

  useEffect(() => {
    API.summary().then(summary => {
      setItemCount(summary.item_count);
      setPendingTransfers(summary.pending_transfers);
      setLoaded(true);
    }).catch(err => {
      // TODO: provide a better "failed to load" screen.
      console.log(err);
    });
  }, [authContext.user]);

  function completeTransfer(transfer) {
    API.completeTransfer(transfer.id).then(() => {
      setPendingTransfers(pendingTransfers.slice(1))
    });
  }

  function rejectTransfer(transfer) {
    API.rejectTransfer(transfer.id).then(() => {
      setPendingTransfers(pendingTransfers.slice(1))
    })
  }

  let pendingTransfer = <></>;
  if (pendingTransfers.length > 0) {
    // TODO: Currently, we only display one pending transfer at a time.

    let transfer = pendingTransfers[0];
    pendingTransfer = (
      <View>
        <Text>Pending Transfer from {transfer.user_from.name}:</Text>
        <CollectionItem item={transfer.item}/>
        <View style={{flexDirection: 'row'}}>
          <Button title="Accept" onPress={() => {completeTransfer(transfer)}}/>
          <Button title="Reject" onPress={() => {rejectTransfer(transfer)}}/>
        </View>
      </View>
    )
  }

  return (
    <View>
      { !authContext.user.email_verified ?
        <View style={styles.alertBox}>
          <View style={styles.listItemTitleContainer}>
            <Image source={require('../assets/alert.png')} style={styles.alertIcon} />
            <Text style={styles.alertBoxTitle}>Email address not verified</Text>
          </View>
          <Text style={styles.alertBoxText}>Your email address is not yet verified. An email was sent to {authContext.user.email}. Check your email and use the verification link to dismiss this message.</Text>
          <Text style={styles.alertBoxText}>Didn't get the email? Tap to resend.</Text>
          <IconButton title='Resend' onPress={() => API.resendEmailVerification(authContext.user.id)}/>
        </View>
        :
        <></>
      }
      {pendingTransfer}
    </View>
  );
}

export default function HomeScreen({ navigation }) {
  const authContext = useContext(AuthContext);
  const [loaded, setLoaded] = useState(false);
  const [memories, setMemories] = useState([]);

  useEffect(() => {
    API.memories().then(memories => {
      setLoaded(true);
      setMemories(memories);
    }).catch(err => {
      // TODO: provide a better "failed to load" screen.
      console.log(err);
    });
  }, [authContext.user]);

  return (
    <View style={styles.containerContainer}>
    <View style={styles.homeHeader}>
    <Image
    style={styles.logoTop}
    source={require('../assets/icons/Home/jewelryLogo.png')}
    />
    </View>
    <ScrollView style={styles.homeScrollArea} showsVerticalScrollIndicator={false}>
      <View style={styles.container}>
          <View style={{ flex: 1 }}>
            <FlatList
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{ paddingBottom: 100 }}
              data={memories}
              keyExtractor={(item) => item.id.toString()}
              renderItem={({ item }) => (
                <FeedItem
                  memoryId={item.id}
                  userId={item.poster.id}
                  username={item.poster.username}
                  time={renderDateTime(item.datetime)}
                  likes={item.thumbs_up_count + item.thumbs_down_count}
                  description={item.content}
                  imageUri={API.apiUrl(item.item_image.url)}
                  avatar={item.poster.profile_image_url ? API.apiUrl(item.poster.profile_image_url) : null}
                  initialLikeState={item.vote?.vote_type}
                />
              )}
            />
          </View>
        <AccountSummary/>
      </View>
    </ScrollView>
    </View>
  )
}
