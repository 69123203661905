import React, { useContext, useState } from 'react';
import { View, SafeAreaView, Text, ScrollView } from 'react-native';
import { styles } from '../styles/global';
import { API } from '../lib/api';
import PasswordInput from '../components/passwordInput';
import IconButton from '../components/iconButton';
import { AuthContext } from '../context';
import { useToast } from 'react-native-toast-notifications';

export default function ChangePasswordScreen({ navigation }) {
  const authContext = useContext(AuthContext);
  const [passwordCurrent, setPasswordCurrent] = useState('');
  const [passwordNew, setPasswordNew] = useState('');
  const toast = useToast();

  async function updatePassword() {
    const data = {'password': passwordCurrent, 'new_password': passwordNew};
    try {
      const response = await API.updateUser(authContext.user.id, data);
      toast.show('Password updated.');
      authContext.login(response);
      navigation.navigate(authContext.user.role == 'JEWELER' ? 'JewelerSettings' : 'Settings');
    } catch (error) {
      if (error.response.status == 401)
      {
        toast.show('Password is incorrect');
      }
      else if (error.response.status == 422)
      {
        toast.show('Invalid password: ' + error.response.data.errors[0].new_password[0]);
      }
    }

    // Clear inputs
    setPasswordCurrent('');
    setPasswordNew('');
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView>
        <View style={styles.container}>
          <Text style={styles.titleText}>Change Password</Text>
          <PasswordInput placeholder='current password' value={passwordCurrent} onChangeText={setPasswordCurrent}/>
          <PasswordInput placeholder='new password' value={passwordNew} onChangeText={setPasswordNew}/>
          <IconButton title='Save' icon='save' onPress={updatePassword}/>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
