import {
  StyleSheet,
  Text,
  View,
  FlatList,
  Image,
  Dimensions,
  ScrollView,
} from "react-native";
import React, { useState } from "react";
import { colors } from "../../config/Colors";
import Header from "../../components/Common/Header";
import MemoryItem from "../../components/Capture/MemoryItem";
import Button from "../../components/Common/Button";

let { width } = Dimensions.get('window');
width = width > 500 ? 500 : width;


export default function RetireItemScreen() {
  const [memories, setMemories] = useState([
    {
      id: "1",
      avatarUri: require("../../assets/icons/Common/rolexGmtAvatar.png"),
      title: "GMT-Master II",
      subtitle: "Rolex",
    }
  ]);

  const renderItem = ({ item }) => (
    <MemoryItem
      avatarUri={item.avatarUri}
      title={item.title}
      subtitle={item.subtitle}
    />
  );

  return (
    <View style={styles.containerContainer}>
      <Header title={"Retire Item"} />
      <ScrollView showsVerticalScrollIndicator={false}>
          <View style={styles.container}>
        <FlatList
          data={memories}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />
      <Text style={styles.highlightHeader}>Are you sure you wish to retire this item?</Text>
      <View style={styles.buttonMainContainer}>
      <View style={styles.buttonContainer}>
      <Button style={styles.button} title={"Yes"} color={colors.primary}/>
      </View>
      </View>
      <Text style={styles.highlightText}>This will move the item to your archive.</Text>
      <Text style={styles.highlightText}>A QR code will be generated to restore the item to any account at a later time.</Text>
      </View>
                </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  containerContainer: {
    backgroundColor: "white",
    flex: 1
  },
  container: {
    flex: 1,
    backgroundColor: colors.white,
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight:'auto'
  },
  button: {
    backgroundColor: "#ddd",
    width: width,
    height: 75,
  },
  buttonContainer: {
    marginBottom: 20,
    marginTop: 20
  },
  highlightHeader: {
    textAlign: "center",
    marginTop: 40,
    marginBottom: 20,
    fontFamily: "SemiBold",
    paddingLeft: 30,
    fontSize: 18,
    paddingRight: 30,
  },
  highlightText: {
    textAlign: "center",
    marginTop: 20,
    marginBottom: 20,
    fontFamily: "Regular",
    paddingLeft: 30,
    paddingRight: 30,
  },
  seprator: {
    width: width,
    height: 1,
    backgroundColor: colors.border,
    alignSelf: "center",
  },
});
