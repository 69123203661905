import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { styles } from '../styles/global';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';

export default function User(props) {
  const content = (

  <View style={styles.listItemContainer}>
      <View style={styles.listItem}>
    <FontAwesome5 style={{width: 60, textAlign: 'center', lineHeight: 48, marginTop: 10, marginBottom: 10, marginLeft: 5}} size={50} color="black" name="user" solid />
    <View style={styles.listItemContent}>
      {
        !props.user ?
        <Text style={styles.timelineItemText}>Select a User...</Text> :
        <>
        <Text style={styles.listItemTitle}>{props.user.name} {props.user.username ? '(@' + props.user.username + ')' : ''}</Text>
        { props.displayEmail ? <Text style={styles.listDescription}>{props.user.email}</Text> : <></> }
        </>
      }
      </View>
    </View>
  </View>);

  if (props.onPress) {
    return<TouchableOpacity style={styles.listItemContainer} onPress={() => props.onPress(props.user)}>
      {content}
    </TouchableOpacity>
  }
  return content;
}
