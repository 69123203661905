import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { colors } from "../../config/Colors";

const ItemDetails = () => {
  return (
    <View style={styles.container}>
      <View style={styles.column}>
        <Text style={styles.label}>Manufacturer</Text>
        <Text style={styles.value}>Rolex</Text>
        <Text style={styles.label}>Year</Text>
        <Text style={styles.value}>2020</Text>
      </View>
      <View style={styles.column}>
        <Text style={styles.label}>Model</Text>
        <Text style={styles.value}>GMT-Master II</Text>
        <Text style={styles.label}>Metal</Text>
        <Text style={styles.value}>Steel</Text>
      </View>
      <View style={styles.column}>
        <Text style={styles.label}>Reference</Text>
        <Text style={styles.value}>126710BLRO</Text>
        <Text style={styles.label}>Size</Text>
        <Text style={styles.value}>40mm</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    marginTop: 20,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: colors.tabTopBorder
  },
  column: {
    flexDirection: "column",
  },
  label: {
    fontFamily: "SemiBold",
    color: colors.primary,
    marginBottom: 4,
    fontSize: 14,
  },
  value: {
    color: colors.primary,
    fontSize: 12.5,
    marginBottom: 16,
  },
});

export default ItemDetails;
