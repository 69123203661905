import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import { colors } from "../../config/Colors";
import { useNavigation } from "@react-navigation/native";
const { width: SCREEN_WIDTH } = Dimensions.get("window");

const ServiceHistoryItem = ({ avatarUri, title, subtitle }) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={styles.itemContainer}
    >
      <Image source={avatarUri} style={styles.avatar} />
      <View style={styles.textContainer}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subtitle}>{subtitle}</Text>
      </View>
      <Image
        source={require("../../assets/icons/Common/arrow-right.png")} // Replace with your chevron right icon
        style={styles.chevron}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: 20,
    width: SCREEN_WIDTH,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderBottomColor: colors.border,
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginRight: 10,
  },
  textContainer: {
    flex: 1,
  },
  title: {
    fontSize: 14,
    fontFamily: "Bold",
    color: colors.primary,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: "Regular",
    color: colors.primary,
    marginTop:5,
  },
  chevron: {
    width: 20,
    height: 20,
    tintColor: colors.primary,
    display: "none"
  },
});

export default ServiceHistoryItem;
