import React, { useContext, useEffect, useState } from 'react';
import { SafeAreaView, Text, TextInput, View } from 'react-native';
import { useToast } from "react-native-toast-notifications";
import { Picker } from '@react-native-picker/picker';
import { API } from '../lib/api';
import { styles } from '../styles/global';
import { TicketContext } from '../context';
import IconButton from '../components/iconButton';
import { Actions } from './serviceTicketProvider';

export default function ServiceTicketStatusScreen({ route, navigation }) {
  const ticketContext = useContext(TicketContext);
  const toast = useToast();

  const [description, setDescription] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('RECEIVED');

  async function addStatus() {
    try {
      let response = await API.addTicketEvent(ticketContext.ticket.id, {
        description: description,
        type: selectedStatus,
      });
      ticketContext.dispatch({type: Actions.AddEvent, event: response});
    } catch (error) {
      console.log(error)
      toast.show('Failed to add status');
    }
    navigation.goBack();
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        <Text style={styles.titleText}>Ticket Status</Text>
        <Text>Update the status of this ticket:</Text>
        <Picker
          selectedValue={selectedStatus}
          onValueChange={(itemValue) =>
            setSelectedStatus(itemValue)
          }>
          <Picker.Item label="Received by Jeweler" value="RECEIVED"/>
          <Picker.Item label="Update" value="UPDATE"/>
          <Picker.Item label="Ready for Pickup" value="PICKUP_READY"/>
          <Picker.Item label="Picked up" value="PICKUP"/>
        </Picker>
        <TextInput multiline={true} placeholder="Describe ticket status" value={description} onChangeText={setDescription}></TextInput>
        <IconButton title="Save" onPress={addStatus} icon='save'/>
      </View>
    </SafeAreaView>
  )
}
