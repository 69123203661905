import { colors } from "../../config/Colors";
import SegmentTabBar from "../../components/Profile/SegmentTabBar";
import ProfileInfoCard from "../../components/Profile/ProfileInfoCard";

import React, { useContext, useEffect, useState } from 'react';
import { ActivityIndicator, View, SafeAreaView, Text, Button, TouchableOpacity, Image, ScrollView, FlatList, StyleSheet } from 'react-native';
import { ids } from '../../styles/global';
import { API } from '../../lib/api';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import CollectionItem from '../../components/collectionItem'
import { AuthContext } from '../../context';
import Header from "../../components/Common/Header";
import { useLinkProps, useNavigation } from '@react-navigation/native';
import IconButton from '../../components/iconButton';
import FeedItem from "../../components/Home/FeedItem";

export default function ProfileScreen({ navigation }) {
  const authContext = useContext(AuthContext);

  const [selectedMenuItemKey, setSelectedMenuItemKey] = useState('2');
  const [menuVisible, setMenuVisible] = useState(null);
  const menuOptions = [
    { key: "1", label: "Edit profile" },
    { key: "2", label: "Account Settings" },
    { key: "3", label: "Sign Out" },
  ];
  const renderMenuOption = ({ item }) => (
    <TouchableOpacity
      style={[
        styles.menuOption,
        item.key === selectedMenuItemKey ? styles.menuOptionSelected : {},
      ]}
      onPress={() => onSelectMenuOption(item)}
    >
      <Text style={styles.menuOptionText}>{item.label}</Text>
    </TouchableOpacity>
  );

  const onSelectMenuOption = (option) => {
    setSelectedMenuItemKey(option.key);
    console.log("Selected option:", option.label);

    if (option.key == '2') {
      navigation.navigate('Settings', {screen: 'Settings'});
    }

    if (option.key == '3') {
      authContext.logout();
    }

    setMenuVisible(false);
  };

  return (
    <View style={styles.containerContainer}>
        <Header
        icon={require('../../assets/icons/Common/menu-dot-icon.png')}
        title="Profile"
        onPressIcon={() => setMenuVisible(!menuVisible)}
        />
        {menuVisible && (
          <View style={styles.menuContainerContainer}>
          <View style={styles.menuContainer}>
            <FlatList
              data={menuOptions}
              renderItem={renderMenuOption}
              keyExtractor={(item) => item.key}
            />
          </View>
          </View>
        )}
        <View style={styles.container}>
        <View style={{ flex: 1 }}>
      <ProfileInfoCard user={authContext.user} />
      <SegmentTabBar userId={authContext.user.id} />
      </View>
    </View>
  </View>
  );
}

const styles = StyleSheet.create({
  scrollArea: {
    flex: 1,
    backgroundColor: "white",
  },
  containerContainer: {
    backgroundColor: "white",
    flex: 1
  },
  container: {
    flex: 1,
    backgroundColor: colors.white,
    maxWidth: 500,
    marginTop: 0,
    padding: 0,
    marginTop: 0,
    paddingTop: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 0
  },
  menuContainer: {
    position: "absolute",
    top: 0,
    backgroundColor: "white",
    borderWidth: 2,
    borderColor: colors.border,
    borderRadius: 5,
    maxWidth: 150,
    width: '100%',
    zIndex: 6,
    right: 0,
  },
  menuContainerContainer: {
    position: "absolute",
    top: 155,
    left: 0,
    right: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: 500,
    zIndex: 5
  },
  menuOption: {
    padding: 10,
    zIndex: 999999,
  },
  menuOptionText: {
    color: colors.primary,
    fontSize: 14,
    fontFamily: "Regular",
  },
  menuOptionSelected: {
    backgroundColor: "white"
  },
  contentContainer: { zIndex: -99999,
  backgroundColor: "white"
}
});
