import React from 'react';
import { View, Text } from 'react-native';

export default function Message(props) {
  return (
    <View style={{flexDirection: 'row', backgroundColor: '#cfcfcf', borderRadius: 10}}>
      <Text style={{padding: 10}}>{props.text}</Text>
    </View>
  );
}
