import {
  StyleSheet,
  Text,
  View,
  FlatList,
  Image,
  Dimensions,
} from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { colors } from "../../config/Colors";
import Header from "../../components/Common/Header";
import MemoryItem from "../../components/Capture/MemoryItem";
import { API } from "../../lib/api";
import { AuthContext } from "../../context";
import { useNavigation } from "@react-navigation/native";
import FeedItem from "../../components/Home/FeedItem";
import { renderDateTime } from '../../lib/utils';

let { width } = Dimensions.get('window');
width = width > 500 ? 500 : width;

export default function MemoryDetailScreen({ route }) {
  const { userId } = route.params;
  const [followers, setFollowers] = useState([]);
  const authContext = useContext(AuthContext);
  const navigation = useNavigation();

  const item = route.params.data;

  useEffect(() => {
    API.followers(userId).then(setFollowers);
  }, [authContext.user, userId]);

  function goToProfile(userId) {
    if (userId == authContext.user.id) {
      navigation.navigate('Profile', {screen: 'ProfileScreen'})
    } else {
      navigation.navigate('Home', {screen: 'UserProfile', params: {id: userId}})
    }
  }

  return (
    <View style={styles.containerContainer}>
      <Header title={"Memory"} />
          <View style={styles.container}>
      <View style={styles.seprator} />
      <View>
        <FeedItem
          memoryId={item.id}
          userId={item.poster.id}
          username={item.poster.username}
          time={renderDateTime(item.datetime)}
          likes={item.thumbs_up_count + item.thumbs_down_count}
          description={item.content}
          imageUri={API.apiUrl(item.item_image.url)}
          avatar={item.poster.profile_image_url ? API.apiUrl(item.poster.profile_image_url) : null}
          initialLikeState={item.vote?.vote_type}
        />
      </View>
    </View>
      </View>
  );
}

const styles = StyleSheet.create({
  containerContainer: {
    backgroundColor: "white",
    flex: 1
  },
  container: {
    flex: 1,
    backgroundColor: colors.white,
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight:'auto'
  },
  seprator: {
    width: width,
    height: 1,
    backgroundColor: colors.border,
    alignSelf: "center",
  },
});
