import { Dimensions, StyleSheet } from 'react-native';

let headerMargin = 90;
let headerHeight = 70;
let homeMargin = 80;
const window = Dimensions.get('window'); // Retrieve window dimensions
if (window.width <= 768) {
  headerMargin = 40;
  headerHeight = 70;
  homeMargin = 0;
}

let { width } = Dimensions.get('window');
width = width > 500 ? 500 : width;

export const widthThreshold = 1024;

export const colors = {
  outline: '#d2d2d2',
  text: '#0D1520',
  background: 'white',
  block: 'white',
  primary: '#0D1520',
  accent: '#F8C273'
}

export const styles = StyleSheet.create({
  loadScreen: {
   backgroundColor: 'black',
   justifyContent: 'center',
   flex: 1,
  },
  loginScreenSafeArea: {
    height: Dimensions.get('window').height,
    justifyContent: 'center',
    backgroundColor: 'white'
  },
  safeArea: {
    flex: 1,
    backgroundColor: colors.background,
  },
  scrollArea: {
    backgroundColor: colors.background,
    marginTop: 0
  },
  homeScrollArea: {
    flex: 1,
    backgroundColor: colors.background,
    marginTop: homeMargin
  },
  loadScreenSafeArea: {
    flex: 1
  },
  loadScreenContainer: {
    padding: 20,
    maxWidth: 500,
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 0,
    marginBottom: 0
  },
  loginContainer: {
    padding: 20,
    maxWidth: 400,
    width: '100%',
    height: Dimensions.get('window').height,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 0,
    marginBottom: 175,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  container: {
    padding: 0,
    marginTop: 0,
    backgroundColor: colors.background,
    maxWidth: 500,
    width: Dimensions.get('window').width,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 0,
  },
  fullContainer: {
    padding: 20,
    maxWidth: 1200,
    width: Dimensions.get('window').width,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 0,
    marginBottom: 0
  },
  titleText: {
    fontSize: 38,
    marginBottom: 20,
    fontFamily: 'LatoBold',
    letterSpacing: 0
  },
  subTitleText: {
    textAlign: 'center',
    fontSize: 14,
    fontFamily: "SemiBold",
    color: "#545f71",
  },
  subTitleTextOrphan: {
    fontSize: 22,
    fontFamily: 'LatoBlack',
    marginBottom: 10
  },
  subTitleTextOrphanGap: {
    fontSize: 22,
    fontFamily: 'LatoBlack',
    marginBottom: 10,
    marginTop: 30
  },
  smallTitleText: {
    fontSize: 28,
    marginBottom: 20,
    fontFamily: 'LatoBold',
    letterSpacing: 0
  },
  smallSubTitleText: {
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 30,
    fontFamily: 'ContrailOne',
    textTransform: 'uppercase'
  },
  accentText: {
    fontFamily:'SemiBold',
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 7,
    marginTop: 30,
    marginLeft: 15,
  },
  switchOption: {
    lineHeight: 30,
    height: 30
  },
  switchText: {
    lineHeight: 30,
    height: 30
  },
  homeCount: {
    fontSize: 46,
    fontWeight: 'bold',
    color: colors.primary,
    marginTop: -10,
    fontFamily: 'InconsolataBold',
    letterSpacing: 1
  },
  homeCountText: {
    fontSize: 15,
    color: colors.text,
    fontFamily: 'Lato'
  },
  accountSummaryIconContainer: {
    height: 64,
    width: 64,
    borderRadius: 32,
    backgroundColor: '#f7f7f7',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginScreenLogo: {
    height: 70,
    width: 250,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 50
  },
  boxAnimation: {
    height: 100,
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 14
  },
  verifiedIcon: {
    width: 20,
    height: 20,
  },
  alertIcon: {
    width: 25,
    height: 25,
    marginRight: 12
  },
  repairText: {
    marginBottom: 15
  },
  loadScreenLogo: {
    height: 80,
    width: 275,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 60
  },
  topNavLogo: {
    height: 45,
    width: 150,
    marginLeft: 30,
    marginRight: 30,
    marginTop: 16
  },
  topNavLink: {
   color: 'black',
   fontSize: 13,
   fontWeight: "200",
   paddingLeft: 15,
   paddingRight: 15,
   fontFamily: 'LatoBlack',
   textTransform: 'Uppercase',
   letterSpacing: .7,
   height: 80,
   lineHeight: 80,
   ':hover': {
     backgroundColor: '#f9f9f9',
     borderBottomWidth: 1,
     borderBottomColor: '#ddd'
   }
  },
  topNavLinkActive: {
   color: 'black',
   fontSize: 13,
   borderBottomWidth: 2,
   borderBottomColor: "black",
   fontFamily: 'LatoBlack',
   textTransform: 'Uppercase',
   letterSpacing: .7,
   fontWeight: 500,
   paddingLeft: 15,
   paddingRight: 15,
   height: 80,
   lineHeight: 80,
   ':hover': {
     backgroundColor: '#f9f9f9',
     borderBottomWidth: 2,
     borderBottomColor: 'black'
   }
  },
  topNavAccount: {
   color: 'white',
   fontWeight: 'bold',
   height: 80,
   width: 80,
   textAlign: 'center',
   lineHeight: 80,
   position: 'absolute',
   justifyContent: 'center',
   alignItems: 'center',
   right: 0,
   borderBottomWidth: 1,
   borderBottomColor: '#ddd',
   ':hover': {
     backgroundColor: '#f9f9f9',
     borderBottomWidth: 1,
     borderBottomColor: '#ddd'
   }
  },
  topNav: {
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
    backgroundColor: "white",
    height: 80,
    width: "100%",
    left: 0,
    right: 0,
    top: 0,
    position: 'fixed',
    zIndex: 2,
    flexDirection:'row',
    flexWrap:'wrap'
  },
  bottomNav: {
    paddingTop: 50,
  },
  thumbnailImg: {
    height: 74,
    width: 74,
    backgroundColor: 'white',
    opacity: 1
  },
  detailImg: {
    aspectRatio: 1,
    width: '100%',
    backgroundColor: 'white',
  },
  itemActionGrid: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    marginTop: 0,
  },
  insightsGrid: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    marginTop: 0,
    marginBottom: 125
  },
  itemActionLeft: {
    width: '50%',
    paddingRight: 5,
    marginTop: 10,
  },
  itemActionRight: {
    width: '50%',
    paddingLeft: 5,
    marginTop: 10,
  },
  itemActionContentLeft: {
    borderWidth: 1,
    borderColor: colors.outline,
    borderRadius: 0,
    width: '100%',
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemActionContentRight: {
    borderRadius: 0,
    width: '100%',
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: colors.outline,
    borderRadius: 0
  },
  itemAction: {
    width: '25%',
    paddingRight: 10,
    marginTop: 10,
  },
  insight: {
    width: '33.33%',
    paddingRight: 15,
    marginTop: 10,
  },
  itemActionContent: {
    borderWidth: 1,
    borderColor: colors.outline,
    borderRadius: 0,
    width: '100%',
    height: 150,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white'
  },
  insightContent: {
    borderWidth: 1,
    borderColor: colors.outline,
    width: '100%',
    height: 310,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white'
  },
  insightTitle: {
    fontFamily: 'LatoBlack',
    fontSize: 18,
    marginBottom: 15,
    marginTop: 0,
    width: '100%',
    marginLeft: 75
  },
  insightCount: {
    fontFamily: 'LatoBlack',
    fontSize: 18,
    marginBottom: 15,
    marginTop: 5,
    width: '100%',
    marginLeft: 65,
    position: 'absolute',
    textAlign: 'right',
    top: 30,
    right: 35,
  },
  itemDetailContent: {
    width: '100%',
    padding: 25,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderWidth: 1,
    borderColor: colors.outline,
    marginTop: -1
  },
  itemListTitle: {
    padding: 25,
    borderColor: colors.outline,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0
  },
  itemList: {
    borderColor: colors.outline,
    backgroundColor: 'white',
    marginTop: -1,
    borderLeftWidth: 0,
    borderRightWidth: 0
  },
  itemListwithGap: {
    borderColor: colors.outline,
    backgroundColor: 'white',
    marginBottom: 0,
    marginTop: -1
  },
  itemListwithGapNoBorder: {
    border: 0,
    backgroundColor: 'white',
    marginBottom: 0,
    marginTop: -1
  },
  itemListwithGaps: {
    borderColor: colors.outline,
    backgroundColor: 'white',
    marginBottom: 0,
    marginTop: -1
  },
  itemListwithGapsTopBorder: {
    borderColor: colors.outline,
    backgroundColor: 'white',
    marginBottom: 0,
    marginTop: -1,
    borderTopWidth: 1,
    borderTopColor: '#ddd'
  },
  listItemContainer: {
    marginBottom: 0,
    width: "100%",
    maxWidth: 500
  },
  listItemContainerNoGap: {
    marginBottom: 0,
    width: "100%",
    maxWidth: 500,
  },
  listItem: {
    padding: 15,
    position: 'relative',
    flexDirection:'row',
    flexWrap: 'wrap',
    width: '100%',
    borderLeftWidth: 0,
    borderRightWidth: 0,
    maxWidth: 500,
    borderColor: colors.outline,
    borderWidth: 1,
    marginTop: -1,
    backgroundColor: 'white'
  },
  listItemNoBottom: {
    padding: 15,
    position: 'relative',
    flexDirection:'row',
    flexWrap: 'wrap',
    width: '100%',
    borderLeftWidth: 0,
    borderRightWidth: 0,
    maxWidth: 500,
    borderColor: colors.outline,
    borderWidth: 1,
    marginTop: -1,
    backgroundColor: 'white'
  },
  listItemCondensed: {
    padding: 10,
    position: 'relative',
    flexDirection:'row',
    flexWrap: 'wrap',
    width: '100%',
    borderColor: colors.outline,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 1,
    marginTop: 0,
    maxWidth: 500,
    backgroundColor: 'white'
  },
  promptItem: {
    padding: 30,
    fontSize: 15,
    fontFamily: 'Lato',
    color: colors.primary,
    borderColor: colors.outline,
    borderWidth: 1,
    marginTop: -1,
    borderLeftWidth: 0,
    borderRightWidth: 0
  },
  promptItemText: {
    fontSize: 14,
    fontFamily: "Regular",
    color: colors.primary
  },
  homeItem: {
    backgroundColor: 'white',
    position: 'relative',
    flexDirection:'row',
    flexWrap:'wrap',
    width: '100%',
    borderColor: 'black',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderWidth: 1,
    borderColor: colors.outline,
    padding: 15
  },
  triangleTopLeftCorner: {
    zIndex: 1,
    elevation: 1,
    width: 6,
    height: 6,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderRightWidth: 6,
    borderTopWidth: 6,
    borderRightColor: "transparent",
    borderTopColor: "white",
    position: 'absolute',
    top: 0,
    left: 0
  },
  triangleBottomRightCorner: {
    zIndex: 1,
    elevation: 1,
    width: 6,
    height: 6,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderRightWidth: 6,
    borderTopWidth: 6,
    borderRightColor: "transparent",
    borderTopColor: "white",
    position: 'absolute',
    bottom: 0,
    right: 0,
    transform: [{ rotate: "180deg" }]
  },
  itemActionTitle: {
    fontFamily: 'InconsolataBold',
    textTransform: 'uppercase',
    fontWeight: '600',
    fontSize: 17,
    marginBottom: 1,
    width: '100%',
    textAlign: 'center',
    letterSpacing: 1
  },
  itemActionTitleLarge: {
    fontFamily: 'LatoBlack',
    fontSize: 17,
    marginBottom: 1,
    width: '100%',
    textAlign: 'center',
    marginTop: 10
  },
  detailItem: {
    marginTop: 10,
    marginBottom: 20,
    padding: 0,
    position: 'relative',
    borderWidth: 1,
    borderColor: colors.outline,
    borderRadius: 0,
    width: '100%',
    borderColor: 'black',
    borderBottomWidth: 7,
    borderLeftWidth: 7,
    borderTopWidth: 2,
    borderRightWidth: 2
  },
  detailTransferItem: {
    marginTop: 10,
    marginBottom: 0,
    padding: 15,
    paddingBottom: 25,
    position: 'relative',
    flexDirection:'row',
    flexWrap: 'wrap',
    borderWidth: 1,
    borderColor: colors.outline,
    borderRadius: 0,
    borderColor: 'black',
    borderBottomWidth: 7,
    borderLeftWidth: 7,
    borderTopWidth: 2,
    borderRightWidth: 2,
  },
  listItemContent: {
    marginLeft: 15,
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: 22,
    paddingTop: 14
  },
  listItemContentCondensed: {
    marginLeft: 15,
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: 22,
    paddingTop: 5
  },
  listItemTitleContainer: {
   flexDirection:'row',
   flexWrap:'wrap'
 },
  jobItemContent: {
    width: '100%',
    marginLeft: 15,
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 22,
    paddingTop: 15
  },
  listItemTitle: {
    marginBottom: 3,
    marginTop: 6,
    flexDirection:'row',
    flexWrap:'wrap',
    marginRight: 10,
    fontSize: 14,
    fontFamily:'Bold',
    color: colors.primary
  },
  listDescription: {
    color: colors.text,
    marginBottom: 0,
    fontSize: 14,
    fontFamily:'Regular',
    color: colors.primary
  },
  listDescriptionSub: {
    color: colors.text,
    marginBottom: 0,
    fontSize: 14,
    fontFamily:'Regular',
    color: colors.primary,
    textTransform: 'lowercase'
  },
  listReference: {
    color: colors.text,
    marginBottom: 1,
    fontSize: 14,
    fontFamily:'Regular',
    color: colors.primary
  },
  jobTitle: {
    fontSize: 14,
    fontFamily: "Bold",
    color: colors.primary,
  },
  jobContent: {
    fontSize: 14,
    fontFamily: "Regular",
    color: colors.primary,
    marginTop:5,
  },
  listItemAction: {
    right: 25,
    justifyContent: 'center',
    flex: 1,
    position: 'absolute',
    top: 40,
  },
  smallListItemAction: {
    position: 'absolute',
    right: 10,
    flex: 1,
    justifyContent: 'center',
  },
  imgAction: {
    position: 'absolute',
    right: 10,
    top: -80,
    justifyContent: 'center',
    alignItems: 'center',
  },
  pushBanner: {
    height: 350,
    borderWidth: 0,
    borderColor: colors.outline,
    padding: '5%',
    borderWidth: 1,
    marginBottom: 15,
    position: 'relative',
    backgroundColor: colors.block,
    textAlign: 'center'
  },
  pushBackgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 350,
    opacity: 0,
  },
  pushOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 350,
    opacity: 0,
    zIndex: 2,
    background: '#ededee'
  },
  pushBannerTitle: {
    color: colors.primary,
    fontSize: 24,
    textAlign: 'center',
    marginTop: 120,
    zIndex: 3,
    fontFamily: 'LatoBold',
    fontSize: 30,
  },
  pushBannerText: {
    color: colors.text,
    fontSize: 15,
    textAlign: 'center',
    marginTop: 15,
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: 3,
    lineHeight: 18,
    fontFamily: 'Lato'
  },
  block: {
    padding: '5%',
    borderColor: colors.outline,
    borderWidth: 1,
    marginTop: -1
  },
  blockNoBorder: {
    padding: '5%',
    borderColor: colors.outline,
    borderWidth: 0,
    marginTop: -1
  },
  image: {
    borderWidth: 1,
    borderColor: colors.outline,
    marginRight: 15,
    marginBottom: 15
  },
  inputContainer: {
    marginBottom: 25,
    position: 'relative',
    flexWrap:'wrap',
    width: '100%',
    alignSelf: 'stretch'
  },
  passwordInput : {
    width: '100%',
    alignSelf: 'stretch'
  },
  chartContainer: {
    borderWidth: 1,
    borderColor: colors.outline,
    padding: '5%',
    marginTop: -1,
  },
  chart: {
    backgroundColor: 'white',
    fontFamily: 'Lato',
    paddingTop: 15,
    marginRight: 30,
  },
  boxContainer: {
    position: 'relative',
    marginBottom: 0
  },
  inputLabel: {
    fontFamily: 'SemiBold',
    fontSize: 13,
    paddingBottom: 7,
  },
  input: {
    backgroundColor: colors.background,
    borderColor: colors.outline,
    fontSize: 14,
    fontFamily:'Regular',
    color: colors.primary,
    width: '100%',
    borderWidth: 1,
    borderRadius: 0,
    padding: 15,
    alignSelf: 'stretch',
    height: 55,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 1,
    outlineStyle: 'none',
    borderRadius: 5
  },
  button: {
    backgroundColor: "#545F71",
    height: 52,
    width: width - 30,
    justifyContent:"center",
    alignItems:"center",
    borderColor: colors.outline,
    borderWidth: 0,
    borderRadius: 6,
    justifyContent: "center",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    marginTop: 15,
    flexDirection: 'row',
    ':hover': {
      backgroundColor: colors.accent,
    },
  },
  buttonLogin: {
    backgroundColor: "black",
    height: 52,
    width: "100%",
    justifyContent:"center",
    alignItems:"center",
    borderColor: colors.outline,
    borderWidth: 0,
    borderRadius: 6,
    justifyContent: "center",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    marginTop: 15,
    flexDirection: 'row',
    ':hover': {
      backgroundColor: colors.accent,
    },
  },
  disabledButton: {
    backgroundColor: '#78807f'
  },
  buttonText: {
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    fontFamily:'SemiBold',
  },
  transferHistory: {
    borderColor: colors.outline
  },
  transferHistoryText: {
    fontSize: 15,
    marginBottom: 20
  },
  profileIcon: {
    borderRadius: 39,
    width: 78,
    height: 78,
    backgroundColor: '#c7c7c7'
  },
  memImgContainer: {
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flexDirection: 'row'
  },
  memImg: {
    height: 78,
    width: 78,
    marginRight: 10,
  },
  itemImgContainer: {
    position: 'relative'
  },
  itemImgOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    opacity: .3,
    zIndex: 2,
    background: '#ededee',
    height: 92
  },
  itemImgBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    opacity: .2,
    zIndex: 2,
    height: 92
  },
  detailImgContainer: {
    position: 'relative',
    borderWidth: 1,
    borderColor: colors.outline,
  },
  detailImgOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: .3,
    zIndex: 2,
    background: '#ededee',
    height: undefined,
    aspectRatio: 1,
  },
  detailImgBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: .1,
    zIndex: 2,
    height: undefined,
    aspectRatio: 1,
  },
  textInput: {
    borderColor: 'black',
    borderWidth: 1,
    height: 50,
    lineHeight: 50,
    paddingLeft: 10,
    fontSize: 15,
    marginBottom: 20
  },
  textInputLong: {
    borderColor: '#ddd',
    borderWidth: 1,
    height: 100,
    padding: 10,
    fontSize: 15,
    marginBottom: 20,
    marginTop: 15,
    marginLeft: 15,
    width: width - 60
  },
  helpText: {
      borderColor: 'black',
      fontSize: 15,
      marginBottom: 20,
      lineHeight: 18
  },
  alertBox: {
    marginBottom: 15,
    borderWidth: 1,
    borderColor: colors.outline,
    padding: '5%',
    flexDirection: 'column',
    backgroundColor: colors.block
  },
  alertBoxTitle: {
    fontFamily: 'LatoBlack',
    fontSize: 22,
    marginTop: -1,
    marginBottom: 8,
    color: colors.primary,
    marginBottom: 20,

  },
  alertBoxText: {
    fontSize: 15,
    color: colors.text,
    marginBottom: 10,
    marginTop: 10,
    lineHeight: 18,
    zIndex: 3,
    fontFamily: 'Lato',
  },
  timelineItem: {
    borderWidth: 1,
    borderColor: colors.outline,
    padding: 25,
    marginTop: -1
  },
  timelineItemTitle: {
    fontSize: 15,
    borderColor: colors.text,
    marginBottom: 5
  },
  timelineItemText: {
    fontSize: 14,
    marginBottom: 15,
    fontFamily: 'Regular',
    color: colors.text
  },
  imgText: {
    fontSize: 15,
    color: colors.text
  },
  timelineItemDescription: {
    marginTop: 1,
    fontSize: 15,
    borderColor: colors.text,
  },
  documentItem: {
    borderWidth: 1,
    borderColor: colors.outline,
    padding: 15,
    marginBottom: 15,
  },
  picker: {
    borderWidth: 1,
    borderRadius: 5,
    fontSize: 15,
    borderColor: 'black',
    padding: 15
  },
  statusContainer: {
    borderWidth: 1,
    borderColor: colors.outline,
    backgroundColor: 'white',
    height: 250,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    paddingTop: 20
  },
  separator: {
    width: '100%',
    maxWidth: 500,
    height: 1,
    backgroundColor: colors.border,
    alignSelf: "center",
  },
  containerContainer: {
    backgroundColor: "white",
    flex: 1
  },
  sidePadding: {
    padding :15
  },
  menuContainer: {
    position: "absolute",
    top: 160,
    right: 15,
    backgroundColor: "white",
    borderWidth: 2,
    borderColor: colors.border,
    borderRadius: 5,
    minWidth: 144,
    zIndex: 10
  },
  menuOption: {
    padding: 10,
    zIndex: 999999,
  },
  menuOptionText: {
    color: colors.primary,
    fontSize: 14,
    fontFamily: "Regular",
  },
  menuOptionSelected: {
    backgroundColor: colors.tabTopBorder,
  },
  homeHeader: {
    width: '100%',
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: headerHeight,
    marginTop: headerMargin,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    position: 'fixed'
  },
  logoTop: {
    width:140,
    height:39,
    resizeMode:"contain",
    marginTop:5,
    marginBottom: 10
  },
  removeBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  videoBackground: {
    width: '100%',
    maxWidth: 500,
    height: 500,
    maxHeight: 500,
  },
  serviceLogo: {
    width: 215,
    height: 50,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    top: 110,
    position: "absolute"
  },
  imageBackgroundContainer: {
    backgroundColor: "black",
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
    maxWidth: 500,
    height: 400,
    maxHeight: 400,

  },
  imageBackground: {
    width: '100%',
    maxWidth: 500,
    height: 400,
    maxHeight: 400,
    justifyContent: "center",
    alignItems: "center",
    opacity: 1
  },
  imageBackgroundTitle: {
    fontSize: 18,
    color: "white",
    letterSpacing: 0,
    fontFamily: "SemiBold",
    opacity: 1,
    position: "absolute",
    top: 60
  },
  imageBackgroundText: {
    fontSize: 14,
    color: "white",
    fontFamily: "Regular",
    top: 185,
    textAlign: "center",
    position: "absolute",
    paddingLeft: 42,
    paddingRight: 42
  },
  imageBackgroundTextTwo: {
    fontSize: 14,
    color: "white",
    fontFamily: "Regular",
    top: 250,
    textAlign: "center",
    position: "absolute",
    paddingLeft: 40,
    paddingRight: 40
  },
  btnText: {
     fontSize: 14,
     fontFamily:'SemiBold',
     color: "white"
  },
  serviceBtn: {
    position: "absolute",
    top: 290,
    textAlign: "center",
    width: 250,
    height: 50,
    backgroundColor: "black",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center"
  },
  footerButtonContainer: {
    left: 0,
    right: 0,
    zIndex: 9999,
    marginBottom: 20,
    marginTop: 20
  },
  footerButtonContainerTopBorder: {
    left: 0,
    right: 0,
    zIndex: 9999,
    marginBottom: 20,
    marginTop: 10,
    paddingTop: 10,
    paddingBottom: 8,
    borderTopWidth: 1,
    borderTopColor: colors.outline,
  },
  iphoneGalleryContainer: {
    width: "100%",
    height: 72,
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "left",
    borderTopWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: "center",
  },
  iphoneGalleryText: {
    fontSize: 14,
    fontFamily: "SemiBold",
    color: "#545f71"
  },
  iphoneGalleryTextCentered: {
    fontSize: 14,
    fontFamily: "SemiBold",
    color: "#545f71",
    textAlign: "center",
    width: "100%"
  },
  additionalStyles: {
    borderWidth: 1,
    borderColor: colors.tabTopBorder,
    borderTopWidth: 0,
    height: 65,
  },
  additionalStylesitem: {
    borderColor: colors.tabTopBorder,
    height: 65,
  },
});
