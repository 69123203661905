import { Text } from 'react-native';
import { A } from '@expo/html-elements';
import { API } from '../lib/api';
import { styles } from '../styles/global';

export default function DocumentLink(props) {

  if (props.document.upload) {
    return <Text style={styles.timelineItemText}>{props.document.upload.name}</Text>
  } else {
    return <A style={styles.timelineItemText} href={API.apiUrl(props.document.url)}>{props.document.filename}</A>
  }
}
