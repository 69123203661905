// CustomFlatList.js
import React from "react";
import {
  FlatList,
  Image,
  Text,
  View,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import { colors } from "../../config/Colors";
import { API } from "../../lib/api";
import { renderDateTime } from "../../lib/utils";
import { useNavigation } from "@react-navigation/native";

let { width } = Dimensions.get('window');
width = width > 500 ? 500 : width;

const TimeLineList = ({ data }) => {
  const navigation=useNavigation();
  const renderItem = ({ item }) => (
    <TouchableOpacity activeOpacity={0.7} style={styles.linstItemMainContainer} onPress={()=>navigation.navigate('MemoryDetailScreen', {id: item.id, data: item})}>
      <View style={styles.listItem}>
        <Image source={{uri: API.apiUrl(item.item_image.url)}} style={styles.image} />
        <View style={styles.textContainer}>
          <Text style={styles.title} numberOfLines={1} ellipsizeMode='tail'>{item.item.brand} {item.item.description}</Text>
          <Text style={styles.subtitle}>{renderDateTime(item.datetime)}</Text>
          <Text style={styles.subtitleContent} numberOfLines={1} ellipsizeMode='tail'>{item.content}</Text>
        </View>
      </View>

      <Image
        style={styles.arrowRight}
        source={require("../../assets/icons/Common/arrow-right.png")}
      />
    </TouchableOpacity>
  );


  return (
    <View>
      <FlatList
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainer}
        data={data}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  linstItemMainContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: colors.border,
    paddingVertical: 16,
    paddingLeft: 12,
    paddingRight: 22,
    maxWidth: "100%",
    width: width,
  },
  listItem: {
    flexDirection: "row",
    alignItems: "center",
    maxWidth: "100%",
    width: width
  },
  image: {
    width: 54,
    height: 54,
    borderRadius: 27,
    marginRight: 16,
  },
  textContainer: {
    maxWidth: "100%",
    width: width-130
  },
  title: {
    fontSize: 14,
    fontFamily: "Bold",
    color: colors.primary,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: "Regular",
    color: colors.primary,
    marginTop: 2,
  },
  subtitleContent: {
    fontSize: 14,
    fontFamily: "Regular",
    color: colors.primary,
    marginTop: 7,
    maxWidth: "100%",
    width: width-100
  },
  arrowRight: {
    width: 22,
    height: 22,
    right: 10
  },
  contentContainer: {
    marginBottom: 0,
    paddingBottom: 150,
  },
});

export default TimeLineList;
