import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useLinkProps } from '@react-navigation/native';
import { styles } from '../styles/global';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';

export function TicketStatus(props) {
  let lastEventStatus = props.ticket.events[props.ticket.events.length - 1]?.type || 'CREATE';
  let statusText = {
    CREATE: props.ticket.type == 'ORDER' ? 'Order placed' : 'Ticket created',
    RECEIVED: props.ticket.type == 'ORDER' ? 'Awaiting items' : 'Service in progress',
    PICKUP_READY: 'Ready for Pickup',
    PICKUP: 'Pickup completed',
  }[lastEventStatus];

  return <Text style={styles.jobContent}>{statusText}</Text>
}

export default function Ticket(props) {
  const linkProps = useLinkProps({to: props.linkTo ? props.linkTo(props.ticket.id) : ''});

  let touchableProps = {};
  if (props.linkTo) {
    touchableProps = linkProps;
  } else if (props.onPress) {
    touchableProps = {onPress: () => props.onPress(props.ticket.id)}
  }

  let content = <></>;
  if (props.showUser) {
    content = <>
      <Text style={styles.jobContent}>{props.ticket.user.name} ({props.ticket.user.email})</Text>
    </>;
  }

  let titleText = {
    ORDER: 'Order',
    SERVICE: 'Ticket',
  }[props.ticket.type];

  return (
    <TouchableOpacity style={styles.listItemContainer} {...touchableProps}>
      <View style={styles.listItem}>
        <View style={styles.accountSummaryIconContainer}><FontAwesome5 style={styles.accountSummaryIcon} name={'tools'} color={'#545F71'} size={19}/></View>
        <View style={styles.listItemContent}>
          <Text style={styles.jobTitle} selectable={true}>{titleText} #{props.ticket.id}</Text>
          <TicketStatus ticket={props.ticket}/>
          {content}
        </View>
        <FontAwesome5 style={styles.listItemAction} name='chevron-right' color='black' size={15}/>
      </View>
    </TouchableOpacity>
  );
}
