import React, { useState } from 'react';
import { View, SafeAreaView, Text, ScrollView, TextInput, Image, TouchableOpacity } from 'react-native';
import { styles } from '../styles/global';
import { API } from '../lib/api';
import IconButton from '../components/iconButton';
import { useToast } from 'react-native-toast-notifications';

export default function ForgotPasswordScreen({ navigation }) {
  const toast = useToast();
  const [email, setEmail] = useState('');

  async function submit() {
    API.forgotPassword(email).then(() => toast.show('Password reset link sent.'));
  }

  return (
    <SafeAreaView style={styles.loginScreenSafeArea}>
      <View>
          <View style={styles.loginContainer}>
            <Image style={styles.boxAnimation} source={require('../assets/box.gif')} />
            <Image style={styles.loginScreenLogo} source={require('../assets/jewelry-box-black.png')} />
            <Text style={{marginBottom: 20}}>Enter your account's email address and we will send you a link to reset your password.</Text>
            <View style={styles.inputContainer}>
              <TextInput style={styles.input} placeholder='email address' onChangeText={setEmail}/>
            </View>
            <TouchableOpacity style={styles.buttonLogin} disabled={!email.includes('@')} onPress={submit}>
              <Text style={styles.buttonText}>Send</Text>
            </TouchableOpacity>
          </View>
      </View>
    </SafeAreaView>
  );
}
