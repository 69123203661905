import { Image, StyleSheet, Text, TouchableOpacity, View, Dimensions, useWindowDimensions} from "react-native";
import React from "react";
import { colors } from "../../config/Colors";

import { useNavigation } from "@react-navigation/native";


let headerMargin = 90;
const window = Dimensions.get('window'); // Retrieve window dimensions
if (window.width <= 768) {
  headerMargin = 40;
}


export default function Header({ title, icon, onPressIcon }) {
  const navigation = useNavigation();
  return (
    <View style={styles.headerContainer}>
      <TouchableOpacity onPress={() => navigation.goBack()}>
        <Image
          style={styles.icon}
          source={require("../../assets/icons/Common/arrow-back.png")}
        />
      </TouchableOpacity>
      <Text style={styles.headerTitle}>{title}</Text>
      <TouchableOpacity onPress={onPressIcon}>
        {title !== "User Profile" && ( // Conditionally render regular icon
          <Image
            style={styles.icon}
            source={icon}
          />
        )}
        {title === "User Profile" && ( // Conditionally render followIcon
          <Image
            style={styles.followIcon}
            source={require("../../assets/icons/Common/follow-icon.png")}
          />
        )}
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    height: 72,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    marginTop: headerMargin,
    width: "100%",
    maxWidth: 500,
    marginLeft: "auto",
    marginRight: "auto"
  },
  headerTitle: {
    textAlign: "center",
    fontSize: 14,
    fontFamily: "SemiBold",
    color: colors.primary,
    marginBottom: 10,
  },
  icon: {
    width: 40,
    height: 40,
    marginBottom: 10,
    zindex: 5
  },
  followIcon: {
    width: 80,
    height: 40,
    marginBottom: 10,
  },
});
