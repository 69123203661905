import React, { useContext, useEffect } from 'react';
import { View, SafeAreaView, Text, ActivityIndicator, ScrollView, Image } from 'react-native';
import { styles } from '../styles/global';
import { TicketContext } from '../context';
import JewelerLocation from '../components/jewelerLocation';
import CollectionItem from '../components/collectionItem';
import { TicketStatus } from '../components/ticket';

function ItemList(props) {
  return (props.items || []).map((item) => {
    return <CollectionItem key={item.id} item={item}/>
  });
}

export default function ServiceTicketUserOverviewScreen({ route, navigation }) {
  const ticketContext = useContext(TicketContext);

  useEffect(() => {
    ticketContext.setTicketId(route.params?.id);
  }, [route.params?.id]);

  const titleText = ticketContext?.ticket?.type == 'SERVICE' ? 'Ticket Overview' : 'Order Overview';
  const locationTitleText = ticketContext?.ticket?.type == 'SERVICE' ? 'Service Location' : 'Pickup Location';

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView showsHorizontalScrollIndicator={false}>
      <View style={styles.container}>
          { ticketContext.ticket?.ticket_items == null ?
          <ActivityIndicator size='large'/>
          :
          <>
            <Text style={styles.titleText}>{titleText}</Text>
              <View style={styles.statusContainer}>
                <Image style={styles.boxAnimation} source={require('../assets/box.gif')} />
                <Text style={{fontFamily: 'InconsolataBold', fontWeight: '800', fontSize: 17, marginBottom: 10}}>STATUS:</Text>
                <Text style={{marginBottom: 30}}><TicketStatus ticket={ticketContext.ticket}/></Text>
              </View>
            <Text style={styles.subTitleText}>{locationTitleText}</Text>
            <JewelerLocation location={ticketContext.ticket?.location}/>
            <Text style={styles.subTitleText}>Items</Text>
            <ItemList items={ticketContext.ticket.ticket_items.map(ti => ti.item)}/>
          </>
          }
      </View>
      </ScrollView>
    </SafeAreaView>
  )
}
