import React from 'react';
import { ActivityIndicator, View, Image, SafeAreaView } from 'react-native';
import { styles } from '../styles/global';

function SplashScreen(props) {
  return (
    <View style={styles.loadScreen}>
      <SafeAreaView style={styles.loadScreenSafeArea}>
      <View style={styles.loadScreenContainer}>
        <Image style={styles.loadScreenLogo} source={require('../assets/jewelry-box.png')} />
        <ActivityIndicator size="large" color="white" style={{marginBottom: 100}}/>
      </View>
      </SafeAreaView>
    </View>
  );
}

export default SplashScreen;
