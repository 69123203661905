import React, { useContext, useState } from 'react';
import { View, SafeAreaView, Text, ScrollView } from 'react-native';
import { styles } from '../styles/global';
import { API } from '../lib/api';
import PasswordInput from '../components/passwordInput';
import IconButtonLogin from '../components/iconButtonLogin';
import { AuthContext } from '../context';
import { useToast } from 'react-native-toast-notifications';

export default function ChangePasswordScreen({ route, navigation }) {
  const authContext = useContext(AuthContext);
  const token = route.params.token;
  const user = route.params.user;
  const [passwordNew, setPasswordNew] = useState('');
  const toast = useToast();

  async function updatePassword() {
    const data = {'reset_token': token, 'new_password': passwordNew};

    try {
      const response = await API.updateUser(user, data);
      toast.show('Password updated.');
      authContext.login(response);
    } catch (e) {
      toast.show('Failed to reset password.');
    }
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView>
        <View style={styles.container}>
          <Text style={styles.titleText}>Reset Password</Text>
          <Text style={{marginBottom: 20}}>Enter your new password. All current sessions will be logged out.</Text>
          <PasswordInput placeholder='new password' value={passwordNew} onChangeText={setPasswordNew}/>
          <IconButtonLogin title='Save' icon='save' onPress={updatePassword}/>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
