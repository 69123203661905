import React from 'react';
import { View, Text, Image, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useLinkProps } from '@react-navigation/native';
import { styles } from '../styles/global';
import { API } from '../lib/api';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';

export default function JewelerLocation(props) {
  const ifNullBehavior = props.ifNull || 'loading';
  const linkProps = useLinkProps({to: props.linkTo ? props.linkTo(props.location.id) : ''});

  const content = (


  <View style={styles.listItemNoBottom}>
    {

      props.location?.image_url ?
      <Image style={styles.thumbnailImg} source={{uri: API.host + props.location.image_url}}/> :
      <View style={styles.accountSummaryIconContainer}><FontAwesome5 style={styles.accountSummaryIcon} name={'map-marker-alt'} color={'#0D1520'} size={24}/></View>

    }
    <View style={styles.listItemContentCondensed}>
      {
        props.location ?
        <>
          <Text style={styles.listItemTitle} selectable={true}>{props.location.name}</Text>
          <Text style={styles.listDescription}>{props.location.address1}</Text>
          <Text style={styles.listDescription}>{props.location.address2}</Text>
          <Text style={styles.listDescription}>{props.location.city}, {props.location.state} {props.location.postal_code}</Text>
        </>
        :
        ifNullBehavior == 'select' ?
        <Text style={styles.timelineItemText}>Select a service location...</Text>
        :
        <ActivityIndicator/>
      }
    </View>
    { props.actionIcon ?
      <FontAwesome5 style={styles.listItemAction} name={props.actionIcon} color='black' size={15}/> : <></>
    }
  </View>
  );

  if (props.onPress) {
    return (
      <TouchableOpacity style={styles.listItemContainerNoGap} onPress={() => props.onPress(props.location?.id)}>
        {content}
      </TouchableOpacity>
    );
  } else if (props.linkTo) {
    return (
      <TouchableOpacity {...linkProps}>
        {content}
      </TouchableOpacity>
    );
  } else {
    return content;
  }
}
