/**
 * Storage layer that supports web and mobile.
 * 
 * On mobile, uses expo-secure store.
 * On web, uses LocalStorage and SessionStorage.
 */
 import * as SecureStore from 'expo-secure-store';
 import { Platform } from 'react-native';

let Storage = {};

if (Platform.OS == 'web') {
  async function getItem(key) {
    let value = window.localStorage.getItem(key);

    if (value === null) {
      value = window.sessionStorage.getItem(key);
    }

    return value;
  }

  async function setItem(key, value) {
    return window.localStorage.setItem(key, value);
  }

  async function setItemSession(key, value) {
    // Does nothing on mobile. Session items only make sense on web, where we 
    // use LocalStorage and SessionStorage.
    return window.sessionStorage.setItem(key, value);
  }

  async function deleteItem(key) {
    window.localStorage.removeItem(key);
    window.sessionStorage.removeItem(key);
  }

  Storage = {
    getItem: getItem,
    setItem: setItem,
    setItemSession: setItemSession,
    deleteItem: deleteItem,
  };

} else {
  async function getItem(key) {
    return SecureStore.getItemAsync(key);
  }

  async function setItem(key, value) {
    return SecureStore.setItemAsync(key, value);
  }

  async function setItemSession(key, value) {
    // Does nothing on mobile. Session items only make sense on web, where we 
    // use LocalStorage and SessionStorage.
  }

  async function deleteItem(key) {
    return SecureStore.deleteItemAsync(key);
  }

  Storage = {
    getItem: getItem,
    setItem: setItem,
    setItemSession: setItemSession,
    deleteItem: deleteItem,
  };
}

export default Storage;
