import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View, SafeAreaView, Text, TextInput, ScrollView } from 'react-native';

import { styles } from '../styles/global';
import { API } from '../lib/api';
import User from '../components/user';
import QuickActionButton from '../components/quickActionButton';

export default function JewelerCustomers({ navigation }) {
  const [searchString, setSearchString] = useState('');
  const [tickets, setTickets] = useState(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    if (tickets !== null) return;

    API.tickets().then((loadedTickets) => {
      setTickets(loadedTickets);
    })
  });

  useEffect(() => {
    if (searchString.length > 0 && searchString.length < 3) {
      return;
    }

    setLoading(true);

    let query = searchString.length > 0 ? API.users(searchString) : API.users();
    query.then(res => {
      setUsers(res);
      setLoading(false);
    }).catch(() => {
      setUsers(null);
      setLoading(false);
    });

  }, [searchString]);

  let queryResult = <></>;

  if (loading) {
    queryResult = <View style={{justifyContent: "center", flex: 1}}>
      <ActivityIndicator size="small" />
    </View>;
  }
  else if (users !== null && users.length > 0) {
    queryResult = users.map(user => {
      return <User key={user.id} user={user} onPress={(user) => navigation.navigate('JewelerUserCollectionScreen', {id: user.id})}/>
    })
  } else {
    queryResult = <Text>No users found.</Text>
  }

  return (
  <SafeAreaView style={styles.safeArea}>
    <ScrollView>
      <View style={styles.fullContainer}>
        <Text style={styles.titleText}>Customers</Text>
        <TextInput style={styles.textInput} onChangeText={setSearchString} value={searchString} placeholder="Search by name, username, or email"/>
        {queryResult}
      </View>
    </ScrollView>
  </SafeAreaView>
  )
}
