import { Dimensions, Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";
import { colors } from "../../config/Colors";
import { useNavigation } from "@react-navigation/native";
import { API } from "../../lib/api";

let { width } = Dimensions.get('window');
width = width > 500 ? 500 : width;
const ProfileInfoCard = ({user}) => {
 const navigation=useNavigation();
  return (
    <View style={styles.cardMainContainer}>
      <View style={styles.cardHeader}>
      <TouchableOpacity>
      <Image
          style={styles.profileAvatar}
          source={{uri: API.apiUrl(user.profile_image_url)}}
        />
        </TouchableOpacity>

        <View style={styles.headerRightContainer}>
          <TouchableOpacity
          onPress={()=>navigation.navigate('Collection', {screen: 'CollectionScreen'})}
          style={styles.headerRightItem}>
            <Text style={styles.title}>{user.item_count}</Text>
            <Text style={styles.subTitle}>items</Text>
          </TouchableOpacity>

          <TouchableOpacity
          onPress={()=>navigation.navigate('FollowersScreen', {userId: user.id})}
          activeOpacity={0.2}
          style={styles.headerRightItem}>
            <Text style={styles.title}>{user.follower_count}</Text>
            <Text style={styles.subTitle}>Followers</Text>
          </TouchableOpacity>

          <TouchableOpacity
          activeOpacity={0.2}
          onPress={()=>navigation.navigate('FollowingScreen', {userId: user.id})}
          >
            <Text style={styles.title}>{user.following_count}</Text>
            <Text style={styles.subTitle}>Following</Text>
          </TouchableOpacity>
        </View>
      </View>

      <View style={styles.cardPersoneletails}>
        <Text style={styles.name}>{user.username}</Text>
        <Text style={styles.place}>{user.location}</Text>
        <Text style={styles.bio}>{user.bio}</Text>
      </View>

      <View style={styles.seprator} />
    </View>
  );
}

const styles = StyleSheet.create({
  cardMainContainer: {
    marginTop: 6,
    zIndex:-999999,
    width: "100%",
    maxWidth: 500
  },
  cardHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 17.61,
    paddingRight: 19.5,
    width: "100%",
    maxWidth: 500
  },
  profileAvatar: {
    width: 76,
    height: 76,
    borderRadius: 38
  },
  headerRightContainer: {},
  title: {
    fontSize: 12.5,
    fontFamily: "Bold",
    color: colors.black,
    textAlign: "center",
    width: "100%",
    maxWidth: 500
  },
  subTitle: {
    fontSize: 12.5,
    fontFamily: "Regular",
    color: colors.black,
    marginTop: 3,
    textAlign: "center",
  },
  headerRightContainer: {
    flexDirection: "row",
    alignItems: "center",

  },
  headerRightItem: {
    marginRight: 24,
  },
  cardPersoneletails: {
    paddingHorizontal: 18,
    paddingTop: 22,
  },
  name: {
    fontSize: 11.5,
    fontFamily: "Bold",
    color: colors.black,
  },
  place: {
    fontSize: 11.5,
    fontFamily: "Regular",
    color: colors.halfblack,
    marginTop: 4,
  },
  bio: {
    fontSize: 11.5,
    fontFamily: "Regular",
    color: colors.black,
    marginTop: 4,
  },
  seprator: {
    width: width,
    height: 1,
    backgroundColor: colors.border,
    marginTop: 16,
  },
});

export default ProfileInfoCard;
