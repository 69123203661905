import React, { useEffect, useState } from 'react';
import { ActivityIndicator, TextInput, Text, View } from 'react-native';
import { styles } from '../styles/global';
import { API } from '../lib/api';
import User from '../components/user';

export default function UserSeach(props) {
  const [searchString, setSearchString] = useState('');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    if (searchString.length < 3) {
      return;
    }

    setLoading(true);
    API.users(searchString).then(res => {
      // TODO: filter out the current user.
      setUsers(res);
      setLoading(false);
    }).catch(() => {
      setUsers(null);
      setLoading(false);
    });

  }, [searchString]);

  let queryResult = <></>;

  if (loading) {
    queryResult = <View style={{justifyContent: "center", flex: 1}}>
      <ActivityIndicator size="small" />
    </View>;
  }
  else if (users !== null && users.length > 0) {
    queryResult = users.map(user => {
      return <User key={user.id} user={user} onPress={props.onSelectUser}/>
    })
  } else if (searchString.length >= 3) {
    queryResult = <Text>No users found.</Text>
  }

  return (
    <View style={styles.sidePadding}>
      <TextInput style={styles.textInput}
        onChangeText={setSearchString} value={searchString}
        placeholder="Search by name, username, or email"
      />
      {queryResult}
    </View>
  );
}
