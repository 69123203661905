import React, { useContext, useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Animated,
  Dimensions,
} from "react-native";
import { colors } from "../../config/Colors";
import TimeLineList from "../Profile/TimeLineList";
import ImageGrid from "../Profile/ImageGrid";
import { API } from "../../lib/api";
import { AuthContext } from '../../context';

const { width: SCREEN_WIDTH } = Dimensions.get("window");

export default function ItemDetailSegmentTabBar({itemId}) {
  const [activeTab, setActiveTab] = useState("Timeline");
  const scrollX = useRef(new Animated.Value(0)).current;
  const scrollViewRef = useRef();
  const authContext = useContext(AuthContext);
  const [memories, SetMemories] = useState([]);

  useEffect(() => {
    API.memories(null, itemId).then(response => {
      SetMemories(response);
    }).catch(err => {
      // TODO: provide a better "failed to load" screen.
      console.log(err);
    });
  }, [authContext.user]);

  let { width: SCREEN_WIDTH } = Dimensions.get("window");

  if (SCREEN_WIDTH > 500) {
      SCREEN_WIDTH = 500;
  }


  scrollX.addListener(({ value }) => {
    setActiveTab(value < SCREEN_WIDTH / 2 ? "Timeline" : "Grid");
  });

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    const position = tabName === "Timeline" ? 0 : SCREEN_WIDTH;
    scrollViewRef.current.scrollTo({ x: position, animated: true });
  };

  return (
    <View style={styles.container}>
      <Tabs
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        scrollX={scrollX}
      />
      <ContentTabs scrollX={scrollX} scrollViewRef={scrollViewRef} memories={memories}/>
    </View>
  );
}

const Tabs = ({ activeTab, handleTabChange, scrollX }) => (
  <View style={styles.tabContainer}>
    <TouchableOpacity
      style={styles.tabButton}
      onPress={() => handleTabChange("Timeline")}
    >
      <Text
        style={[styles.tabText, activeTab === "Timeline" && styles.activeTab]}
      >
        Timeline
      </Text>
    </TouchableOpacity>
    <TouchableOpacity
      style={styles.tabButton}
      onPress={() => handleTabChange("Grid")}
    >
      <Text style={[styles.tabText, activeTab === "Grid" && styles.activeTab]}>
        Grid
      </Text>
    </TouchableOpacity>
    <Animated.View
      style={[
        styles.indicator,
        {
          transform: [
            {
              translateX: scrollX.interpolate({
                inputRange: [0, SCREEN_WIDTH],
                outputRange: [0, SCREEN_WIDTH / 2],
              }),
            },
          ],
        },
      ]}
    />
  </View>
);

const ContentTabs = ({ scrollX, scrollViewRef, memories }) => (
  <View>
  <Animated.ScrollView
    bounces={false}
    horizontal
    showsHorizontalScrollIndicator={false}
    pagingEnabled
    onScroll={Animated.event(
      [{ nativeEvent: { contentOffset: { x: scrollX } } }],
      { useNativeDriver: false }
    )}
    scrollEventThrottle={16}
    ref={scrollViewRef}
  >
    <View style={styles.content}>
    <TimeLineList data={memories} />
    </View>
    <View style={styles.gridcontent}>
    <ImageGrid data={memories}/>
    </View>
  </Animated.ScrollView>
  </View>
);

const styles = StyleSheet.create({
  container: {
    height:290,
  },
  tabContainer: {
    flexDirection: "row",
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderBottomColor: colors.tabTopBorder,
    maxWidth: 500
  },
  tabButton: {
    flex: 1,
    alignItems: "center",
    padding: 15,
  },
  tabText: {
    fontSize: 14,
    color: colors.primary,
    fontFamily: "Regular",
  },
  activeTab: {
    fontFamily: "SemiBold",
    color: colors.primary,
    fontSize: 14,
  },
  indicator: {
    position: "absolute",
    bottom: 0,
    height: 2,
    width: SCREEN_WIDTH / 2,
    maxWidth: 500 / 2,
    backgroundColor: colors.primary,
  },
  content: {
    width: SCREEN_WIDTH,
    maxWidth: 500
  },
  dataCommingText:
  {
    fontSize:16,
    fontFamily:'SemiBold',
    marginTop:50,
    textAlign:'center'
  },
  gridcontent:
  {
    width: SCREEN_WIDTH,
    maxWidth: 500
  }
});
