import React, { useContext, useState } from 'react';
import { Button, Switch, Text, TextInput, TouchableOpacity, View, SafeAreaView, Image } from 'react-native';
import { Link } from '@react-navigation/native';
import { styles } from '../styles/global';
import { API, APIIncorrectCredentials } from '../lib/api';
import { AuthContext } from '../context.js';
import SwitchOption from '../components/switchOption'
import Message from '../components/message';
import PasswordInput from '../components/passwordInput';

function ModePrompt(props) {
  return (<View style={{flexDirection: 'row', justifyContent: 'center', marginTop: 20}}>
    <Text>{props.prompt} </Text>
    <Link to={props.to}><Text style={{color: 'black', textDecorationLine: 'underline'}}>{props.title}</Text></Link>
  </View>);
}

function ErrorText(props) {
  if (props.message == '') {
    return <></>;
  }
  return <Text style={{color: 'red', marginTop: 15}}>{props.message}</Text>;
}

function LoginForm(props) {
  const [identity, setIdentity] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [borderColor, setBorderColor] = useState();
  const authContext = useContext(AuthContext);

  function login() {
    setError('');

    if (identity == '') {
      setError('Please enter your username or email address.');
      return;
    }
    if (password == '') {
      setError('Please enter your password.');
      return;
    }

    API.login(identity, password, true).then((user) => {
      return authContext.login(user);
    }).catch((err) => {
      if (err.constructor === APIIncorrectCredentials) {
        setError('Login or password is incorrect.');
        console.log(err);
      } else {
        setError('An error occurred.');
        console.log(err);
      }
    });
  }

  return (
    <View style={styles.loginScreenSafeArea}>
      <View style={styles.loginContainer}>
      { authContext.redirect ?
      <Message text={"Sign in to view the requested page: " + authContext.redirect}/>
      :
      <></>
      }
      <Image style={styles.boxAnimation} source={require('../assets/box.gif')} />
      <Image style={styles.loginScreenLogo} source={require('../assets/jewelry-box-black.png')} />
      <View style={styles.inputContainer}>
      <TextInput
        onBlur={() => setBorderColor('black')}
        onFocus={() => setBorderColor('orange')}
        style={[{borderColor}, styles.input]} placeholder="username or email" placeholderTextColor="#aaa" onChangeText={setIdentity}/>
      </View>
      <View style={styles.inputContainer}>
        <PasswordInput style={styles.passwordInput} value={password} onChangeText={setPassword}/>
      </View>
      <View style={{flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'stretch', marginLeft: 3, marginRight: 3, alignItems: 'center'}}>
        <Link to={{screen: 'ForgotPassword'}}><Text style={{color: 'black', textDecoration: 'underline'}}>Forgot password?</Text></Link>
      </View>
      <TouchableOpacity style={styles.buttonLogin} onPress={login}>
        <Text style={styles.buttonText}>Sign In</Text>
      </TouchableOpacity>
      <ModePrompt prompt="Don't have an account?" title="Register now" to={{screen: 'Register', action: 'replace'}}/>
      <ErrorText message={error}/>
      </View>
    </View>
  );
}

function RegisterForm(props) {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const authContext = useContext(AuthContext);

  function register() {
    if (username == '') {
      setError('Please enter a username');
      return;
    }
    if (email == '') {
      setError('Please enter an email address');
      return;
    }
    if (password == '') {
      setError('Please enter a password');
      return;
    }

    API.register(username, name, email, password, true).then((user) => {
      authContext.login(user);
    });
  }

  return (
    <View style={styles.loginScreenSafeArea}>
      <View style={styles.loginContainer}>
      <Image style={styles.boxAnimation} source={require('../assets/box.gif')} />
      <Image style={styles.loginScreenLogo} source={require('../assets/jewelry-box-black.png')} />
      <View style={styles.inputContainer}>
        <TextInput style={styles.input} placeholder="username" placeholderTextColor="#aaa" onChangeText={setUsername}/>
      </View>
      <View style={styles.inputContainer}>
        <TextInput style={styles.input} placeholder="full name" placeholderTextColor="#aaa" onChangeText={setName}/>
      </View>
      <View style={styles.inputContainer}>
        <TextInput style={styles.input} placeholder="email" placeholderTextColor="#aaa" onChangeText={setEmail}/>
      </View>
      <View style={styles.inputContainer}>
        <PasswordInput style={styles.passwordInput} value={password} onChangeText={setPassword}/>
      </View>
      <TouchableOpacity style={styles.buttonLogin} onPress={register}>
        <Text style={styles.buttonText}>Sign Up</Text>
      </TouchableOpacity>
      <ModePrompt prompt="Already have an account?" title="Log in" to={{screen: 'LoginStack', params: {screen: 'Login'},  action: 'replace'}}/>
      <ErrorText message={error}/>
    </View>
  </View>
  );
}

export function LoginScreen({ navigation }) {
  return (
  <SafeAreaView style={styles.safeArea}>
      <LoginForm navigation={navigation}/>
  </SafeAreaView>
  );
}

export function RegisterScreen({ navigation }) {
  return (
  <SafeAreaView style={styles.safeArea}>
      <RegisterForm navigation={navigation}/>
  </SafeAreaView>
  );
}
