import React, { useContext, useEffect } from 'react';
import { View, SafeAreaView, Text } from 'react-native';
import { styles } from '../styles/global';
import TopNav from '../navigation/topnav';
import { AuthContext } from '../context.js';

export default function NotFoundScreen({ navigation, route }) {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (authContext.user.role !== null && authContext.isAuthPath(route.path)) {
      // If the user is logged in, but tries to go to a login screen, just go home.
      navigation.replace(authContext.user.role == 'JEWELER' ? 'JewelerDashboard' : 'HomeScreen');
    }
  });

  return (
  <SafeAreaView style={styles.safeArea}>
    <View style={styles.container}>
      <Text style={styles.titleText}>Not Found</Text>
      <Text>Page not found: {route.path}</Text>
    </View>
  </SafeAreaView>
  )
}
