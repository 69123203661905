import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View } from "react-native";
import Ticket from "./ticket";
import { API } from '../lib/api';
import { useNavigation } from '@react-navigation/native';

export default function TicketList() {
  const navigation = useNavigation();
  const [tickets, setTickets] = useState(null);

  function queryAPI() {
    API.tickets().then((loadedTickets) => {
      setTickets(loadedTickets);
    })
  }

  useEffect(() => {
    queryAPI();
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // Refresh when the screen enters focus.
      queryAPI();
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [navigation]);

  const ticketList = (tickets || []).map(ticket => {
    return <Ticket key={ticket.id} ticket={ticket} showUser={true} linkTo={(id) => {
      return { screen: 'Jobs', params: {screen: 'ServiceTicketEditorScreen', initial: false, params: { id: id }} }
    }} />
  });

  return <View>{tickets === null ? <ActivityIndicator size="large" /> : ticketList}</View>;
}
