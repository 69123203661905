import React, { useContext, useEffect, useState } from 'react';
import { View, SafeAreaView, Text, ScrollView, ActivityIndicator } from 'react-native';
import { styles } from '../styles/global';
import { API } from '../lib/api';
import IconButton from '../components/iconButton';
import { AuthContext } from '../context';
import { useToast } from 'react-native-toast-notifications';

export default function EmailVerificationScreen({ route, navigation }) {
  const [verifying, setVerifying] = useState(true);
  const [success, setSuccess] = useState(false);
  const authContext = useContext(AuthContext);
  const token = route.params.token;
  const user = route.params.user;
  const toast = useToast();

  useEffect(() => {
    if (toast.show === undefined) {
      return;
    }

    verifyEmail();
  }, [user, token, toast]);

  async function verifyEmail() {
    const data = {'email_verification_token': token};

    try {
      const response = await API.updateUser(user, data);
      console.log('toast:', toast.show);
      toast.show('Email verified.');
      setSuccess(true);
      setVerifying(false);
      authContext.login(response);
    } catch (e) {
      toast.show('Failed to verify email address.');
      setVerifying(false);
    }
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView>
        <View style={styles.container}>
          <Text style={styles.titleText}>Verify Email</Text>
          { verifying ?
            <View style={{alignItems: 'center'}}>
              <Text style={{marginBottom: 20}}>Verifying your email address...</Text>
              <ActivityIndicator size='large'/>
            </View> : success ? 
            <Text style={{textAlign: 'center'}}>Email address verified.</Text> :
            <View>
              <Text style={{marginBottom: 20}}>Failed to verify email address.</Text>
              { authContext.user ?
                /* Resending requires a current login */
                <IconButton title='Resend Email' icon='paper-plane' onPress={() => API.resendEmailVerification(user)}/>
                : <></>
              }
            </View>
          }
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
