import { registerRootComponent } from 'expo';
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Platform, useWindowDimensions, Text } from 'react-native';
import * as Linking from 'expo-linking';
import { NavigationContainer, useLinkTo, useNavigationState } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { ToastProvider } from 'react-native-toast-notifications';
import { useFonts } from 'expo-font';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';

import { API } from './lib/api';
import { AuthContext } from './context';
import { styles, widthThreshold } from './styles/global';

import AppraisalUploadScreen from './screens/appraisalUpload';
import ItemDetailsScreen from './screens/itemDetails';
import ItemAppraisalScreen from './screens/itemAppraisal';
import ItemWarrantyScreen from './screens/itemWarranty';
import ItemInsuranceScreen from './screens/itemInsurance';
import ItemServiceScreen from './screens/itemService';
import SettingsScreen from './screens/settings';
import HomeScreen from './screens/home';
import {CollectionScreen, JewelerUserCollectionScreen} from './screens/collection';
import ServiceScreen from './screens/service';
import SendItemScreen from './screens/sendItem';
import NotFoundScreen from './screens/notfound';
import { LoginScreen, RegisterScreen } from './screens/login';
import SplashScreen from './screens/splash';
import TopNav from './navigation/topnav';
import JewelerNav from './navigation/jewelernav';
import JewelerDashboard from './screens/jewelerDashboard';
import JewelerCustomers from './screens/jewelerCustomers';
import JewelerJobs from './screens/jewelerJobs';
import JewelerLocationsScreen from './screens/jewelerLocations';
import JewelerLocationForm from './screens/jewelerLocationForm';
import JewelerSettingsScreen from './screens/jewelerSettings';
import ServiceTicketProvider from './screens/serviceTicketProvider';
import ServiceTicketOverviewScreen from './screens/serviceTicketOverview';
import ServiceTicketUserOverviewScreen from './screens/serviceTicketUserOverview';
import ServiceTicketCustomerSelectScreen from './screens/serviceTicketCustomerSelect';
import ServiceTicketItemsScreen from './screens/serviceTicketItems';
import ServiceTicketLocationScreen from './screens/serviceTicketLocation';
import ChangePasswordScreen from './screens/changePassword';
import ResetPasswordScreen from './screens/resetPassword';
import ForgotPasswordScreen from './screens/forgotPassword';
import EmailVerificationScreen from './screens/verifyEmail';
import {ItemEditor, ItemCreator, ServiceTicketItemCreator, JewelerUserItemCreator} from './screens/ItemEditor';
import ServiceTicketStatusScreen from './screens/serviceTicketStatus';

import ProfileScreen from './screens/TabScreens/ProfileScreen';
import CaptureScreen from './screens/TabScreens/CaptureScreen';
import UserProfileScreen from './screens/Other/UserProfileScreen';
import RetireItemScreen from './screens/Other/RetireItemScreen';
import FollowersScreen from './screens/Other/FollowersScreen';
import UserItemsScreen from './screens/Other/UserItemsScreen';
import FollowingScreen from './screens/Other/FollowingScreen';
import UserFollowersScreen from './screens/Other/UserFollowersScreen';
import MemoryDetailScreen from './screens/Other/MemoryDetailScreen';
import UserFollowingScreen from './screens/Other/UserFollowingScreen';
import RequestServiceScreen from './screens/Other/RequestServiceScreen';
import ServiceDetailScreen from './screens/Other/ServiceDetailScreen';

const StackOptions = {
  headerShown: true,
  headerBackTitleVisible: false,
  headerShadowVisible: false,
  headerStyle: {
    backgroundColor: 'white',
    borderBottomColor: 'transparent',
    shadowColor: 'transparent',
    elevation: 0
  }
};

const LoginStack = createNativeStackNavigator();

function LoginStackScreen() {
  return (
    <LoginStack.Navigator screenOptions={StackOptions}>
      <LoginStack.Screen name="Login" component={LoginScreen} options={{headerShown: false, title: 'Login'}}/>
      <LoginStack.Screen name="ForgotPassword" component={ForgotPasswordScreen} options={{title: 'Forgot Password'}}/>
      <LoginStack.Screen name="ResetPassword" component={ResetPasswordScreen} options={{title: 'Reset Password'}}/>
      <LoginStack.Screen name="EmailVerification" component={EmailVerificationScreen} options={{title: 'Verify Email'}}/>
    </LoginStack.Navigator>
  )
}

const HomeStack = createNativeStackNavigator();

function HomeStackScreen() {
  return (
    <HomeStack.Navigator screenOptions={StackOptions}>
      <HomeStack.Screen name="HomeScreen" component={HomeScreen} options={{headerShown: false, title: 'Home'}}/>
      <HomeStack.Screen name="UserProfile" component={UserProfileScreen} options={{headerShown: false, title: 'User Profile'}}/>
      <HomeStack.Screen name="UserItemsScreen" component={UserItemsScreen} options={{title: 'Items', headerShown: false}}/>
      <HomeStack.Screen name="Settings" component={SettingsScreen} options={{title: 'Settings', headerShown: false}} />
      <HomeStack.Screen name="ChangePassword" component={ChangePasswordScreen} options={{title: 'Change Password'}}/>
      <LoginStack.Screen name="EmailVerification" component={EmailVerificationScreen} options={{title: 'Verify Email'}}/>
      <HomeStack.Screen name="NotFound" component={NotFoundScreen} options={{title: 'Not Found'}}/>
      <HomeStack.Screen name="UserFollowersScreen" component={UserFollowersScreen} options={{title: 'Followers', headerShown: false}}/>
      <HomeStack.Screen name="UserFollowingScreen" component={UserFollowingScreen} options={{title: 'Following', headerShown: false}}/>
    </HomeStack.Navigator>
  );
}

const ProfileStack = createNativeStackNavigator();

function ProfileStackScreen() {
  return (
    <ProfileStack.Navigator screenOptions={StackOptions}>
      <ProfileStack.Screen name="ProfileScreen" component={ProfileScreen} options={{title: 'Profile', headerShown: false}}/>
      <ProfileStack.Screen name="FollowersScreen" component={FollowersScreen} options={{title: 'Followers', headerShown: false}}/>
      <ProfileStack.Screen name="FollowingScreen" component={FollowingScreen} options={{title: 'Following', headerShown: false}}/>
      <ProfileStack.Screen name="MemoryDetailScreen" component={MemoryDetailScreen} options={{title: 'Memory', headerShown: false}}/>
    </ProfileStack.Navigator>
  );
}

const CaptureStack = createNativeStackNavigator();

function CaptureStackScreen() {
  return (
    <CaptureStack.Navigator screenOptions={StackOptions}>
      <CaptureStack.Screen name="CaptureScreen" component={CaptureScreen} options={{title: 'Capture', headerShown: false}}/>
    </CaptureStack.Navigator>
  );
}

const CollectionStack = createNativeStackNavigator();

function CollectionStackScreen() {
  return (
    <CollectionStack.Navigator screenOptions={StackOptions}>
      <CollectionStack.Screen name="CollectionScreen" component={CollectionScreen} options={{title: 'Collection', headerShown: false}}/>
      <CollectionStack.Screen name="ItemDetails" component={ItemDetailsScreen} options={{title: 'Item Details', headerShown: false}}/>
      <CollectionStack.Screen name="RetireItemScreen" component={RetireItemScreen} options={{title: 'Retire Item', headerShown: false}}/>
      <CollectionStack.Screen name="SendItemScreen" component={SendItemScreen} options={{title: 'Send Item', headerShown: false}}/>
      <CollectionStack.Screen name="ItemEditor" component={ItemEditor} options={{title: 'Edit Item', headerShown: false}}/>
      <CollectionStack.Screen name="ItemCreator" component={ItemCreator} options={{title: 'Create Item', headerShown: false}}/>
      <CollectionStack.Screen name="ItemAppraisalScreen" component={ItemAppraisalScreen} options={{title: 'Appraisal', headerShown: false}}/>
      <CollectionStack.Screen name="ItemWarrantyScreen" component={ItemWarrantyScreen} options={{title: 'Warranty', headerShown: false}}/>
      <CollectionStack.Screen name="ItemInsuranceScreen" component={ItemInsuranceScreen} options={{title: 'Insurance', headerShown: false}}/>
      <CollectionStack.Screen name="ItemServiceScreen" component={ItemServiceScreen} options={{title: 'Service', headerShown: false}}/>
    </CollectionStack.Navigator>
  );
}

const ServiceStack = createNativeStackNavigator();

function ServiceStackScreen() {
  return (
    <ServiceTicketProvider>
      <ServiceStack.Navigator screenOptions={StackOptions}>
        <ServiceStack.Screen name="ServiceScreen" component={ServiceScreen} options={{title: 'Service', headerShown: false}}/>
        <ServiceStack.Screen name="RequestServiceScreen" component={RequestServiceScreen} options={{title: 'Request Service', headerShown: false}}/>
        <ServiceStack.Screen name="ServiceTicketCreatorScreen" component={ServiceTicketOverviewScreen} options={{title: 'Request Service', headerShown: false}}/>
        <ServiceStack.Screen name="ServiceTicketItemsScreen" component={ServiceTicketItemsScreen} options={{title: 'Items', headerShown: false}}/>
        <ServiceStack.Screen name="ServiceTicketLocationScreen" component={ServiceTicketLocationScreen} options={{title: 'Location', headerShown: false}}/>
        <ServiceStack.Screen name="ServiceTicketUserOverviewScreen" component={ServiceTicketUserOverviewScreen} options={{title: 'Overview', headerShown: false}}/>
        <ServiceStack.Screen name="ServiceDetailScreen" component={ServiceDetailScreen} options={{title: 'Service Detail', headerShown: false}}/>
    </ServiceStack.Navigator>
    </ServiceTicketProvider>
  );
}

const JewelerDashboardStack = createNativeStackNavigator();

function JewelerDashboardStackScreen() {
  return (
    <JewelerDashboardStack.Navigator screenOptions={StackOptions}>
      <JewelerDashboardStack.Screen name="JewelerDashboard" component={JewelerDashboard} options={{headerShown: false, title: 'Dashboard'}}/>
      <JewelerDashboardStack.Screen name="JewelerSettings" component={JewelerSettingsScreen} options={{title: 'Settings'}} />
      <JewelerDashboardStack.Screen name="ChangePassword" component={ChangePasswordScreen} options={{title: 'Change Password'}}/>
      <JewelerDashboardStack.Screen name="JewelerLocationsScreen" component={JewelerLocationsScreen} options={{title: 'Locations'}}/>
      <JewelerDashboardStack.Screen name="JewelerLocationForm" component={JewelerLocationForm} options={{title: 'Locations Editor'}}/>
      <JewelerDashboardStack.Screen name="NotFound" component={NotFoundScreen} options={{title: 'Not Found'}}/>
    </JewelerDashboardStack.Navigator>
  );
}

const JewelerCustomersStack = createNativeStackNavigator();

function JewelerCustomersStackScreen() {
  return (
    <JewelerCustomersStack.Navigator screenOptions={StackOptions}>
      <JewelerCustomersStack.Screen name="JewelerCustomers" component={JewelerCustomers} options={{headerShown: false, title: 'Customers'}}/>
      <JewelerCustomersStack.Screen name="JewelerUserItemCreator" component={JewelerUserItemCreator} options={{title: 'Create Item'}}/>
      <JewelerCustomersStack.Screen name="JewelerUserCollectionScreen" component={JewelerUserCollectionScreen} options={{title: 'User Collection'}}/>
      <JewelerDashboardStack.Screen name="ItemDetails" component={ItemDetailsScreen} options={{title: 'Item Details'}}/>
      <JewelerDashboardStack.Screen name="ItemEditor" component={ItemEditor} options={{title: 'Edit Item'}}/>
      <JewelerDashboardStack.Screen name="ItemCreator" component={ItemCreator} options={{title: 'Create Item'}}/>
      <JewelerDashboardStack.Screen name="ItemAppraisalScreen" component={ItemAppraisalScreen} options={{title: 'Appraisal'}}/>
      <JewelerDashboardStack.Screen name="AppraisalUpload" component={AppraisalUploadScreen} options={{title: 'Upload Appraisal'}}/>
    </JewelerCustomersStack.Navigator>
  )
}

const JewelerJobsStack = createNativeStackNavigator();

function JewelerJobsStackScreen() {
  return (
    <JewelerJobsStack.Navigator screenOptions={StackOptions}>
      <JewelerJobsStack.Screen name="JobsScreen" component={JewelerJobs} options={{headerShown: false, title: 'Jobs'}}/>
      <JewelerJobsStack.Screen name="ServiceTicketCreatorScreen" component={ServiceTicketOverviewScreen} options={{title: 'New Service Ticket'}}/>
      <JewelerJobsStack.Screen name="ServiceTicketEditorScreen" component={ServiceTicketOverviewScreen} options={{title: 'Service Ticket'}}/>
      <JewelerJobsStack.Screen name="ServiceTicketCustomerSelectScreen" component={ServiceTicketCustomerSelectScreen} options={{title: 'Select Customer'}}/>
      <JewelerJobsStack.Screen name="ServiceTicketItemsScreen" component={ServiceTicketItemsScreen} options={{title: 'Items'}}/>
      <JewelerJobsStack.Screen name="ServiceTicketLocationScreen" component={ServiceTicketLocationScreen} options={{title: 'Location'}}/>
      <JewelerJobsStack.Screen name="ServiceTicketItemCreator" component={ServiceTicketItemCreator} options={{title: 'Create Item'}}/>
      <JewelerJobsStack.Screen name="ServiceTicketStatusScreen" component={ServiceTicketStatusScreen} options={{title: 'Ticket Status'}}/>
      <JewelerJobsStack.Screen name="NotFound" component={NotFoundScreen} options={{title: 'Not Found'}}/>
    </JewelerJobsStack.Navigator>
  );
}

function useTabDisplayMode() {
  const window = useWindowDimensions();
  return window.width <= widthThreshold ? 'flex' : 'none';
}

function Redirector() {
  // Redirects to the page requested by the user.
  // Must be in a component in the navigator in order to use useLinkTo()
  const linkTo = useLinkTo();
  const authContext = useContext(AuthContext);
  const navState = useNavigationState(state => state);
  const screen = navState?.routes[navState.index || 0].name;

  useEffect(() => {
    if (authContext.redirectReady && authContext.redirect) {
      if (screen == 'HomeScreen') {
        console.log('Redirecting to ', authContext.redirect);
      }
      linkTo(authContext.redirect);
      authContext.clearRedirect();
    }
  });

  return <></>;
}

const Tab = createBottomTabNavigator();

async function registerForPushNotifications() {
  if (Device.isDevice && Platform.OS != 'web') {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      console.log('Failed to get push token for push notification!');
      return;
    }
    const token = (await Notifications.getExpoPushTokenAsync({ projectId: Constants.expoConfig.extra.eas.projectId })).data;
    console.log('Push token:', token);
    API.savePushToken(token);

    Notifications.setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false,
      }),
    });
  } else {
    console.log('Push notifications disabled: no physical device.');
  }

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      // TODO: probably not MAX importance, may want to customize color / vibration pattern.
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }
}

const unauthenticatedRoutes = {
  LoginStack: {
    initialRouteName: 'Login',
    screens: {
      Login: 'login',
      ForgotPassword: 'password/forgot',
      ResetPassword: 'password/reset/:user/:token',
      EmailVerification: 'email/verify/:user/:token'
    }
  },
  Register: 'register',
};

const appRoutes = {
  Home: {
    initialRouteName: 'HomeScreen',
    screens: {
      HomeScreen: '',
      NotFound: '*',
      Settings: 'settings',
      ChangePassword: 'settings/password',
      EmailVerification: 'email/verify/:user/:token',
      UserProfile: {
        path: 'user/:id',
        parse: { id: Number },
      },
      UserItemsScreen: {
        path: 'user/:userId/items',
        parse: { userId: Number },
      },
      UserFollowersScreen: {
        path: 'user/:userId/followers',
        parse: { userId: Number },
      },
      UserFollowingScreen: {
        path: 'user/:userId/following',
        parse: { userId: Number },
      },
    }
  },
  Profile: {
    initialRouteName: 'ProfileScreen',
    screens: {
      ProfileScreen: 'profile',
      FollowersScreen: {
        path: 'followers',
        parse: { userId: Number },
      },
      FollowingScreen: {
        path: 'following',
        parse: { userId: Number },
      },
      MemoryDetailScreen: {
        path: 'memory/:id',
        parse: { id: Number },
      },
    }
  },
  Capture: {
    initialRouteName: 'CaptureScreen',
    screens: {
      CaptureScreen: 'capture',
    }
  },
  Collection: {
    initialRouteName: 'CollectionScreen',
    screens: {
      CollectionScreen: 'collection',
      ItemDetails: {
        path: 'collection/item/:id',
        parse: { id: Number, },
      },
      SendItemScreen: {
        path: 'collection/item/:id/send',
        parse: { id: Number, },
      },
      RetireItemScreen: {
        path: 'retire/item/:id/send',
        parse: { id: Number, },
      },
      ItemCreator: {
        path: 'collection/item/new'
      },
      ItemEditor: {
        path: 'collection/item/:id/edit',
        parse: { id: Number, },
      },
      ItemAppraisalScreen: {
        path: 'collection/item/:id/appraisal',
        parse: { id: Number, },
      },
      ItemWarrantyScreen: {
        path: 'collection/item/:id/warranty',
        parse: { id: Number, },
      },
      ItemInsuranceScreen: {
        path: 'collection/item/:id/insurance',
        parse: { id: Number, },
      },
      ItemServiceScreen: {
        path: 'collection/item/:id/service',
        parse: { id: Number, },
      },
    },
  },
  Service: {
    initialRouteName: 'ServiceScreen',
    screens: {
      ServiceScreen: 'service',
      ServiceTicketCreatorScreen: 'service/ticket/new',
      ServiceTicketItemsScreen: 'tickets/new/items',
      ServiceTicketLocationScreen: 'tickets/new/location',
      ServiceTicketUserOverviewScreen: {
        path: 'tickets/:id?',
        parse: { id: Number, },
      },
    }
  }
};

const serviceRoutes = {
  Home: {
    initialRouteName: 'JewelerDashboard',
    screens: {
      JewelerDashboard: '',
      JewelerSettings: 'settings',
      ChangePassword: 'settings/password',
      JewelerLocationsScreen: 'locations',
      JewelerLocationForm: {
        path: 'locations/:id',
        parse: { id: (id) => id === 'new' ? 'new' : parseInt(id) },
        stringify: { id: (id) => id == 'new' ? 'new' : id.toString()}
      },
    },
  },
  Customers: {
    initialRouteName: 'JewelerCustomers',
    screens: {
      JewelerCustomers: {
        path: 'customers',
      },
      JewelerUserCollectionScreen: {
        path: 'customers/:id/collection',
        parse: { id: Number, },
      },
      JewelerUserItemCreator: {
        path: 'customers/:id/collection/item/new',
        parse: { id: Number, },
      },
      ItemDetails: {
        path: 'items/:id',
        parse: { id: Number, },
      },
      ItemCreator: {
        path: 'collection/item/new'
      },
      ItemEditor: {
        path: 'collection/item/:id/edit',
        parse: { id: Number, },
      },
      ItemAppraisalScreen: {
        path: 'items/:id/appraisal',
        parse: { id: Number, },
      },
      AppraisalUpload: {
        path: 'items/:id/appraisal/upload',
        parse: { id: Number, },
      }
    }
  },
  Jobs: {
    initialRouteName: 'JobsScreen',
    screens: {
      JobsScreen: {
        path: 'jobs',
      },
      ServiceTicketCreatorScreen: {
        path: 'tickets/new',
      },
      ServiceTicketEditorScreen: {
        path: 'tickets/:id?',
        parse: { id: Number, },
      },
      ServiceTicketCustomerSelectScreen: {
        path: 'tickets/:id?/customer',
        parse: { id: Number, },
      },
      ServiceTicketItemsScreen: {
        path: 'tickets/:id?/items',
        parse: { id: Number, },
      },
      ServiceTicketLocationScreen: {
        path: 'tickets/:id?/location',
        parse: { id: Number, },
      },
      ServiceTicketItemCreator: {
        path: 'tickets/:id?/items/new',
        parse: { id: Number, },
      },
      ServiceTicketStatusScreen: {
        path: 'tickets/:id?/status',
        parse: { id: Number, },
      },
    }
  },
  NotFound: '*'
};

const prefix = Linking.createURL('/');

function App() {
  const [loaded, setLoaded] = useState(false);
  const [tryLogin, setTryLogin] = useState(true);
  const [user, setUser] = useState(null);
  const [initialUrl, setInitialUrl] = useState();
  const tabDisplayMode = useTabDisplayMode();
  const [navigationReady, setNavigationReady] = useState(false);

  async function _handleLogin(new_user) {
    let tokenUpdate = user !== null;

    console.log('User login:', new_user);
    setUser(new_user);

    if (!tokenUpdate) {
      registerForPushNotifications();
    }
  }

  const authContext = useMemo(() => {
    return {
      login: _handleLogin,
      logout: () => {
        API.logout().then(() => {
          setUser(null);
        });
      },
      isAuthPath: (path) => {
        const routeLists = [unauthenticatedRoutes.LoginStack.screens, unauthenticatedRoutes];
        for (const routeListIndex in routeLists) {
          const routeList = routeLists[routeListIndex];
          for (const route in routeList) {
            if (path == '/' + routeList[route]) {
              return true;
            }
          }
        }
        return false;
      },
      redirectReady: navigationReady,
      redirect: initialUrl,
      user: user,
      clearRedirect: () => {setInitialUrl(null)},
    };
  }, [initialUrl, navigationReady, user]);

  // Try logging in with the saved token.
  useEffect(() => {
    if (!tryLogin) return;
    // Only try logging in once.
    setTryLogin(false);

    API.loginWithToken().then((user) => {
      _handleLogin(user);
      setLoaded(true);
    }).catch(error => {
      console.log('Login with token failed.', error);
      setLoaded(true);
    });
  });

  // Redirect to the requested page after login.
  useEffect(() => {
    if (Platform.OS == 'web') {
      const path = window.location.pathname + window.location.search;
      if (user?.role === null && !initialUrl) {
        // If the initial URL belongs to the unauthenticated route, don't forward it.
        if (path == '/' || authContext.isAuthPath(path)) return;
        setInitialUrl(path);
        console.log('initial URL:', path);
      }
    }
  });

  const unauthenticatedLinking = {config: {screens: unauthenticatedRoutes}};
  const linking = {
    prefixes: [prefix, 'jewelrybox://'],
    config: { screens: appRoutes },
    subscribe(listener) {
      // Listen to expo push notifications
      const notificationSubscription = Notifications.addNotificationResponseReceivedListener(response => {
        const url = response.notification.request.content.data.url;
        console.log('received notification:', response.notification.request.content);

        // Let React Navigation handle the URL
        listener(url);
      });

      return () => {
        // Clean up the event listeners
        notificationSubscription.remove();
      };
    },
  };
  const serviceLinking = {
    config: {
      initialRouteName: 'JewelerDashboard',
      screens: serviceRoutes
    },
  };

  const [fontsLoaded] = useFonts({
    'ContrailOne': require('./assets/fonts/ContrailOne.ttf'),
    'Inconsolata': require('./assets/fonts/Inconsolata.ttf'),
    'InconsolataBold': require('./assets/fonts/InconsolataBold.ttf'),
    'Lato': require('./assets/fonts/Lato-Regular.ttf'),
    'LatoBold': require('./assets/fonts/Lato-Bold.ttf'),
    'LatoBlack': require('./assets/fonts/Lato-Black.ttf'),
    'LatoItalic': require('./assets/fonts/Lato-Italic.ttf'),
    Bold: require("./assets/fonts/Inter-Bold.ttf"),
    SemiBold: require("./assets/fonts/Inter-SemiBold.ttf"),
    Medium: require("./assets/fonts/Inter-Medium.ttf"),
    Regular: require("./assets/fonts/Inter-Regular.ttf"),
  });

  if (!loaded || !fontsLoaded) {
    return <SplashScreen />;
  }
  return (
    <ToastProvider>
    <AuthContext.Provider value={authContext}>
      { user == null ?
      <NavigationContainer linking={unauthenticatedLinking}>
        <Tab.Navigator screenOptions={{headerShown: false, tabBarStyle: {display: 'none'}}}>
          <Tab.Screen name="LoginStack" component={LoginStackScreen}/>
          <Tab.Screen name="Register" component={RegisterScreen}/>
        </Tab.Navigator>
      </NavigationContainer>
      : user?.role == 'INDIVIDUAL' ?
      <NavigationContainer linking={linking} onReady={() => setNavigationReady(true)} onStateChange={() => setNavigationReady(true)}>
        <TopNav/>
        <Redirector/>
        <Tab.Navigator
            style={ styles.bottomNav }
            screenOptions={{
              headerShown: false,
              tabBarActiveTintColor: '#000000',
              tabBarInactiveTintColor: '#B2B2B2',
              tabBarStyle: {height: 94, paddingTop: 16, paddingBottom: 36, display: tabDisplayMode},
              tabBarLabelStyle: {fontSize: 10},
            }}
        >
        <Tab.Screen
          name="Home"
          component={HomeStackScreen}
          options={{
            tabBarIcon: ({ color, focused }) => (<FontAwesome5 name={'home'} color={color} size={18} solid/>)
            }}
        />
        <Tab.Screen
          name="Profile"
          component={ProfileStackScreen}
          options={{
            tabBarIcon: ({ color, focused }) => (<FontAwesome5 name={'user'} color={color} size={18} solid/>)
            }}
        />
        <Tab.Screen
          name="Capture"
          component={CaptureStackScreen}
          options={{
            tabBarIcon: ({ color, focused }) => (<FontAwesome5 name={'plus-circle'} color={color} size={18}/>)
            }}
        />
        <Tab.Screen
          name="Collection"
          component={CollectionStackScreen}
          options={{
            tabBarIcon: ({ color, focused }) => (<FontAwesome5 name={'layer-group'} color={color} size={18} />)
            }}
        />
        <Tab.Screen
          name="Service"
          component={ServiceStackScreen}
          options={{
            tabBarIcon: ({ color, focused }) => (<FontAwesome5 name={'tools'} color={color} size={18} solid/>)
            }}
        />
        </Tab.Navigator>
      </NavigationContainer>
      : user?.role == 'JEWELER' ?
      <ServiceTicketProvider>
        <NavigationContainer linking={serviceLinking} onReady={() => setNavigationReady(true)} onStateChange={() => setNavigationReady(true)}>
          <JewelerNav/>
          <Tab.Navigator
              style={ styles.bottomNav }
              screenOptions={{
                headerShown: false,
                tabBarActiveTintColor: '#000000',
                tabBarInactiveTintColor: '#B2B2B2',
                tabBarStyle: {height: 94, paddingTop: 16, paddingBottom: 36, display: tabDisplayMode},
                tabBarLabelStyle: {fontSize: 10},
              }}
          >
          <Tab.Screen
            name="Home"
            component={JewelerDashboardStackScreen}
            options={{
              tabBarIcon: ({ color, focused }) => (<FontAwesome5 name={'home'} color={color} size={18} solid/>)
              }}
          />
          <Tab.Screen
            name="Customers"
            component={JewelerCustomersStackScreen}
            options={{
              tabBarIcon: ({ color, focused }) => (<FontAwesome5 name={'home'} color={color} size={18} solid/>)
              }}
          />
          <Tab.Screen
            name="Jobs"
            component={JewelerJobsStackScreen}
            options={{
              tabBarIcon: ({ color, focused }) => (<FontAwesome5 name={'home'} color={color} size={18} solid/>)
              }}
          />
          </Tab.Navigator>
        </NavigationContainer>
      </ServiceTicketProvider>
      :
      <Text>No such role: {user?.role}</Text>
      }
    </AuthContext.Provider>
    </ToastProvider>
  );
}

export default registerRootComponent(App);
