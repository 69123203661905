import React from 'react';
import { View, Image, Text, TouchableOpacity, useWindowDimensions } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { styles, widthThreshold } from '../styles/global';
import { useLinkProps, useNavigation, useNavigationState } from '@react-navigation/native';

function TopNavLink(props) {
  const navigation = useNavigation();
  let style = props.active ? styles.topNavLinkActive : styles.topNavLink;

  return <Text style={style} onPress={() => navigation.navigate(props.screen)}>{props.text}</Text>;
}

function JewelerNav(props) {
  const window = useWindowDimensions();
  const navState = useNavigationState(state => state);
  const screen = navState?.routes[navState.index || 0].name;
  const profileLinkProps = useLinkProps({to: {screen: 'Home', params: {screen: 'JewelerSettings', initial: false}}});

  return (
    <View style={styles.topNav}>
    <Image style={styles.topNavLogo} source={require('../assets/jewelry-box.png')} />
      {
        window.width <= widthThreshold ?
        <></>
        :
        <>
          <TopNavLink active={screen == 'Home'} screen='Home' text='Home'/>
          <TopNavLink active={screen == 'Customers'} screen='Customers' text='Customers'/>
          <TopNavLink active={screen == 'Jobs'} screen='Jobs' text='Jobs'/>
        </>
      }
      <TouchableOpacity style={styles.topNavAccount} {...profileLinkProps}>
        <FontAwesome5 name={'user-circle'} color={"white"} size={20} solid/>
      </TouchableOpacity>
    </View>
  );
}

export default JewelerNav;
