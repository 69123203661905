import {
  StyleSheet,
  Text,
  View,
  FlatList,
  Image,
  Dimensions,
} from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { colors } from "../../config/Colors";
import Header from "../../components/Common/Header";
import MemoryItem from "../../components/Capture/MemoryItem";
import { API } from "../../lib/api";
import { AuthContext } from "../../context";
import { useNavigation } from "@react-navigation/native";

let { width } = Dimensions.get('window');
width = width > 500 ? 500 : width;

export default function UserItemsScreen({ route }) {
  const { userId } = route.params;
  const authContext = useContext(AuthContext);
  const navigation = useNavigation();
  const [items, setItems] = useState([]);

  useEffect(() => {
    API.collection(userId).then(items => {
      setItems(items);
    }).catch(err => {
      // TODO: provide a better "failed to load items" screen.
      console.log(err);
    });
  }, [userId]);

  const renderItem = ({ item }) => (
    <MemoryItem
      avatarUri={API.apiUrl(item.images[0].url)}
      title={item.brand}
      subtitle={item.description}
      onPress={() => console.log(item.id)}
    />
  );

  return (
    <View style={styles.containerContainer}>
      <Header title={"Items"} />
      <View style={styles.container}>
      <View style={styles.seprator} />
        <FlatList
            data={items}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
          />
      <View>
      </View>
    </View>
      </View>
  );
}

const styles = StyleSheet.create({
  containerContainer: {
    backgroundColor: "white",
    flex: 1
  },
  container: {
    flex: 1,
    backgroundColor: colors.white,
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight:'auto'
  },
  seprator: {
    width: width,
    height: 1,
    backgroundColor: colors.border,
    alignSelf: "center",
  },
});
