import { Image, StyleSheet, Text, TouchableOpacity, View, Dimensions, useWindowDimensions} from "react-native";
import React, { useState } from 'react';
import { colors } from "../../config/Colors";

import { useNavigation } from "@react-navigation/native";
import { API } from "../../lib/api";

let headerMargin = 90;
const window = Dimensions.get('window'); // Retrieve window dimensions
if (window.width <= 768) {
  headerMargin = 40;
}

export default function Header({ title, icon, onPressIcon, user, setUser }) {
  const navigation = useNavigation();
  const [isFollowing, setIsFollowing] = useState(false);

  async function handleIconPress() {
    if (!isFollowing) {
      API.follow(user.id).then(() => {
        setIsFollowing(true);
        setUser((u) => {return {...u, follower_count: u.follower_count + 1}} )
      });
    } else {
      API.unfollow(user.id).then(() => {
        setIsFollowing(false);
        setUser((u) => {return {...u, follower_count: u.follower_count - 1}} )
      });
    }
  };

  return (
     <View style={styles.headerContainer}>
       <TouchableOpacity onPress={() => navigation.goBack()}>
         <Image
           style={styles.icon}
           source={require('../../assets/icons/Common/arrow-back.png')}
         />
       </TouchableOpacity>
       <Text style={styles.headerTitle}>{title}</Text>
       <TouchableOpacity onPress={handleIconPress}>
         <Image
           style={styles.followIcon}
           source={title === 'User Profile' && isFollowing ? require('../../assets/icons/Common/following-icon.png') : icon}
         />
       </TouchableOpacity>
     </View>
   );
 }

const styles = StyleSheet.create({
  headerContainer: {
    height: 72,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    marginTop: headerMargin,
    width: "100%",
    maxWidth: 500,
    marginLeft: "auto",
    marginRight: "auto"
  },
  headerTitle: {
    textAlign: "center",
    fontSize: 14,
    fontFamily: "SemiBold",
    color: colors.primary,
    marginBottom: 10,
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    zIndex: 3
  },
  icon: {
    width: 40,
    height: 40,
    marginBottom: 10,
    zIndex: 5
  },
  followIcon: {
    width: 80,
    height: 40,
    marginBottom: 10,
    zIndex: 5
  },
});
