import React, { useContext, useEffect, useState } from 'react';
import { View, SafeAreaView, Text, ActivityIndicator, ScrollView } from 'react-native';
import { LineChart } from "react-native-chart-kit";
import { styles } from '../styles/global';
import { AuthContext } from '../context.js';
import IconButton from '../components/iconButton';
import QuickActionButton from '../components/quickActionButton';
import TicketList from '../components/ticketList';

export default function JewelerDashboard({ navigation }) {
  const authContext = useContext(AuthContext);

  const customerData = [23, 18, 12, 16, 24, 27];
  const itemData = [54, 23, 36, 38, 34, 46];
  const jobData = [124, 167, 132, 75, 102, 145];

  const chartConfig = {
    backgroundColor: '#ffffff',
    backgroundGradientFrom: '#ffffff',
    backgroundGradientTo: '#ffffff',
    decimalPlaces: 0,
    color: (opacity = 0) => `rgba(0, 0, 0, ${opacity})`,
    strokeWidth: 2,
    useShadowColorFromDataset: false
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView>
        <View style={styles.fullContainer}>
          <Text style={styles.titleText}>Home</Text>
          <Text style={styles.subTitleTextOrphan}>Quick Actions</Text>
          <View style={styles.itemActionGrid}>
            <QuickActionButton icon='tools' title='New Job' linkTo={{ screen: 'Jobs', params: {screen: 'ServiceTicketCreatorScreen', initial: false}}}/>
            <QuickActionButton icon='map-marker-alt' title='Locations' linkTo={{ screen: 'JewelerLocationsScreen' }}/>
          </View>

          <Text style={styles.subTitleTextOrphan}>Insights</Text>
          <View style={styles.insightsGrid}>
            <View style={styles.insight}>
              <View style={styles.boxContainer}>
                <View style={styles.insightContent}>
                  <Text style={styles.insightTitle}>Customers</Text>
                  <Text style={styles.insightCount}>120</Text>
                  <LineChart
                    data={{
                      labels: ["MAY", "JUN", "JUL", "AUG", "SEP", "OCT"],
                      datasets: [{ data: customerData }]
                    }}
                    width={330}
                    height={175}
                    chartConfig={chartConfig}
                    decimalPlaces={0}
                    bezier
                    fromZero
                    style={styles.chart}
                  />
                </View>
              </View>
            </View>
            <View style={styles.insight}>
              <View style={styles.boxContainer}>
                <View style={styles.insightContent}>
                  <Text style={styles.insightTitle}>Items</Text>
                  <Text style={styles.insightCount}>231</Text>
                  <LineChart
                    data={{
                      labels: ["MAY", "JUN", "JUL", "AUG", "SEP", "OCT"],
                      datasets: [{ data: itemData }]
                    }}
                    width={330}
                    height={175}
                    chartConfig={chartConfig}
                    decimalPlaces={0}
                    bezier
                    fromZero
                    style={styles.chart}
                  />
                </View>
              </View>
            </View>

            <View style={styles.insight}>
              <View style={styles.boxContainer}>
                <View style={styles.insightContent}>
                  <Text style={styles.insightTitle}>Jobs</Text>
                  <Text style={styles.insightCount}>74</Text>
                  <LineChart
                    data={{
                      labels: ["MAY", "JUN", "JUL", "AUG", "SEP", "OCT"],
                      datasets: [{ data: jobData }]
                    }}
                    width={330}
                    height={175}
                    chartConfig={chartConfig}
                    decimalPlaces={0}
                    bezier
                    fromZero
                    style={styles.chart}
                  />
                </View>
              </View>
            </View>
          </View>
          <View style={styles.itemListwithGap}>
            <View style={styles.itemListTitle}>
            <Text style={styles.subTitleText}>Open Jobs</Text>
            </View>
          <TicketList/>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}
