import React, { useContext, useEffect, useState, useRef } from 'react';
import { View, SafeAreaView, Text, Button, Image, TouchableOpacity, ScrollView, FlatList, Stylesheet, Dimensions } from 'react-native';
import { styles } from '../styles/global';
import { API } from '../lib/api';
import CollectionItem from '../components/collectionItem'
import { AuthContext } from '../context';
import IconButton from '../components/iconButton';
import { useNavigation } from '@react-navigation/native';
import User from '../components/user';
import { colors } from "../config/Colors";
import MemoryItem from "../components/Capture/MemoryItem";
import Header from "../components/Common/Header";

let { width } = Dimensions.get('window');
width = width > 500 ? 500 : width;

function CollectionItemList(props) {
  const [collection, setCollection] = useState({
    items: null,
    refreshing: false,
  });

  const navigation = useNavigation();

  function queryAPI() {
    API.collection(props.userId).then(items => {
      setCollection({refreshing: false, items: items});
    }).catch(err => {
      // TODO: provide a better "failed to load items" screen.
      setCollection({...collection, items: [], refreshing: false});
      console.log(err);
    });
  }

  useEffect(() => {
    setCollection({...collection, refreshing: true});
    queryAPI();
  }, [props.userId]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // Refresh when the screen enters focus.
      queryAPI();
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [navigation]);

  return <View style={styles.itemList}>
    {(collection.items || []).map(item => <CollectionItem key={item.id} item={item} actionIcon='chevron-right' linkTo={props.linkTo}/>)}
  </View>;
}

export function JewelerUserCollectionScreen({ route, navigation }) {
  const userId = route.params.id;

  function getItemDetailsScreen(itemId) {
    return {screen: 'Customers', params: {screen: 'ItemDetails', initial: false, params: {id: itemId}}};
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView>
        <View style={styles.container}>
          <Text style={styles.titleText}>Collection</Text>
          <CollectionItemList userId={userId} linkTo={getItemDetailsScreen}></CollectionItemList>
          <IconButton title="NEW ITEM" linkTo={{screen: 'JewelerUserItemCreator', params: {id: userId}}}/>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

export function CollectionScreen({ navigation }) {
  const authContext = useContext(AuthContext);
  const [selectedMenuItemKey, setSelectedMenuItemKey] = useState('2');
  const [menuVisible, setMenuVisible] = useState(null);
  const menuOptions = [
    { key: "1", label: "Add item" },
    { key: "2", label: "Sort items" },
    { key: "3", label: "Filter items" },
  ];

  const renderItem = ({ item }) => (
    <MemoryItem
      avatarUri={item.avatarUri}
      title={item.title}
      subtitle={item.subtitle}
    />
  );

  const renderMenuOption = ({ item }) => (
    <TouchableOpacity
      style={[
        styles.menuOption,
        item.key === selectedMenuItemKey ? styles.menuOptionSelected : {},
      ]}
      onPress={() => onSelectMenuOption(item)}
    >
      <Text style={styles.menuOptionText}>{item.label}</Text>
    </TouchableOpacity>
  );

  const onSelectMenuOption = (option) => {
    setSelectedMenuItemKey(option.key); // Set the selected menu item key
    console.log("Selected option:", option.label);
    setMenuVisible(false);
  };

  function getItemDetailsScreen(itemId) {
    return {screen: 'Collection', params: {screen: 'ItemDetails', initial: false, params: {id: itemId}}};
  }

  return (
    <View style={styles.containerContainer}>
        <View style={styles.container}>
      <Header
        icon={require("../assets/icons/Common/menu-plus-icon.png")}
        title={"Items"}
        onPressIcon={() => navigation.navigate('ItemCreator')}
      />
      <ScrollView style={styles.scrollArea}>
          <View style={styles.separator} />
          <CollectionItemList userId={authContext.user.id}  linkTo={getItemDetailsScreen}></CollectionItemList>
      </ScrollView>
        </View>
            </View>
  );
}
