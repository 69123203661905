import React, { useEffect, useState } from 'react';
import { styles } from '../styles/global';
import { API } from '../lib/api';
import { ActivityIndicator, Button, SafeAreaView, Text, View } from 'react-native';
import CollectionItem from '../components/collectionItem';

export default function ItemAppraisalScreen({ route, navigation }) {
  const itemId = route.params?.id;
  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        <Text>Insurance</Text>
      </View>
    </SafeAreaView>
  );
}
