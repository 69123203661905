import {
  StyleSheet,
  Text,
  View,
  FlatList,
  TouchableOpacity,
  Image,
  Dimensions,
  ScrollView,
} from "react-native";
import React, { useState } from "react";
import { colors } from "../../config/Colors";
import Header from "../../components/Common/Header";
import ItemDetailsCard from "../../components/Items/ItemDetailsCard";
import ServiceDetailsCard from "../../components/Items/ServiceDetailsCard";
import ItemDetailSegmentTabBar from "../../components/Items/ItemDetailSegmentBar";
import TransferHistoryItem from "../../components/Items/TransferHistoryItem";
import ServiceHistoryItem from "../../components/Items/ServiceHistoryItem";
import MemoryItem from "../../components/Capture/MemoryItem";
import { TransferHistory, ServiceHistory } from "../../config/SampleJsonData";
import Button from "../../components/Common/Button";

const { width, height } = Dimensions.get("window");
export default function ServiceDetailScreen({ route,navigation }) {

  const [selectedMenuItemKey, setSelectedMenuItemKey] = useState("3");
  const [menuVisible, setMenuVisible] = useState(null);
  const menuOptions = [
    { key: "1", label: "Edit items" },
    { key: "2", label: "Add memory" },
    { key: "3", label: "Submit for service" },
    { key: "4", label: "Transfer item" },
    { key: "5", label: "Retire item" },
  ];
  const renderMenuOption = ({ item }) => (
    <TouchableOpacity
      style={[
        styles.menuOption,
        item.key === selectedMenuItemKey ? styles.menuOptionSelected : {},
      ]}
      onPress={() => onSelectMenuOption(item)}
    >
      <Text style={styles.menuOptionText}>{item.label}</Text>
    </TouchableOpacity>
  );

  const onSelectMenuOption = (option) => {
    setSelectedMenuItemKey(option.key);
    console.log("Selected option:", option.label);
    setMenuVisible(false);
  };

  const renderItem = ({ item }) => (
    <ServiceHistoryItem
      avatarUri={item.avatarUri}
      title={item.title}
      subtitle={item.subtitle}
    />
  );
  return (
    <View style={styles.containerContainer}>
      <Header
        title="Service Detail Screen"
      />
        <View style={styles.container}>
      <ScrollView
        contentContainerStyle={{ paddingBottom: 100 }}
        showsVerticalScrollIndicator={false}
      >
<View style={styles.seprator} />
              <MemoryItem
                avatarUri={require("../../assets/icons/Common/rolexGmtAvatar.png")}
                title="GMT-Master II"
                subtitle="Rolex"
              />
      <View
        style={[styles.iphoneGalleryContainerNoBottomNoTop, styles.additionalStylesitem]}
      >
        <Text style={styles.iphoneGalleryTextCentered}>
          Service Log
        </Text>
      </View>

      <View style={styles.seprator} />
      <View>
        <FlatList
          data={ServiceHistory}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />
      </View>

      <View
        style={{
          ...styles.iphoneGalleryContainerNoTop,
          borderWidth: 1,
          borderColor: colors.tabTopBorder,
          height: 72,
        }}
      >
        <Text style={styles.iphoneGalleryTextCentered}>Service Details</Text>
      </View>
      <ServiceDetailsCard />
        <View
          style={{
            ...styles.iphoneGalleryContainerNoTop,
            borderWidth: 1,
            borderColor: colors.tabTopBorder,
            height: 72,
          }}
        >
          <Text style={styles.iphoneGalleryTextCentered}>Service Center</Text>
        </View>
        <MemoryItem
          avatarUri={require("../../assets/icons/Common/sawmill.png")}
          title="Diamond Cellar (Dublin, OH)"
          subtitle="6280 Sawmill Road"
        />
        <View
        style={[styles.iphoneGalleryContainerNoTop, styles.additionalStylesitem]}
      >
        <Text style={styles.iphoneGalleryTextCentered}>
          Actions
        </Text>
      </View>

      <View style={styles.buttonMainContainer}>
      <View style={styles.buttonContainer}>
      <Button
      title={"Contact Service Center"}
      color={colors.primary}
      />
      <Button
      title={"Cancel Service"}
      color={colors.redButtom}
      />
      </View>

      </View>
      </ScrollView>
    </View>
      </View>
  );
}

const styles = StyleSheet.create({
  containerContainer: {
    backgroundColor: "white",
    flex: 1
  },
  container: {
    flex: 1,
    backgroundColor: colors.white,
    maxWidth: 500,
    marginTop: 0,
    padding: 0,
    marginTop: 0,
    paddingTop: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 0
  },
  menuContainer: {
    position: "absolute",
    top: 50,
    right: 10,
    backgroundColor: "white",
    borderWidth: 2,
    borderColor: colors.border,
    borderRadius: 5,
    zIndex: 99999,
    minWidth: 144,
  },
  menuOption: {
    padding: 10,
    zIndex: 999999,
  },
  menuOptionText: {
    color: colors.primary,
    fontSize: 14,
    fontFamily: "Regular",
    borderLeftWidth: 0
  },
  menuOptionSelected: {
    backgroundColor: colors.tabTopBorder,
  },
  underHeaderContainer: {
    zIndex: -99999,
  },
  itemImage: {
    width: width,
    height: height * 0.45,
  },
  serviceDetails: {
    height: 200,
    width: width
  },
  serviceDetailsHeader: {
    display: "block",
    fontFamily: "SemiBold",
  },
  serviceDetailsText: {
    display: "block",
    fontFamily: "Regular",
  },
  iphoneGalleryContainer: {
    width: width,
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "left",
    borderTopWidth: 1,
    borderTopColor: colors.tabTopBorder,
    borderLeftWidth: 0
  },
  iphoneGalleryContainerNoTop: {
    width: width,
    height: 48,
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "left",
    borderTopWidth: 0,
    borderTopColor: colors.tabTopBorder,
    borderLeftWidth: 0
  },
  iphoneGalleryTextCentered: {
    fontSize: 14,
    fontFamily: "SemiBold",
    color: "#545f71",
    textAlign: "center",
    width: width,
    maxWidth: 500,
  },
  iphoneGalleryContainerNoBottom: {
    width: width,
    height: 48,
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "left",
    borderTopWidth: 1,
    borderBottomWidth: 0,
    borderTopColor: colors.tabTopBorder,
    borderLeftWidth: 0
  },
  iphoneGalleryContainerNoBottomNoTop: {
    width: width,
    height: 48,
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "left",
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderTopColor: colors.tabTopBorder,
    borderLeftWidth: 0
  },
  iphoneGalleryText: {
    fontSize: 14,
    fontFamily: "SemiBold",
    color: colors.primary,
    borderLeftWidth: 0
  },
  seprator: {
    width: width,
    height: 1,
    backgroundColor: colors.border,
borderLeftWidth: 0
  },
  additionalStyles: {
    borderWidth: 1,
    borderColor: colors.tabTopBorder,
    borderTopWidth: 0,
    height: 65,
    borderLeftWidth: 0
  },
  additionalStylesitem: {
    borderWidth: 1,
    borderColor: colors.tabTopBorder,
    height: 65,
    borderLeftWidth: 0
  },
  chevron: {
    opacity: 0,
  },
  buttonContainer:
  {
    marginBottom:16,
  },
  buttonMainContainer:
  {
    marginTop:23
  }
});
