import React, { useContext, useEffect, useState } from 'react';
import { View, SafeAreaView, Text, ActivityIndicator, Image, ScrollView, TouchableOpacity, useWindowDimensions, Dimensions, FlatList } from 'react-native';
import { styles } from '../styles/global';
import { LineChart } from "react-native-chart-kit";
import { API } from '../lib/api';
import IconButton from '../components/iconButton';
import ItemImage from '../components/itemImage';
import { useToast } from 'react-native-toast-notifications';
import { DateTime } from 'luxon';
import DocumentLink from '../components/documentLink';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../context';

import Header from "../components/Common/Header";
import ItemDetailsCard from "../components/Items/ItemDetailsCard";
import ItemDetailSegmentTabBar from "../components/Items/ItemDetailSegmentBar";
import TransferHistoryItem from "../components/Items/TransferHistoryItem";
import Button from "../components/Common/Button";
import { colors } from "../config/Colors";

let { width } = Dimensions.get('window');
width = width > 500 ? 500 : width;






const chartConfig = {
  backgroundColor: '#ffffff',
  backgroundGradientFrom: '#ffffff',
  backgroundGradientTo: '#ffffff',
  decimalPlaces: 0,
  color: (opacity = 0) => `rgba(0, 0, 0, ${opacity})`,
  strokeWidth: 2,
  useShadowColorFromDataset: false
};

function parseDateTime(s)
{
  return DateTime.fromISO(s, { zone: "UTC" }).setZone('system');
}

function ItemTransfer(props) {
  const toast = useToast();
  const authContext = useContext(AuthContext);
  let text, icon;

  if (props.transfer.user_from)
  {
    icon = 'exchange-alt'
    if (props.transfer.user_from.email == authContext.user.email)
    {
      text = `Transferred to ${props.transfer.user_to.name}`
    }
    else {
      text = `Transferred from ${props.transfer.user_from.name}`;
    }
  }
  else
  {
    icon = 'star';
    text = `Created for ${props.transfer.user_to.name}`;
  }

  return (
 <View style={styles.listItem}>
  <View style={styles.accountSummaryIconContainer}><FontAwesome5 style={styles.accountSummaryIcon} name={icon} color={'#0D1520'} size={24}/></View>
    <View style={styles.listItemContentCondensed}>
      <Text style={styles.listItemTitle}>{text}</Text>
      <Text style={styles.listDescription}>{parseDateTime(props.transfer.datetime).toLocaleString(DateTime.DATETIME_MED)}</Text>
      <Text style={styles.listDescriptionSub}>{props.transfer.status}</Text>
    </View>
    {props.transfer.status == 'PENDING' ?
    <TouchableOpacity style={{position: 'absolute', width: 80, right: 15, ...styles.button}} onPress={() => {
      API.cancelTransfer(props.transfer.id).then(() => {
        // TODO: if this component had internal state, the parent wouldn't have to re-render the whole list...
        props.onCancel();
      });}}>
      <Text style={styles.buttonText}>Cancel</Text>
    </TouchableOpacity>
    :
    <></>
    }
  </View>);
}

function ItemTransferList(props) {
  const [transfers, setTransfers] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const toast = useToast();

  function queryAPI() {
    setRefreshing(true);
    API.transfers(props.item_id).then(t => {
      setTransfers(t);
      setRefreshing(false);
    }).catch(err => {
      toast.show('Failed to load transfer history')
      setRefreshing(false);
      console.log(err);
    });
  }

  useEffect(() => {
    queryAPI();
  }, [props.item_id]);

  return <View>
    { isRefreshing ?
    <ActivityIndicator/>
    :
    transfers.map(transfer => {
      return <ItemTransfer key={transfer.id} transfer={transfer} onCancel={() => queryAPI()}></ItemTransfer>;
    })
    }
  </View>
}

export default function ItemDetailsScreen({ route, navigation}) {
  const { id } = route.params;
  const [ item, setItem ] = useState(null);
  const itemId = route.params?.id;
  const authContext = useContext(AuthContext);
  const toast = useToast();
  const { height, width } = useWindowDimensions();
  const { titles } = route.params;

  const [selectedMenuItemKey, setSelectedMenuItemKey] = useState("3");
  const [menuVisible, setMenuVisible] = useState(null);
  const menuOptions = [
    { key: "1", label: "Edit item" },
    { key: "2", label: "Add memory" },
    { key: "3", label: "Submit for service" },
    { key: "4", label: "Transfer item" },
    { key: "5", label: "Retire item" },
  ];
  const renderMenuOption = ({ item }) => (
    <TouchableOpacity
      style={[
        styles.menuOption,
        item.key === selectedMenuItemKey ? styles.menuOptionSelected : {},
      ]}
      onPress={() => onSelectMenuOption(item)}
    >
      <Text style={styles.menuOptionText}>{item.label}</Text>
    </TouchableOpacity>
  );



  const onSelectMenuOption = (option) => {
    setSelectedMenuItemKey(option.key);
    console.log("Selected option:", option.label);
    setMenuVisible(false);

    if (option.key == '1') {
      navigation.navigate('ItemEditor', { id: item.id });
    } else if (option.key == '2') {
      navigation.navigate('CaptureScreen', { id: item.id });
    } else if (option.key == '3') {
      navigation.navigate('RequestServiceScreen', { id: item.id });
    } else if (option.key == '4') {
      navigation.navigate('SendItemScreen', { id: item.id });
    } else if (option.key == '5') {
      navigation.navigate('RetireItemScreen', { id: item.id });
    }

  };

  const renderItem = ({ item }) => (
    <TransferHistoryItem
      avatarUri={item.avatarUri}
      title={item.title}
      subtitle={item.subtitle}
    />
  );

  async function queryAPI() {
    try {
      let item = await API.item(itemId)
      setItem(item);
    } catch (error) {
      toast.show('Failed to load item details');
      console.log(error);
    }
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // Refresh when the screen enters focus.
      queryAPI();
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    setItem(null);
    queryAPI();
  }, [id]);

  let warrantyDocs = item?.documents.length > 0 ? item.documents.filter(doc => doc.purpose == 'WARRANTY') : [];
  let lastAppraisal = item?.appraisals.length > 0 ? item.appraisals[item.appraisals.length-1] : null;

  return (
    <View style={styles.containerContainer}>
      <Header
        icon={require("../assets/icons/Common/menu-dot-icon.png")}
        title="Item Details"
        onPressIcon={() => setMenuVisible(!menuVisible)}
      />
      {menuVisible && (
        <View style={styles.menuContainer}>
          <FlatList
            data={menuOptions}
            renderItem={renderMenuOption}
            keyExtractor={(item) => item.key}
          />
        </View>
      )}
      <ScrollView showsHorizontalScrollIndicator={false}>
        <View style={styles.container}>
          { item == null ?
            <ActivityIndicator size="large"/> :
            <View>
              <View style={styles.boxContainer}>
                <View style={styles.detailImgContainer}>
                  <ItemImage style={styles.detailImg} image={item?.images[0]}/>
                  <View style={styles.detailImgOverlay}></View>
                </View>
              </View>

              <View style={[styles.iphoneGalleryContainer, styles.additionalStylesitem]}>
                <Text style={styles.iphoneGalleryText}>Item Details</Text>
              </View>

              <ItemDetailsCard />
                <View style={styles.seprator} />
                <ItemDetailSegmentTabBar itemId={id}/>
              <View style={styles.itemList}>
                <View style={styles.itemListTitle}>
                <Text style={styles.subTitleText}>Value</Text>
                </View>
                    { item.appraisals.length > 0 ?
                      <>
                        <View style={styles.chartContainer}>
                          <LineChart
                              data={{
                                labels: item.appraisals.map(ap => parseDateTime(ap.datetime).toLocaleString({month: 'short', day: 'numeric'})),
                                datasets: [{ data: item.appraisals.map(ap => ap.value) }]
                              }}
                              width={Math.min(500, width-50)}
                              height={175}
                              chartConfig={chartConfig}
                              decimalPlaces={0}
                              bezier
                              fromZero
                              yAxisLabel="$"
                              style={styles.chart}
                            />
                        </View>
                        <TouchableOpacity onPress={() => navigation.navigate('ItemAppraisalScreen', { id: item.id })}>
                          <View style={styles.listItem}>
                            <View style={styles.accountSummaryIconContainer}><FontAwesome5 style={styles.accountSummaryIcon} name={'chart-line'} color={'#0D1520'} size={24}/></View>
                            <View style={styles.listItemContentCondensed}>
                              <Text style={styles.listItemTitle}>${lastAppraisal.value.toLocaleString('en', {useGrouping: true})}</Text>
                              <Text style={styles.listDescription}>Last updated {parseDateTime(lastAppraisal.datetime).toLocaleString(DateTime.DATE_MED)}</Text>
                              <Text style={styles.listDescriptionSub}>by {lastAppraisal.appraiser}</Text>
                            </View>
                            <FontAwesome5 style={styles.listItemAction} name={'chevron-right'} color={'#0D1520'} size={15}/>
                          </View>
                        </TouchableOpacity>
                      </>
                      :
                      <View style={styles.promptItem}>
                        <Text style={styles.promptItemText}>No appraisals found.</Text>
                      </View>
                    }
              </View>
              {
                authContext.user.role == 'JEWELER' ?
                <IconButton icon='tools' title='New Appraisal' linkTo={{ screen: 'AppraisalUpload', params: {id: itemId}}}/>
                :
                <></>
              }
              <View style={styles.itemListwithGaps}>
                <View style={styles.itemListTitle}>
                  <Text style={styles.subTitleText}>Warranty Documents</Text>
                </View>

                <View style={styles.documents}>
                {
                  warrantyDocs.length > 0 ?
                  warrantyDocs.map(doc => <DocumentLink key={doc.id} document={doc}/>)
                  :
                  <View style={styles.promptItem}>
                    <Text style={styles.promptItemText}>No documents found.</Text>
                  </View>
                }
              </View>
            </View>

            <View style={styles.itemList}>
              <View style={styles.itemListTitle}>
              <Text style={styles.subTitleText}>Transfer History</Text>
              </View>
              <View style={styles.timeline}>
                    <ItemTransferList item_id={item.id} style={styles.transferHistory} />
              </View>
            </View>
              <View style={styles.footerButtonContainer}>
                <Button title={"Add Memory"} color={colors.primary} onPress={()=>navigation.navigate('Capture')}/>
                <Button title={"Send Item"} color={colors.primary} onPress={()=>navigation.navigate('SendItemScreen')}/>
                <Button title={"Retire Item"} color={colors.redButtom} onPress={()=>navigation.navigate('RetireItemScreen')}/>
              </View>

            </View>
          }
        </View>
      </ScrollView>
    </View>
  );
}
