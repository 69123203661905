import React, { useContext, useState } from 'react';
import { View, SafeAreaView, Text } from 'react-native';
import { styles } from '../styles/global';
import { TicketContext } from '../context';

import UserSearch from '../components/userSearch';

export default function ServiceTicketCustomerSelectScreen({ route, navigation }) {
  const ticketContext = useContext(TicketContext);

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        <UserSearch onSelectUser={(user) => {
          ticketContext.setTicketField('user', user);
          navigation.goBack();
        }}/>
      </View>
    </SafeAreaView>
  )
}
