import React from 'react';
import { View, Image, TouchableOpacity, useWindowDimensions } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { styles } from '../styles/global';
import { Link, useLinkProps, useNavigationState } from '@react-navigation/native';

function TopNavLink(props) {
  let style = props.active ? styles.topNavLinkActive : styles.topNavLink;

  // HACK: This is a hack that assumes the top-level screen of each tab is the name of the tab plus 'Screen'.
  // We must do this since Link does not use initialRouteName.
  // See: https://github.com/react-navigation/react-navigation/issues/10535
  const linkTo = {screen: props.screen, params: {screen: props.screen + 'Screen'}};
  return <Link style={style} to={linkTo}>{props.text}</Link>;
}

function TopNav(props) {
  const window = useWindowDimensions();
  const navState = useNavigationState(state => state);
  const screen = navState?.routes[navState.index || 0].name;
  const profileLinkProps = useLinkProps({to: {screen: 'Home', params: {screen: 'Settings', initial: false}}});

  // Define the width threshold below which the top nav should be hidden
  const widthThreshold = 768; // Example threshold, replace with your desired value

  // Render the top nav only if the window width is greater than the threshold
  if (window.width <= widthThreshold) {
    return null; // If the window width is less than or equal to the threshold, return null to hide the component
  }

  // Render the top nav if the window width is greater than the threshold
  return (
    <View style={styles.topNav}>
      <Image style={styles.topNavLogo} source={require('../assets/jewelry-box-black.png')} />
      <TopNavLink active={screen == 'Home'} screen='Home' text='Home'/>
      <TopNavLink active={screen == 'Profile'} screen='Profile' text='Profile'/>
      <TopNavLink active={screen == 'Capture'} screen='Capture' text='Capture'/>
      <TopNavLink active={screen == 'Collection'} screen='Collection' text='Items'/>
      <TopNavLink active={screen == 'Service'} screen='Service' text='Service'/>
      <TouchableOpacity style={styles.topNavAccount} {...profileLinkProps}>
        <FontAwesome5 name={'user-circle'} color={"black"} size={20} solid/>
      </TouchableOpacity>
    </View>
  );
}

export default TopNav;
