import React, { useContext, useEffect } from 'react';
import { View, SafeAreaView, Text, Dimensions, ScrollView } from 'react-native';
import { styles } from '../styles/global';
import CollectionItem from '../components/collectionItem';
import { TicketContext } from '../context';
import IconButton from '../components/iconButton';
import { Actions } from './serviceTicketProvider';
import Header from "../components/Common/Header";

let { width } = Dimensions.get('window');
width = width > 500 ? 500 : width;

function CollectionPicker(props) {
  function renderEntry(item) {
    return <CollectionItem key={item.id} item={item} onPress={(itemId) => {
      props.dispatch({type: Actions.AddItem, itemId: itemId});
      props.onPick();
    }}/>;
  }
  return (props.items === null ? [] : props.items).map(item => {
    return renderEntry(item);
  });
}

export default function ServiceTicketItemsScreen({ route, navigation }) {
  const ticketContext = useContext(TicketContext);
  const selectableItems = (ticketContext.userCollection || []).filter(collectionItem => {
    if (!ticketContext.ticket.ticket_items) {
      return true;
    }

    if (ticketContext.ticket.ticket_items.find(item => collectionItem.id == item.item_id)) {
      return false;
    }

    // Keep collection items not yet added to the ticket.
    return true;
  });

  useEffect(() => {
    // If no user is selected, redirect back to the ticket overview.
    if (ticketContext.ticket?.user?.id == null) {
      navigation.replace('ServiceTicketEdtiorScreen');
    }
  }, [ticketContext.ticket?.user?.id]);

  return (
<View style={styles.containerContainer}>
    <Header
      title={"Add an Item"}
    />

  <ScrollView showsVerticalScrollIndicator={false}>
      <View style={styles.container}>
    <CollectionPicker
        items={selectableItems}
        dispatch={ticketContext.dispatch}
        onPick={() => navigation.goBack()}
        />
</View>
  </ScrollView>
</View>
);
}
