import React, { useContext } from 'react';
import { View, SafeAreaView, Text, ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { styles } from '../styles/global';
import { AuthContext } from '../context.js';
import IconButton from '../components/iconButton';

function JewelerSettings(props) {
  const authContext = useContext(AuthContext);
  const navigation = useNavigation();

  return(
    <ScrollView>
      <View style={styles.container}>
        <Text style={styles.titleText}>Settings</Text>
        <View style={styles.itemListwithGap}>
          <View style={styles.itemListTitle}>
            <Text style={styles.subTitleText}>Account</Text>
          </View>
          <View style={styles.block}>
            <Text>Email Address: {authContext.user.email}</Text>
          </View>
        </View>
        <IconButton title='Change Password' icon='edit' onPress={() => navigation.navigate('ChangePassword')}/>
        <IconButton title='Log Out' icon='sign-out-alt' onPress={authContext.logout}/>
      </View>
    </ScrollView>
  );
}

export default function JewelerSettingsScreen({ navigation }) {
  return (
    <SafeAreaView style={styles.safeArea}>
      <JewelerSettings/>
    </SafeAreaView>
  );
}
