import React, { useContext, useEffect } from 'react';
import { View, SafeAreaView, Text, TextInput, TouchableOpacity, ActivityIndicator, ScrollView, Image } from 'react-native';
import { useToast } from "react-native-toast-notifications";
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { DateTime } from 'luxon';
import { styles } from '../styles/global';
import { AuthContext, TicketContext } from '../context';
import JewelerLocation from '../components/jewelerLocation';
import IconButton from '../components/iconButton';
import User from '../components/user';
import CollectionItem from '../components/collectionItem';
import SwitchOption from '../components/switchOption'
import { Actions } from './serviceTicketProvider';
import { Picker } from '@react-native-picker/picker';
import { TicketStatus } from '../components/ticket';
import Header from '../components/Common/Header';
import MemoryItemNoImage from "../components/Capture/MemoryItemNoImage";

const TicketStatusLabels = {
  CREATE: 'Ticket Created',
  RECEIVED: 'Received by Jeweler',
  UPDATE: 'Updated',
  PICKUP_READY: 'Ready for Pickup',
  PICKUP: 'Picked up'
};

function TicketItemListEntry(props) {
  const toggleCertify = () => props.dispatch({type: Actions.ToggleCertify, itemId: props.ticketItem.item.id});
  const toggleRepair = () => props.dispatch({type: Actions.ToggleRepair, itemId: props.ticketItem.item.id});
  const setNotes = (text) => props.dispatch({type: Actions.SetNotes, itemId: props.ticketItem.item.id, text: text});

  return (
    <View style={styles.listItemContainer}>
      <View style={styles.listItem}>
        <View style={{flexGrow: 1.0}}>
          <CollectionItem item={props.ticketItem.item} outline={false}/>
          <Text style={styles.accentText}>Tasks</Text>
          <SwitchOption value={props.ticketItem.certify} onValueChange={toggleCertify}>Certify authenticity</SwitchOption>
          <SwitchOption value={props.ticketItem.repair} onValueChange={toggleRepair}>Repair</SwitchOption>
          <Text style={styles.accentText}>Notes</Text>
          <TextInput style={styles.textInputLong} multiline={true} placeholder="Describe requested service" value={props.ticketItem.notes} onChangeText={setNotes}></TextInput>
        </View>
        <TouchableOpacity style={styles.removeBtn} onPress={() => props.dispatch({type: Actions.RemoveItem, itemId: props.ticketItem.item.id})}>
          <FontAwesome5 style={styles.listItemAction} name={'times'} color={'#555F71'} size={15}/>
        </TouchableOpacity>
      </View>
    </View>
  );
}

function TicketItemList(props) {
  return <View style={styles.ticketList}>
    {(props.ticketItems).map(item => <TicketItemListEntry key={item.item.id} ticketItem={item} dispatch={props.dispatch}/>)}
  </View>;
}

function TicketTimeline(props) {
  if (!props.events) {
    return <ActivityIndicator size="large"/>
  }

  let renderedEvents = props.events.map(event => {
    return <View key={event.id} style={styles.timelineItem}>
      <Text selectable={true} style={styles.timelineItemTitle}>{TicketStatusLabels[event.type]}</Text>
      <Text style={styles.timelineItemText} selectable={true}>{event.datetime == null ? '' : DateTime.fromISO(event.datetime, { zone: "UTC" }).setZone('system').toLocaleString(DateTime.DATETIME_MED)}</Text>
      <Text style={styles.timelineItemDescription} selectable={true}>{event.description}</Text>
    </View>
  })

  return <View>{renderedEvents}</View>;
}

function TicketOverviewSection(props) {
  return <View style={styles.itemListwithGaps}>
    <View style={styles.itemListTitle}>
      <Text style={styles.subTitleText}>{props.title}</Text>
    </View>
    {props.children}
  </View>;
}

export default function ServiceTicketOverviewScreen({ route, navigation }) {
  const ticketContext = useContext(TicketContext);
  const authContext = useContext(AuthContext);
  const screenParams = ticketContext.ticketId ? {id: ticketContext.ticketId} : {};
  const toast = useToast();

  useEffect(() => {
    ticketContext.setTicketId(route.params?.id);
  }, [route.params?.id]);

  useEffect(() => {
    if (authContext.user.role != 'JEWELER') {
      ticketContext.setTicketField('user', authContext.user);
    }
  }, [authContext.user]);

  const ticketUserValid = ticketContext.ticket?.user?.id != null;
  const ticketValid = ticketUserValid && ticketContext.ticket?.location?.id != null && ticketContext?.ticket?.ticket_items?.length > 0;

  function saveTicket() {
    ticketContext.saveTicket().then((response) => {
      toast.show('Ticket #' + response.id + ' saved!');
      navigation.pop();
    })
    .catch((error) => {
      toast.show('Failed to save ticket');
      console.log(error);
    });
  }

  function TicketType() {
    let content;
    if (authContext.user.role != 'JEWELER') {
      return <></>;
    } else {
      if (ticketContext.ticketId == null) {
        content = <Picker style={styles.picker} selectedValue={ticketContext.ticket?.type} onValueChange={(t) => ticketContext.setTicketField('type', t)}>
          <Picker.Item label="Service" value="SERVICE"/>
          <Picker.Item label="Order" value="ORDER"/>
        </Picker>;
      } else {
        content =<View style={styles.promptItem}>
          <Text style={styles.promptItemText}>{ticketContext.ticket.type}</Text>
        </View>;
      }
      return <TicketOverviewSection JewelerLocation="Type">{content}</TicketOverviewSection>
    }
  }

  return (
    <View style={styles.containerContainer}>
      <Header title="Request Service" />
      <ScrollView showsVerticalScrollIndicator={false}>
      <View style={styles.container}>
        { ticketContext.ticket?.id != ticketContext.ticketId ?
        <ActivityIndicator size="large"/>
        :
        <>
          {ticketContext.ticket?.id ? <Text style={styles.smallTitleText}>Ticket #{ticketContext.ticketId}</Text> : <></>}
            { ticketContext.ticketId != null ?
              <>
              <View style={styles.statusContainer}>
                <Image style={styles.boxAnimation} source={require('../assets/box.gif')} />
                <Text style={{fontFamily: 'InconsolataBold', fontWeight: '800', fontSize: 17, marginBottom: 10}}>STATUS:</Text>
                <Text style={{marginBottom: 30}}><TicketStatus ticket={ticketContext.ticket}/></Text>
              </View>
                <IconButton title="Update Status" onPress={() => navigation.navigate('ServiceTicketStatusScreen', screenParams)}/>
              </> : <></>
            }
          { authContext.user.role == 'JEWELER' ?
            <TicketOverviewSection title="Customer">
              <User user={ticketContext.ticket?.user} displayEmail={true} onPress={() => navigation.navigate('ServiceTicketCustomerSelectScreen', screenParams)}/>
            </TicketOverviewSection>
            :
            <></>
          }
          <TicketType/>
          <TicketOverviewSection title="Items">
            <TicketItemList ticketItems={ticketContext?.ticket?.ticket_items || []} dispatch={ticketContext.dispatch}/>

              <MemoryItemNoImage
                subtitle={"Add item..."}
                onPress={() => navigation.navigate('ServiceTicketItemsScreen', screenParams)}
              />


          </TicketOverviewSection>
          <TicketOverviewSection title="Location">
            <JewelerLocation location={ticketContext.ticket?.location} onPress={() => navigation.navigate('ServiceTicketLocationScreen', screenParams)} ifNull='select'/>
          </TicketOverviewSection>
          { ticketContext.ticketId != null ?
            <>

            <View style={styles.itemListwithGaps}>
              <View style={styles.itemListTitle}>
              <Text style={styles.subTitleText}>Timeline</Text>
              </View>
              <View style={styles.timeline}>
                <TicketTimeline events={ticketContext.ticket.events}/>
              </View>
                </View>
            </> : <></>
          }
          <IconButton title="Submit" disabled={!ticketValid} onPress={saveTicket}/>
        </>
        }
      </View>
      </ScrollView>
    </View>
  )
}
