import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { colors } from "../../config/Colors";

const ServiceDetails = () => {
  return (
    <View style={styles.container}>
      <View style={styles.column}>
        <Text style={styles.label}>Type</Text>
        <Text style={styles.value}>Full Service</Text>
        <Text style={styles.label}>Notes</Text>
        <Text style={styles.value}>Purchased in 2020. Preform full service. Please only polish the bracelet and clasp.</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    marginTop: 26,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#ddd"
  },
  column: {
    flexDirection: "column",
  },
  label: {
    fontFamily: "SemiBold",
    color: colors.primary,
    marginBottom: 4,
    fontSize: 14,
  },
  value: {
    color: colors.primary,
    fontSize: 12.5,
    marginBottom: 16,
  },
});

export default ServiceDetails;
