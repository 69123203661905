import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext, TicketContext } from '../context';
import { API } from '../lib/api';

export const Actions = {
  ClearItems: 1,
  AddItem: 2,
  RemoveItem: 3,
  ToggleCertify: 4,
  ToggleRepair: 5,
  SetNotes: 6,
  CreateItem: 7,
  AddEvent: 8
}

export default function ServiceTicketProvider(props) {

  function setField(obj, setter, field, value) {
    setter({
      ...(obj || {}),
      [field]: value
    });
  }

  const authContext = useContext(AuthContext);
  const [ticketId, setTicketId] = useState(null);
  const [ticket, setTicket] = useState(null);
  const [userCollection, setUserCollection] = useState(null);

  function setTicketField(field, value) {
    setField(ticket, setTicket, field, value)
  }

  async function updateCollection() {
    setUserCollection(null);
    try {
      let items = await API.collection(ticket.user.id);
      console.log(items);
      setUserCollection(items);
    } catch(error) {
      // TODO: provide a better "failed to load items" screen.
      console.log(err);
    }
  }

  const dispatch = async (action) => {
    // Helper functions to update an individual item's properties
    const withoutItem = (arr, itemId) => arr.filter(item => item.item_id != itemId);
    const findItem = (arr, itemId) => arr.find(item => item.item_id == itemId);

    function updateItem(arr, item, field, value) {
      let without = withoutItem(arr, item.item_id);
      return [
        ...without,
        {
          ...item,
          [field]: value
        }
      ].sort((a, b) => a.item_id - b.item_id);
    }

    let item;
    let newCollection = userCollection;
    switch (action.type) {
      case Actions.ClearTicketItems:
        setTicketField('ticket_items', [])
        break;
      case Actions.CreateItem: // Fallthrough to addItem
        newCollection = [...userCollection, action.item];
        setUserCollection(newCollection);
      case Actions.AddItem:
        setTicketField('ticket_items', [...(ticket.ticket_items || []), {
          certify: false,
          repair: false,
          notes: '',
          item_id: action.itemId,
          item: newCollection.find(item => item.id == action.itemId) || {'id': action.itemId}
        }]);
        break;

      case Actions.RemoveItem:
        setTicketField('ticket_items', withoutItem(ticket.ticket_items, action.itemId));
        break;

      case Actions.ToggleCertify:
        item = findItem(ticket.ticket_items, action.itemId);
        setTicketField('ticket_items', updateItem(ticket.ticket_items, item, 'certify', !item.certify));
        break;

      case Actions.ToggleRepair:
        item = findItem(ticket.ticket_items, action.itemId);
        setTicketField('ticket_items', updateItem(ticket.ticket_items, item, 'repair', !item.repair));
        break;

      case Actions.SetNotes:
        item = findItem(ticket.ticket_items, action.itemId);
        setTicketField('ticket_items', updateItem(ticket.ticket_items, item, 'notes', action.text));
        break;

      case Actions.AddEvent:
        setTicketField('events', [...ticket.events, action.event])
        break;
    }
  };

  const ticketContext = useMemo(() => {
    return {
      ticketId: ticketId,
      ticket: ticket, /* The local state of the ticket */
      userCollection: userCollection,

      dispatch: dispatch,
      reset: () => setTicket(null),
      setTicketId: setTicketId,
      setTicketField: setTicketField,
      saveTicket: saveTicket
    };
  }, [ticketId, ticket, userCollection]);

  useEffect(() => {
    setUserCollection(null);
    if (!ticket?.user?.id) {
      return;
    }

    updateCollection();
  }, [ticket?.user?.id]);

  function saveTicket() {
    // Re-build the post data with only the expected fields
    let data = {
      type: ticketContext.ticket.type || 'SERVICE',
      location_id: ticketContext.ticket.location?.id || null,
      ticket_items: (ticketContext.ticket.ticket_items || []).map((item) => {
        return {
          item_id: item.item_id,
          certify: item.certify,
          repair: item.repair,
          notes: item.notes
        };
      })
    }

    if (authContext.user.role == "JEWELER") {
      data["user_id"] = ticketContext.ticket.user.id;
    }

    if (ticketContext.ticketId == null) {
      return API.submitTicket(data);
    } else {
      return API.updateTicket(ticketContext.ticketId, data);
    }
  }

  useEffect(() => {
    if (ticketContext.ticketId != ticketContext.ticket?.id) {
      setTicket(null);
    }

    if (!ticketContext.ticketId) return;

    API.ticket(ticketContext.ticketId).then(ticket => {
      setTicket(ticket);
    });
  }, [ticketContext.ticketId]);

  return (
    <TicketContext.Provider value={ticketContext}>
      {props.children}
    </TicketContext.Provider>
  );
}
