import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import { useLinkProps } from '@react-navigation/native';
import { styles } from '../styles/global';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import ItemImage from './itemImage';

export default function CollectionItem(props) {
  let detailsLinkProps = useLinkProps({to: props.linkTo ? props.linkTo(props.item.id) : ''});

  const content = (
    <View style={styles.container}>
    <View style={props.outline === false ? {flexDirection: 'row'} : styles.listItemContainer}>
      <View style={props.outline === false ? {flexDirection: 'row'} : styles.listItemCondensed}>
        <ItemImage style={styles.thumbnailImg} image={props.item?.images?.length > 0 ? props.item?.images[0] : null}/>
        <View style={styles.listItemContent}>
          <View style={styles.listItemTitleContainer}>
          <Text style={styles.listItemTitle} selectable={true}>{props.item.description}</Text>
          {
            props.item.appraisals.length > 0 ?
              <Image source={require('../assets/verified.png')} style={styles.verifiedIcon} />
            :
            <></>
          }
          </View>
          <Text style={styles.listDescription}>{props.item.brand}, {props.item.reference}</Text>
        </View>
      </View>
      {
        props.actionIcon ?
        <FontAwesome5 style={styles.listItemAction} name={'chevron-right'} color={'black'} size={15}/>
        :
        <></>
      }
    </View>
    </View>
  );

  if (props.onPress) {
    return <TouchableOpacity onPress={() => props.onPress(props.item.id)}>
      {content}
    </TouchableOpacity>
  } else if (props.linkTo) {
    return <TouchableOpacity {...detailsLinkProps}>
      {content}
    </TouchableOpacity>
  } else {
    return content;
  }
}
