import React, { useState } from 'react';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { View, TextInput, TouchableOpacity } from 'react-native';
import { styles } from '../styles/global';

export default function PasswordInput(props) {
  const [hidePassword, setHidePassword] = useState(true);
  const placeholder = props.placeholder || 'password';

  return (
  <View style={{alignSelf: 'stretch', width: '100%'}} >
    <TextInput style={{...styles.input, flexGrow: 1}} value={props.value} placeholder={placeholder} onChangeText={props.onChangeText} secureTextEntry={hidePassword} placeholderTextColor="#aaa"/>
    <TouchableOpacity style={{position: 'absolute', right: 16, top: 20, bottom: 0}} onPress={() => setHidePassword(!hidePassword)}>
      <FontAwesome5 name={hidePassword ? 'eye' : 'eye-slash'} color='black' size={15}/>
    </TouchableOpacity>
  </View>
  );
}
