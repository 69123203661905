import React, { useEffect, useState } from 'react';
import { View, SafeAreaView, Text, ActivityIndicator, ScrollView, ImageBackground, Dimensions, Image, TouchableOpacity } from 'react-native';
import { useToast } from "react-native-toast-notifications";
import { useNavigation } from '@react-navigation/native';
import { styles } from '../styles/global';
import { API } from '../lib/api';
import Header from "../components/Common/Header";
import { colors } from "../config/Colors";
import Button from "../components/Common/Button";
import {Video} from 'expo-av'

import JewelerLocation from '../components/jewelerLocation';
import Ticket from '../components/ticket';

let { width } = Dimensions.get('window');
width = width > 500 ? 500 : width;

function TicketList(props) {
  const toast = useToast();
  const navigation = useNavigation();
  const [tickets, setTickets] = useState(null);

  useEffect(() => {
    if (tickets !== null) return;

    API.tickets().then((tickets) => {
      setTickets(tickets);
    }).catch(() => {
      setTickets([]);
      console.log('failed to get tickets');
    });
  });

  let renderedTickets = (tickets || []).filter(t => t.type == props.type).map((t) => {
    return <Ticket key={t.id} ticket={t} onPress={(id) => navigation.navigate('ServiceTicketUserOverviewScreen', {id: id})}></Ticket>
  });

  let noneText = {
    ORDER: 'orders',
    SERVICE: 'service tickets',
  }[props.type];

  return (
    <View style={{justifyContent: 'center'}}>
      { tickets === null ?
      <ActivityIndicator size="large"/>
      : renderedTickets.length === 0 ?
      <View style={styles.promptItem}>
        <Text style={styles.promptItemText}>No {noneText} found</Text>
      </View>
      :
      renderedTickets
      }
    </View>
  );
}

export default function ServiceScreen({ navigation }) {
  const toast = useToast();
  const [locations, setLocations] = useState({
    items: null,
    refreshing: false,
  });

  function loadLocations() {
    setLocations({...locations, refreshing: true});
    API.locations().then(items => {
      setLocations({refreshing: false, items: items});
    }).catch(err => {
      // TODO: provide a better "failed to load items" screen.
      setLocations({...locations, items: [], refreshing: false});
      toast.show('Failed to load locations');
      console.log(err);
    });
  }

  useEffect(() => {
    loadLocations();
  }, []);

  return (
    <View style={styles.containerContainer}>
      <Header title="Service" />
      <ScrollView showsVerticalScrollIndicator={false}>
      <View style={styles.container}>
        <View style={styles.imageBackgroundContainer}>
          <Video
style={styles.videoBackground}
source={require("../assets/videos/service.mp4")}
isMuted
isLooping
resizeMode='cover'
shouldPlay
/>
          <Text style={styles.imageBackgroundTitle}>Repair Made Easy With</Text>
<Image
  style={styles.serviceLogo}
  source={require("../assets/images/carousel-white.png")}
  />
<Text style={styles.imageBackgroundText}>Submit qualifying items for repair across Carousel’s trusted network of service centers. Track your repair progress right from the app, keeping your memories safe and your items in top shape.</Text>

  <TouchableOpacity
  onPress={()=>navigation.navigate('ServiceTicketCreatorScreen')}
  style={styles.serviceBtn}>
    <Text style={styles.btnText}>Request Service</Text>
  </TouchableOpacity>
</View>
        <View style={styles.container}>
          <View
            style={{
              ...styles.iphoneGalleryContainer,
              height: 72,
            }}
          >
            <Text style={styles.iphoneGalleryTextCentered}>Open Service Tickets</Text>
          </View>
          <View style={styles.itemListwithGap}>
            <TicketList type='SERVICE'/>
          </View>
        </View>
        <View style={styles.footerButtonContainer}>
          <Button title={"Test"} color={colors.primary} onPress={()=>navigation.navigate('ServiceDetailScreen')}/>
        </View>
        </View>
          </ScrollView>
        </View>
  );
}
