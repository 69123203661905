import React, { useContext, useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ImageBackground,
  TextInput,
  Dimensions,
  FlatList,
  ScrollView,
  Image,
  Platform,
  Alert
} from "react-native";
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native'
import { Camera, CameraType } from 'expo-camera';
import { useToast } from 'react-native-toast-notifications';
import { colors } from "../../config/Colors";
import Header from "../../components/Common/Header";
import MemoryItem from "../../components/Capture/MemoryItem";
import Button from "../../components/Common/Button";
import * as ImagePicker from 'expo-image-picker';
import CollectionItem from '../../components/collectionItem';
import { AuthContext } from "../../context";
import { API } from "../../lib/api";

let { width } = Dimensions.get('window');
width = width > 500 ? 500 : width;

let headerMargin = 100;
let headerHeight = 70;
const window = Dimensions.get('window'); // Retrieve window dimensions
if (window.width <= 768) {
  headerMargin = 40;
  headerHeight = 70;
}

function CollectionPicker(props) {
  function renderEntry(item) {
    return <CollectionItem key={item.id} item={item} onPress={(itemId) => {
      props.onPick(itemId);
    }}/>;
  }
  return (props.items === null ? [] : props.items).map(item => {
    return renderEntry(item);
  });
}

// Mock data for gallery images
const images = Array.from({ length: 6 }, (_, index) => ({
  id: String(index),
  uri: require("../../assets/icons/Capture/emptyImage.png"),
}));

export default function CaptureScreen() {
  const [permission, requestPermission] = Camera.useCameraPermissions();
  const [cameraReady, setCameraReady] = useState(false);
  const [type, setType] = useState(CameraType.back);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [isSelectingItem, setSelectingItem] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [collection, setCollection] = useState([]);
  const isFocused = useIsFocused();
  const camera = useRef(null);
  const authContext = useContext(AuthContext);
  const navigation = useNavigation();
  const toast = useToast();
  const [content, setContent] = useState("");
  const selectedItem = collection.find((item) => item.id == selectedItemId);

  useFocusEffect(
    React.useCallback(() => {
      if (!permission /* && Platform.OS != 'web'*/)
      {
        requestPermission();
      }

      return () => {};
    }, [])
  );

  function queryAPI() {
    API.collection(authContext.user.id).then(items => {
      setCollection(items);
    }).catch(err => {
      console.log(err);
    });
  }

  useEffect(() => {
    queryAPI();
  }, [authContext.user.id]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // Refresh when the screen enters focus.
      queryAPI();
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [navigation]);


  async function pickImage() {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (!result.canceled) {
       setCurrentPhoto(result.uri);
    }
  };

  async function submit() {
    if (selectedItemId == null) {
      toast.show("Select an item", {duration: 1000});
      return;
    } else if (currentPhoto == null) {
      toast.show("Take a Photo", {duration: 1000});
      return;
    } else if (content == "") {
      toast.show("Enter a message", {duration: 1000});
      return;
    }

    let uploaded;
    try {
      uploaded = await API.uploadItemImage(currentPhoto);
    } catch (err) {
      console.log(err);
      toast.show("Failed to upload photo");
    }

    let memory;
    try {
      memory = await API.submitMemory({
        content: content,
        item_id: selectedItemId,
        item_image_id: uploaded.id,
      });
    } catch (err) {
      console.log(err);
      toast.show("Failed to post memory");
    }

    console.log('Posted memory:', memory);
    navigation.navigate('Home', {screen: 'HomeScreen'});
  }

  const renderImageItem = ({ item }) => (
    <View style={styles.imageItem}>
      <Image source={item.uri} style={styles.image} />
    </View>
  );

  function toggleCameraType() {
    setType(current => (current === CameraType.back ? CameraType.front : CameraType.back));
  }

  async function takePhoto() {
    if (!cameraReady || camera.current == null) {
      console.log("camera not ready");
      return;
    }

    let result;
    try {
      result = await camera.current.takePictureAsync();
    } catch (err) {
      console.log(err);
      return;
    }
    setCurrentPhoto(result.uri);
  }

  return (
    <View style={styles.containerContainer}>
      <Header title="Capture a New Memory" />
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.container}>
          { isSelectingItem ?
           <>
            <CollectionPicker items={collection} onPick={(itemId) => { setSelectedItemId(itemId); setSelectingItem(false) }}/>
           </>
           :
           <>
            { currentPhoto != null ? (
              <ImageBackground
              style={styles.cameraView}
              source={{uri: currentPhoto}}
              >
                <TouchableOpacity style={styles.cameraRetakeButton} onPress={() => setCurrentPhoto(null)}>
                  <Image source={require("../../assets/icons/Common/close.png")} style={styles.closeIcon}></Image>
                </TouchableOpacity>
              </ImageBackground>
            ) : (
              isFocused ?
              <Camera
                style={styles.cameraView}
                onCameraReady={() => setCameraReady(true)}
                onMountError={(event) => console.log(event)}
                type={type}
                ref={camera}>
                <View style={{height: 500, width: "100%", textAlign: "center", alignItems: "center"}}>
                  <View style={styles.buttonContainer}>
                    <TouchableOpacity style={styles.imageUploadButton} onPress={pickImage}>
                      <Image source={require("../../assets/icons/Common/image-upload.png")} style={styles.flipIcon}></Image>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.cameraFlipButton} onPress={toggleCameraType}>
                      <Image source={require("../../assets/icons/Common/camera-flip.png")} style={styles.flipIcon}></Image>
                    </TouchableOpacity>
                    <View style={styles.cameraTakePhotoButtonContainer}>
                    <TouchableOpacity style={styles.cameraTakePhotoButton} onPress={takePhoto}>
                    </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </Camera>
              :
              <></>
          )}
          <View
            style={{
              ...styles.iphoneGalleryContainer,
              borderWidth: 1,
              borderColor: colors.tabTopBorder,
              height: 72,
            }}
          >
            <Text style={styles.iphoneGalleryText}>Select an Item</Text>
          </View>
          <MemoryItem
            avatarUri={selectedItem ? API.apiUrl(selectedItem.images[0].url) : null }
            title={selectedItem?.description || "Select item..."}
            subtitle={selectedItem?.brand}
            onPress={() => setSelectingItem(true)}
          />

          <TextInput
            style={styles.descriptionInput}
            placeholder="Add text to your memory."
            multiline
            onChangeText={setContent}
          />

        <View style={styles.footerButtonContainer}>
        <Button
          title={"Post Memory"}
          color={colors.primary}
          onPress={submit}
        />
        </View>
        </>
        }
      </View>
    </ScrollView>
  </View>
  );
}

const Tabs = ({ activeTab, handleTabChange }) => (
  <View style={styles.tabContainer}>
    <TouchableOpacity
      style={[styles.tabButton, activeTab === "TakePhoto" && styles.activeTab]}
      onPress={() => handleTabChange("TakePhoto")}
    >
      <Text style={[styles.tabText,activeTab === "TakePhoto" && styles.activeTabText]}>Take Photo</Text>
    </TouchableOpacity>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    maxWidth: 500,
    marginTop: 0,
    padding: 0,
    marginTop: 0,
    paddingTop: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 40,
  },
  containerContainer: {
    backgroundColor: "white",
    flex: 1
  },
  tabContainer: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: colors.tabTopBorder,
  },
  tabButton: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 15,
  },
  tabText: {
    fontSize: 14,
    color: colors.primary,
    fontFamily:'Regular',
  },
  activeTabText:
  {
    fontSize: 14,
    color: colors.primary,
    fontFamily:'SemiBold',
  },
  activeTab: {
    borderBottomWidth: 2,
    borderBottomColor: colors.primary,
  },
  cameraView: {
    width: width,
    maxWidth: 500,
    height: 500,
    justifyContent: "center",
    alignItems: "center",
  },
  cameraViewText: {
    fontSize: 14,
    color: colors.white,
    fontFamily:'SemiBold'
  },
  descriptionInput: {
    borderColor: colors.primary,
    borderWidth: 1,
    borderRadius: 6,
    padding: 12,
    paddingTop: 15,
    margin: 20,
    height: 168,
    textAlignVertical: "top",
    color: colors.primary,
    fontFamily: "Regular",
  },
  header: {
    padding: 20,
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: colors.tabTopBorder,
    backgroundColor: "white",
    position: 'fixed',
    marginTop: headerMargin,
    width: '100%',
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  headerText: {
    fontSize: 20,
    color: colors.black,
  },
  gallery: {},
  imageItem: {
    width: width / 3,
    height: width / 3,
    borderWidth: 1,
    borderColor: colors.white,
  },
  image: {
    width: "100%",
    height: "100%",
  },
  iphoneGalleryContainer: {
    width: width,
    maxWidth: 500,
    height: 48,
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "center",
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopColor: colors.tabTopBorder,
  },
  iphoneGalleryText: {
    fontSize: 14,
    fontFamily: "SemiBold",
    color: colors.primary,
  },
  footerButtonContainer:
  {
    marginTop:18,
  },
  cameraButton: {
  },
  buttonContainer: {
    height: 500,
    textAlign: "center",
    width: "100%",
    alignItems: "center",
    flex: 1,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  cameraFlipButton: {
    backgroundColor: "transparent",
    position: "absolute",
    bottom: 52,
    right: 40
  },
  imageUploadButton: {
    backgroundColor: "transparent",
    position: "absolute",
    bottom: 52,
    left: 40
  },
  cameraTakePhotoButtonContainer: {
    backgroundColor: "transparent",
    borderWidth: 3,
    borderColor: colors.white,
    height: 80,
    width: 80,
    borderRadius: 40,
    position: "absolute",
    bottom: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  cameraTakePhotoButton: {
    backgroundColor: colors.white,
    height: 66,
    width: 66,
    borderRadius: 33,
    position: "relative",
    top: 4,
    left: 4
  },
  cameraRetakeButton: {
    backgroundColor: "transparent",
    position: "absolute",
    top: 30,
    left: 30
  },
  flipIcon : {
    height: 32,
    width: 32
  },
  closeIcon : {
    height: 16,
    width: 16
  }
});
