import React, { useEffect, useState } from 'react';
import { View, SafeAreaView, Text, ActivityIndicator } from 'react-native';
import { styles } from '../styles/global';
import { API } from '../lib/api';
import IconButton from '../components/iconButton';
import JewelerLocation from '../components/jewelerLocation';

export default function JewelerLocationsScreen({ navigation }) {
  const [locations, setLocations] = useState(null);

  function refresh() {
    return API.locations().then((response) => {
      setLocations(response);
    });
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // Refresh when the screen enters focus.
      refresh();
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    refresh();
  }, []);

  return (
  <SafeAreaView style={styles.safeArea}>
    <View style={styles.container}>
      <Text style={styles.titleText}>Locations</Text>
      <IconButton title='Add Location' icon='plus' linkTo={{screen: 'JewelerLocationForm', params: {id: 'new'}}}/>
      {
        locations === null ?
        <ActivityIndicator size="large"/>
        :
        locations.map(loc => {
          return <JewelerLocation actionIcon='chevron-right' linkTo={(id) => {return {screen: 'JewelerLocationForm', params: {id: loc.id}}}} key={loc.id} location={loc}/>
        })
      }
    </View>
  </SafeAreaView>
  )
}
