import {
    StyleSheet,
    View,
    FlatList,
    Dimensions,
  } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { colors } from "../../config/Colors";
import Header from "../../components/Common/Header";
import MemoryItem from "../../components/Capture/MemoryItem";
import { AuthContext } from "../../context";
import { API } from "../../lib/api";
import { useNavigation } from "@react-navigation/native";

let { width } = Dimensions.get('window');
width = width > 500 ? 500 : width;

export default function UserFollowingScreen({ route }) {
  const { userId } = route.params;
  const [following, setFollowing] = useState([]);
  const authContext = useContext(AuthContext);
  const navigation = useNavigation();

  useEffect(() => {
    API.following(userId).then(setFollowing);
  }, [authContext.user, userId]);

  function goToProfile(userId) {
    if (userId == authContext.user.id) {
      navigation.navigate('Profile', {screen: 'ProfileScreen'})
    } else {
      navigation.navigate('Home', {screen: 'UserProfile', params: {id: userId}})
    }
  }

  const renderItem = ({ item }) => (
    <MemoryItem
      avatarUri={API.apiUrl(item.profile_image_url)}
      title={item.username}
      subtitle={item.location}
      onPress={() => goToProfile(item.id)}
    />
  );

  return (
    <View style={styles.containerContainer}>
      <Header title={"Following"} />
        <View style={styles.container}>
      <View style={styles.seprator} />
      <View>
        <FlatList
          data={following}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          // Add any additional FlatList props as needed
        />
      </View>
    </View>
    </View>
  );
}

const styles = StyleSheet.create({
  containerContainer: {
    backgroundColor: "white",
    flex: 1
  },
  container: {
    flex: 1,
    backgroundColor: colors.white,
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight:'auto'
  },
  seprator: {
    width: width,
    height: 1,
    backgroundColor: colors.border,
    alignSelf: "center",
  },
});
